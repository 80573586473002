import { MagnifyingGlass as MagnifyingGlassIcon } from '@phosphor-icons/react/dist/ssr/MagnifyingGlass';
import * as React from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Plus as PlusIcon } from '@phosphor-icons/react/dist/ssr/Plus';
import { UserPlus as UserPlus } from '@phosphor-icons/react';
import { MagnifyingGlass as Search } from '@phosphor-icons/react';

import { CONSTANTS } from '../../constants';
import { MembersTable } from './member-table';
import { useNavigate } from 'react-router-dom';
import axiosService from '../../services/axios';
import { Member } from '../../types/member-interface';
import { Box, Card, OutlinedInput, InputAdornment, CardContent, Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, MenuItem, Select, Switch, TextField, InputLabel, Drawer } from '@mui/material';
import { useAlert } from '../../components/CommonAlert';
import MemberInviteDialog from '../../components/ConfirmationDialog';
import { useAuth } from '../../context/AuthContext';
import { Roles } from 'src/types/enum';
import moment from 'moment';
import NoticeTabs from '../notice-board/notice-tabs';
import { Controller, useForm } from 'react-hook-form';
import { MemberSearch } from 'src/intefaces/member-search.interface';
import { AsssignCoupons } from './assign-coupon';
import { AsssigneWallets } from './assign-ewallet';
import { SendWhatsapp } from './send-whatsapp';
export default function MembersPage(): React.JSX.Element {
    const page = 0;
    const rowsPerPage = 25;
    const [members, setMembers] = React.useState<Member[]>([]);
    const [selectedMembers, setSelectedMembers] = React.useState<Member[]>([]);
    const [openInvite, setOpenInvite] = React.useState<boolean>(false);
    const [filteredData, setFilteredData] = React.useState<Member[]>([]);
    const [searchQuery, setSearchQuery] = React.useState('');
    const [inviteLInk, setInviteLink] = React.useState("");
    const { user } = useAuth();
    const [drawerMode, setDrawerMode] = React.useState<"ewallet" | "coupon" | "whatsapp" | undefined>(undefined);
    const months = [
        { key: "1", value: 'Jan' },
        { key: "2", value: 'Feb' },
        { key: "3", value: 'Mar' },
        { key: "4", value: 'Apr' },
        { key: "5", value: 'May' },
        { key: "6", value: 'Jun' },
        { key: "7", value: 'Jul' },
        { key: "8", value: 'Aug' },
        { key: "9", value: 'Sep' },
        { key: "10", value: 'Oct' },
        { key: "11", value: 'Nov' },
        { key: "12", value: 'Dec' }];
    const { control, handleSubmit, getValues } = useForm<MemberSearch>({
        defaultValues: {
            birthdayMonths: [],
            ageGroups: [],
            gender: 'All',
            memberType: 'All',
            keyword: '',
            upline: false,
        },
    });
    const onSubmit = (data: MemberSearch) => {
        getMembers(data);
    };

    const toggleDrawer = (open: "ewallet" | "coupon" | "whatsapp" | undefined) => {
        setDrawerMode(undefined);
    };
    const { showAlert } = useAlert();
    const getInviteLink = async () => {
        try {
            let meta = "";
            console.log("user", user)
            switch (user?.role) {
                case Roles.merchant:
                    meta = `mid${user.account.id}`;
                    break;
                case Roles.outlet:
                    meta = `oid${user.account.id}`;
                    break;
                case Roles.staff:
                    meta = `sid${user.account.id}`;
                    break;
                default:
                    break;
            }
            setInviteLink(`${process.env.REACT_APP_APP_BASE_URL}/mregister/${encodeURIComponent(btoa(meta))}`);
        } catch (ex: any) {
            showAlert(ex?.response?.data?.message ?? 'Failed to create link', 'error');
            console.error("Exception Caught", ex)
            setInviteLink("");
        }
    }
    const getMembers = async (filters: MemberSearch) => {
        try {
            const response = await axiosService.post<Member[] | any>(`${CONSTANTS.ApiConstants.advanceFilter}`, filters)
            if (response.status != 200) {
                showAlert(response?.data?.message ?? 'Failed to get members', 'error');
                setMembers([])
                setFilteredData([])
            } else {
                const json = response.data;

                setMembers(json);
                setFilteredData(json)
            }
        } catch (ex: any) {
            setMembers([])
            setFilteredData([])
            showAlert(ex?.response?.data?.message ?? 'Failed to get members', 'error');
            console.error("Exception Caught", ex)
        }
    }
    const onRowsSelected = (rows: Member[]) => {
        setSelectedMembers(rows);
    }
    React.useEffect(() => {
        getMembers(getValues());
    }, [])
    const onCopy = () => {
        navigator.clipboard.writeText(inviteLInk).then(() => {
            setOpenInvite(false)
        }).catch(err => {
            setOpenInvite(false)
            showAlert('Failed to copy!', 'error');
            console.log("failed to copy", err);
        });

    }
    React.useEffect(() => {

        setFilteredData(members.filter((item: Member) => {
            console.log("item?.dateFrom", item)
            return (item?.DOB && item?.DOB.toString().includes(searchQuery.toLowerCase())) ||
                (item?.Address1 && item?.Address1.toLowerCase().includes(searchQuery.toLowerCase())) ||
                (item?.Address2 && item?.Address2.toLowerCase().includes(searchQuery.toLowerCase())) ||
                (item?.Country && item?.Country.toLowerCase().includes(searchQuery.toLowerCase())) ||
                (item?.Email && item?.Email.toLowerCase().includes(searchQuery.toLowerCase())) ||
                (item?.IdNumber && item?.IdNumber.toLowerCase().includes(searchQuery.toLowerCase())) ||
                (item?.MemberName && item?.MemberName.toLowerCase().includes(searchQuery.toLowerCase())) ||
                (item?.MemberLocation && item?.MemberLocation.toLowerCase().includes(searchQuery.toLowerCase())) ||
                (item?.Mobile && item?.Mobile.toLowerCase().includes(searchQuery.toLowerCase()))
        }));
    }, [searchQuery])
    const onDeleteCallback = () => getMembers(getValues());
    const navigate = useNavigate();
    return (
        <Stack spacing={3}>
            <Stack direction="row" spacing={3}>
                <Stack spacing={1} sx={{ flex: '1 1 auto' }}>
                    <Typography variant="h4">Members</Typography>

                </Stack>
                <div>
                    <Button
                        startIcon={<Search fontSize="var(--icon-fontSize-md)" />}
                        variant="text"
                        onClick={() => { navigate('/members/globalsearch') }}
                    >
                        Global Search
                    </Button>  <Button
                        startIcon={<UserPlus fontSize="var(--icon-fontSize-md)" />}
                        variant="text"
                        onClick={() => { setOpenInvite(true); getInviteLink(); }}
                    >
                        Invite Member
                    </Button>

                    <Button
                        startIcon={<PlusIcon fontSize="var(--icon-fontSize-md)" />}
                        variant="text"
                        onClick={() => navigate('/members/add')}
                    >
                        Add
                    </Button>
                </div>
            </Stack>
            <Box>
                <Card>
                    <CardContent>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Grid container spacing={2}>
                                {/* Birthday Month */}
                                <Grid item xs={12} sm={5}>
                                    <FormControl component="fieldset">
                                        <FormLabel component="legend">Birthday month</FormLabel>
                                        <FormGroup row>
                                            {months.map((month) => (
                                                <Controller
                                                    key={"birthdayMonths" + month.key}
                                                    name="birthdayMonths"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={field.value.includes(month.key)}
                                                                    onChange={(e) => {
                                                                        const newMonths = e.target.checked
                                                                            ? [...field.value, month.key]
                                                                            : field.value.filter((m: string) => m !== month.key);
                                                                        field.onChange(newMonths);
                                                                    }}
                                                                />
                                                            }
                                                            label={month.value}
                                                        />
                                                    )}
                                                />
                                            ))}
                                        </FormGroup>
                                    </FormControl>
                                </Grid>


                                {/* Gender Select */}
                                <Grid item xs={12} sm={2} >
                                    <FormControl fullWidth>
                                        <InputLabel id="Genderlabel" component="legend">Gender</InputLabel>
                                        <Controller
                                            name="gender"
                                            control={control}
                                            render={({ field }) => (
                                                <Select {...field} labelId="Genderlabel" label="Gender">
                                                    <MenuItem value="All">All</MenuItem>
                                                    <MenuItem value="Male">Male</MenuItem>
                                                    <MenuItem value="Female">Female</MenuItem>
                                                </Select>
                                            )}
                                        />
                                    </FormControl>
                                </Grid>

                                {/* Member Type Select */}
                                <Grid item xs={12} sm={2}>
                                    <FormControl fullWidth>
                                        <InputLabel id="label-membertype" component="legend">Member Type</InputLabel>
                                        <Controller
                                            name="memberType"
                                            control={control}
                                            render={({ field }) => (
                                                <Select {...field} label="Member Type" labelId="label-membertype">
                                                    <MenuItem value="All">All</MenuItem>
                                                    <MenuItem value="Premium">Premium</MenuItem>
                                                    <MenuItem value="Basic">Basic</MenuItem>
                                                </Select>
                                            )}
                                        />
                                    </FormControl>
                                </Grid>

                                {/* Keyword */}
                                <Grid item xs={12} sm={3}>
                                    <Controller
                                        name="keyword"
                                        control={control}
                                        render={({ field }) => <TextField fullWidth label="Keyword" variant="outlined" {...field} />}
                                    />
                                </Grid>


                                {/* Age Group */}
                                <Grid item xs={12} sm={5}>
                                    <FormControl component="fieldset">
                                        <FormLabel component="legend">Age</FormLabel>
                                        <FormGroup row>
                                            {['0-9', '10-19', '20-29', '30-39', '40-49', '50-59', '60 and above'].map((ageGroup) => (
                                                <Controller
                                                    key={ageGroup}
                                                    name="ageGroups"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={field.value.includes(ageGroup)}
                                                                    onChange={(e) => {
                                                                        const newAgeGroups = e.target.checked
                                                                            ? [...field.value, ageGroup]
                                                                            : field.value.filter((age: string) => age !== ageGroup);
                                                                        field.onChange(newAgeGroups);
                                                                    }}
                                                                />
                                                            }
                                                            label={ageGroup}
                                                        />
                                                    )}
                                                />
                                            ))}
                                        </FormGroup>
                                    </FormControl>

                                </Grid>
                                {/* Upline Switch */}
                                <Grid item xs={12} sm={2}>
                                    <FormControlLabel
                                        control={
                                            <Controller
                                                name="upline"
                                                control={control}
                                                render={({ field }) => (
                                                    <Switch checked={field.value} onChange={(e) => field.onChange(e.target.checked)} />
                                                )}
                                            />
                                        }
                                        label="Upline"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={2}></Grid>
                                <Grid item xs={12} sm={3}>
                                    <Button variant="contained" type='submit' color="primary" style={{ float: 'right' }}>
                                        Search
                                    </Button>
                                </Grid>

                            </Grid>
                        </form>
                    </CardContent>
                </Card>
                <Card sx={{ p: 2 }}>
                    {/* Buttons */}
                    <Grid item xs={12}>
                        <Button disabled={!selectedMembers || selectedMembers.length <= 0} variant="contained" color="secondary" style={{ marginRight: 8 }} onClick={() => setDrawerMode("coupon")}>
                            Assign Coupon
                        </Button>
                        <Button disabled={!selectedMembers || selectedMembers.length <= 0} variant="contained" color="secondary" style={{ marginRight: 8 }} onClick={() => setDrawerMode("ewallet")}>
                            Assign Ewallet
                        </Button>
                        <Button disabled={!selectedMembers || selectedMembers.length <= 0} variant="contained" color="secondary" onClick={() => setDrawerMode("whatsapp")}>
                            Send WhatsApp
                        </Button>
                    </Grid>
                    {/* <OutlinedInput
                        onChange={(e) => setSearchQuery(e.target.value)}
                        fullWidth
                        placeholder="Search member"
                        startAdornment={
                            <InputAdornment position="start">
                                <MagnifyingGlassIcon fontSize="var(--icon-fontSize-md)" />
                            </InputAdornment>
                        }
                        sx={{ maxWidth: '500px' }}
                    /> */}
                </Card>
            </Box>
            <MembersTable
                count={filteredData?.length ?? 0}
                page={page}
                rows={filteredData}
                rowsPerPage={rowsPerPage}
                onDeleteCallback={onDeleteCallback}
                onRowsSelected={onRowsSelected}
            />
            <MemberInviteDialog
                open={openInvite && !!inviteLInk}
                onClose={() => { setOpenInvite(false); }}
                onConfirm={onCopy}
                confirmLabel={"Copy Link"}
                cancelLabel={"Cancel"}
                message={`Copy and share the link :  ${inviteLInk}`}
                title={"Invite Member"}
            />


            <Drawer
                anchor="right"
                open={!!drawerMode}
                onClose={() => toggleDrawer(undefined)}
            >
                <Box
                    sx={{ width: "50vw", p: 2 }}
                    role="presentation"
                >
                    {
                        drawerMode == "coupon" ? <AsssignCoupons formMode='add' members={selectedMembers} onCancel={() => setDrawerMode(undefined)} /> : (drawerMode == "ewallet" ? <AsssigneWallets members={selectedMembers} onCancel={() => setDrawerMode(undefined)} /> : (drawerMode == "whatsapp" ? <SendWhatsapp members={selectedMembers} onCancel={() => { setDrawerMode(undefined); }} /> : <></>))
                    }
                </Box>
            </Drawer>
        </Stack>
    );
}
