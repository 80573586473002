import { Card, Box, Avatar, Typography } from "@mui/material";
import moment from "moment";
import React from "react";
import { CouponMapping } from "src/intefaces/coupon.interface";
import * as Icons from '@mui/icons-material';

export const Coupon = ({ mcoupon, disabled }: { mcoupon: CouponMapping, disabled: boolean }) => {
    return (
        <Card

            elevation={0}
            sx={{
                marginTop: "8px",
                display: 'flex',
                alignItems: 'center',
                width: "100%",
                borderRadius: 2,
                overflow: 'hidden',
                position: 'relative',
                background: ` ${disabled ? "#848484" : mcoupon?.coupon?.themeColor}`,
                border: `1px solid ${disabled ? "#848484" : mcoupon?.coupon?.themeColor}`,
                borderLeft: 0,
                '&::before': {
                    content: '""',
                    position: 'absolute',
                    left: 0, // Aligns the scalloped border with the left side of the card
                    top: 0,
                    height: '100%',
                    width: 20, // Width of the scalloped border area
                    backgroundImage: `radial-gradient(circle, white 50%, transparent 50%)`,
                    backgroundSize: '10px 10px', // Size of each scallop
                    backgroundPosition: '-5px 0', // Adjust this to align scallops perfectly
                    backgroundRepeat: 'repeat-y', // Repeats the scallops along the y-axis
                    backgroundColor: `${disabled ? "#848484" : mcoupon?.coupon?.themeColor}`, // Matches the background of the scallops with the card border
                    zIndex: 1, // Ensures the scalloped border appears on top of the card's background
                },
            }}
        >
            {/* Left Section with Icon */}

            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: 100,
                    color: 'white',
                    zIndex: 2, // Ensures the icon is above the scalloped border
                }}
            >
                {mcoupon?.coupon?.image ?
                    <Avatar
                        variant='square'
                        sx={{ width: "80%", height: "80%" }}
                        src={mcoupon?.coupon?.image}
                    />
                    :
                    mcoupon?.coupon?.icon ? React.createElement(Icons[(mcoupon?.coupon?.icon) as keyof typeof Icons], { style: { color: disabled ? "#fff" : mcoupon?.coupon?.iconColor, fontSize: "50" } }) : <></>

                }
            </Box>


            {/* Right Section with Content */}
            <Box
                sx={{
                    padding: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    flexGrow: 1,
                    textAlign: "left",
                    backgroundColor: `${disabled ? "#dfdfdf" : mcoupon?.coupon?.bgColor}`,
                    zIndex: 2, // Ensures the content is above the scalloped border
                }}
            >
                <h3
                    style={{
                        display: '-webkit-box',
                        WebkitBoxOrient: 'vertical',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        WebkitLineClamp: 3, // Limits text to 3 lines
                        lineHeight: '1.5rem', // Set line height (adjust as needed)
                        height: '4.5rem', // 3 lines * lineHeight (3 * 1.5rem)
                        textAlign: 'left',
                        fontWeight: "bold"
                    }}
                >
                    {mcoupon?.coupon?.name}
                </h3>
                <p style={{ margin: 0 }} >
                    {mcoupon?.validTill ? `Expiry : ${moment.utc(mcoupon?.validTill ?? null).local().format('DD-MM-YYYY')}` : ""}
                </p>
            </Box>
        </Card >
    );
};