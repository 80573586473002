import React, { useState } from 'react';
import { Navigate, useLocation, useParams } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { jwtDecode } from 'jwt-decode';
import { TokenObject } from 'src/types/token-object';
import { Merchant } from 'src/types/merchant-interface';

interface ProtectedRouteProps {
  roles?: string[];
  element: React.ReactElement;
}

const ProtectedMobileRoute: React.FC<ProtectedRouteProps> = ({ roles = [], element }) => {
  const [merchant, setMerchant] = useState<Merchant>();
  const location = useLocation();
  const { getToken, isTokenValid } = useAuth();
  const token = getToken();
  const { merchantid } = useParams();
  let user;
  if (token && isTokenValid(token)) {
    const decoded: TokenObject = jwtDecode(token);
    user = decoded;
    const isAuthorized = (roles.length === 0 || roles.includes(user?.role || ''));
    if (!isAuthorized) {
      return <Navigate to={`/${merchantid}`} replace />;
    }
  } else {
    return <Navigate to={`/${merchantid}/membersignin`} state={{ from: location }} replace  />;
  }

  return element;
};

export default ProtectedMobileRoute;
