
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import { Button, Card, CardContent, Divider, FormControl, Grid, InputLabel, MenuItem, Select, Stack, TextField, Typography } from '@mui/material';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { CONSTANTS } from '../../constants';
import axiosService from '../../services/axios';
import { Merchant } from '../../types/merchant-interface';
import { Staff } from '../../types/staff-interface';
import { useAlert } from '../../components/CommonAlert';
import { useParams } from 'react-router-dom';
import { RecordActions, RecordTypes } from '../../types/enum';
import { List as ListIcon } from '@phosphor-icons/react/dist/ssr/List';
import ImageUpload from '../../components/ImageUpload';
import { useState } from 'react';

const genderOptions = [
    { value: 'Male', label: 'Male' },
    { value: 'Female', label: 'Female' },
    { value: 'Other', label: 'Other' }
]
export function StaffForm(): React.JSX.Element {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [value, setValue] = React.useState("General");
    const [outlets, setOutlets] = React.useState<Merchant[]>([])
    const { action, id } = useParams();
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };
    const { showAlert } = useAlert();
    const methods = useForm<any>({
        defaultValues: {
            "phone": "",
            "firstName": "",
            "lastName": "",
            "email": "",
            "address": "",
            "address2": "",
            "postcode": "",
            "state": "",
            "country": "",
            "picture": "",
            "gender": "",
            "merchantid": "",
            "outletid": "",
            "createdat": "",
            "modifiedat": "",
            "createdby": "",
            "modifiedby": "",
            "username": "",
            "password": ""
        }
    });
    const createNewStaff = async (data: any) => {
        try {
            const response = await axiosService.post<Staff | any>(`${CONSTANTS.ApiConstants.GENERIC.post[RecordTypes.staff]}`, data)
            if (response.status != 200) {
                setLoading(false);
                showAlert(response.data.message || 'Failed to Save Merchant', 'error');
            } else {
                const json = response.data;
                setLoading(false);
                showAlert('Merchant Created Successfully', 'success');
                navigate(`/staff/edit/${response.data.id}`)
            }
        } catch (ex: any) {
            setLoading(false);
            showAlert(ex.response.data.message || 'Failed to Save Merchant', 'error');
            console.error("Exception Caught", ex)
        }
    }
    const editStaff = async (data: any) => {
        try {
            const response = await axiosService.put<Staff>(`${CONSTANTS.ApiConstants.GENERIC.put[RecordTypes.staff]}/${id}`, data)
            if (response.status != 200) {
                setLoading(false);
                showAlert('Failed to Update Merchant', 'error');
            } else {
                const json = response.data;
                setLoading(false);
                showAlert('Merchant Updated Successfully', 'success');
            }
        } catch (ex) {
            showAlert('Failed to Update Merchant', 'error');
            setLoading(false);
            console.error("Exception Caught", ex)
        }
    }

    const getStaffDetails = async () => {
        try {
            const response = await axiosService.get<Staff>(`${CONSTANTS.ApiConstants.GENERIC.get[RecordTypes.staff]}/${id}`)
            if (response.status != 200) {
                showAlert('Failed to Retrive Staff Details', 'error');
            } else {
                const json: Staff = response.data;
                methods.reset(json);
            }
        } catch (ex) {
            showAlert('Failed to Retrive Staff Details', 'error');
            console.error("Exception Caught", ex)
        }
    }
    const onSubmit = methods.handleSubmit(async (data: any) => {
        try {
            setTimeout(() => {
                console.log(loading)
                if (loading) {
                    return
                }
                setLoading(true);


                if (action == RecordActions.add) {
                    createNewStaff(data);
                } else if (action == RecordActions.edit) {
                    editStaff(data);
                }

            });

        }
        catch (ex) {
            setLoading(false);
        } finally {

        }



    });
    const getOutlets = async () => {
        try {
            const response = await axiosService.get<Merchant[]>(`${CONSTANTS.ApiConstants.GENERIC.get[RecordTypes.outlet]}`)
            if (response.status != 200) {
                setOutlets([])
            } else {
                const json = response.data;
                setOutlets([{ MerchantName: "--Select Outlet--" } as Merchant, ...json]);
            }
        } catch (ex) {
            setOutlets([])
            console.error("Exception Caught", ex)
        }

    }
    React.useEffect(() => {
        getOutlets();
        if (action == RecordActions.edit || action == RecordActions.view) {
            getStaffDetails();
        }
    }, [])
    return (
        <Stack spacing={3}>
            <Stack direction="row" spacing={3}>
                <Stack spacing={1} sx={{ flex: '1 1 auto' }}>
                    <Typography variant="h4">Staffs</Typography>
                    <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
                        {/* <Button color="inherit" startIcon={<UploadIcon fontSize="var(--icon-fontSize-md)" />}>
          Quick Actions
        </Button> */}

                    </Stack>
                </Stack>
                <div>
                    <Button
                        startIcon={<ListIcon fontSize="var(--icon-fontSize-md)" />}
                        variant="text"
                        onClick={() => navigate('/staff')}
                    >
                        View All
                    </Button>
                </div>
            </Stack>

            <FormProvider {...methods} >
                <Box component="form" onSubmit={onSubmit} sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <Card>
                        <CardContent>
                            <Grid container spacing={2}>


                                <Grid item xs={12} sm={4}>
                                    <Controller
                                        name="outletid"

                                        control={methods.control}
                                        render={({ field, fieldState }) =>
                                            <FormControl fullWidth>
                                                <InputLabel id="demo-simple-select-label">Outlet</InputLabel>
                                                <Select {...field} label="Outlet" name="outlet" variant="outlined">
                                                    {outlets.map((option) => (
                                                        <MenuItem key={option.id} value={option.id}>
                                                            {option.MerchantName}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        } />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Controller
                                        name="phone"
                                        rules={{ required: 'Phone is required' }}
                                        control={methods.control}
                                        render={({ field, fieldState }) =>
                                            <TextField
                                                InputProps={{
                                                    readOnly: action == RecordActions.view,
                                                }}
                                                helperText={typeof methods.formState.errors?.phone?.message === 'string' ? methods.formState.errors?.phone?.message : ""}
                                                FormHelperTextProps={{ style: { color: 'red' } }}
                                                {...field}
                                                fullWidth
                                                id="phone"
                                                label="Phone"
                                            />} />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Controller
                                        name="firstName"

                                        control={methods.control}
                                        render={({ field, fieldState }) =>
                                            <TextField
                                                InputProps={{
                                                    readOnly: action == RecordActions.view,
                                                }}
                                                {...field}
                                                fullWidth
                                                id="firstName"
                                                label="First Name"
                                            />} />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Controller
                                        name="lastName"
                                        control={methods.control}
                                        render={({ field, fieldState }) =>
                                            <TextField
                                                InputProps={{
                                                    readOnly: action == RecordActions.view,
                                                }}
                                                {...field}
                                                fullWidth
                                                label="Last Name"
                                                id="lastName"
                                            />} />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Controller
                                        name="email"
                                        control={methods.control}
                                        render={({ field, fieldState }) =>
                                            <TextField
                                                InputProps={{
                                                    readOnly: action == RecordActions.view,
                                                }}
                                                {...field}
                                                fullWidth
                                                label="Email"
                                                id="email"
                                            />} />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Controller
                                        name="gender"
                                        control={methods.control}
                                        render={({ field }) =>
                                            <FormControl fullWidth>
                                                <InputLabel id="demo-simple-select-label">Gender</InputLabel>
                                                <Select {...field} defaultValue={null} label="Gender" name="gender" variant="outlined">
                                                    {genderOptions.map((option) => (
                                                        <MenuItem key={option.value} value={option.value}>
                                                            {option.label}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        } />
                                </Grid>

                                <Grid item xs={12} sm={4}>
                                    <Controller
                                        name="address"
                                        control={methods.control}
                                        render={({ field, fieldState }) =>
                                            <TextField
                                                InputProps={{
                                                    readOnly: action == RecordActions.view,
                                                }}
                                                {...field}
                                                fullWidth
                                                label="Address"
                                                id="Address"
                                            />} />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Controller
                                        name="address2"
                                        control={methods.control}
                                        render={({ field, fieldState }) =>
                                            <TextField
                                                InputProps={{
                                                    readOnly: action == RecordActions.view,
                                                }}
                                                {...field}

                                                fullWidth
                                                label="Address Line 2"
                                                id="address2"
                                            />} />
                                </Grid>

                                <Grid item xs={12} sm={4}>
                                    <Controller
                                        name="postcode"
                                        control={methods.control}
                                        render={({ field, fieldState }) =>
                                            <TextField
                                                InputProps={{
                                                    readOnly: action == RecordActions.view,
                                                }}
                                                {...field}
                                                fullWidth
                                                label="Postcode"
                                                id="postcode"
                                            />} />
                                </Grid>

                                <Grid item xs={12} sm={4}>
                                    <Controller
                                        name="state"
                                        control={methods.control}
                                        render={({ field, fieldState }) =>
                                            <TextField
                                                InputProps={{
                                                    readOnly: action == RecordActions.view,
                                                }}
                                                {...field}
                                                fullWidth
                                                label="State"
                                                id="state"
                                            />} />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Controller
                                        name="country"
                                        control={methods.control}
                                        render={({ field, fieldState }) =>
                                            <TextField
                                                InputProps={{
                                                    readOnly: action == RecordActions.view,
                                                }}
                                                {...field}
                                                fullWidth
                                                label="Country"
                                                id="country"
                                            />} />
                                </Grid>


                                <Grid item xs={12} sm={12}>
                                    <Controller
                                        name="picture"
                                        control={methods.control}
                                        render={({ field, fieldState }) =>
                                            <ImageUpload onImageChange={(url: string | null) => { field.onChange(url) }} imageUrl={field.value} mode={action} name={"picture"} label="Picture" />
                                        }
                                    />
                                </Grid>

                            </Grid>
                            <br />
                               <Divider />
                            <br />
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={4}>
                                    <Controller
                                        name="username"
                                        control={methods.control}
                                        render={({ field, fieldState }) =>
                                            <TextField
                                                InputProps={{
                                                    readOnly: action == RecordActions.view,
                                                }}
                                                FormHelperTextProps={{ style: { color: 'red' } }}
                                                {...field}
                                                fullWidth
                                                id="username"
                                                label="Username"
                                            />} />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Controller
                                        name="password"
                                        control={methods.control}
                                        render={({ field, fieldState }) =>
                                            <TextField
                                                InputProps={{
                                                    readOnly: action == RecordActions.view,
                                                }}
                                                FormHelperTextProps={{ style: { color: 'red' } }}
                                                {...field}
                                                fullWidth
                                                id="password"
                                                label="Set New Password"
                                            />} />
                                </Grid>
                            </Grid>
                        </CardContent >
                    </Card >
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    {action != RecordActions.view && <Box
                        sx={{
                            position: 'fixed',
                            bottom: 0,
                            left: 0,
                            zIndex: 9,
                            width: '100%',
                            backgroundColor: '#f5f5f5',
                            padding: 2,
                            display: 'flex',
                            justifyContent: 'flex-end', // Align the button to the right
                            boxShadow: '0px -1px 5px rgba(0,0,0,0.1)', // Optional shadow
                        }}
                    >
                        <Button disabled={loading} variant="contained" color="primary" type="submit">
                            Save
                        </Button>
                    </Box>
                    }
                </Box>
            </FormProvider >
        </Stack>

    );
}

