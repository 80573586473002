import React, { useState } from 'react';
import { Box, TextField, Button, Typography, Avatar, Select, MenuItem, InputLabel, FormControl, Stack, InputAdornment, Divider, Link } from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import SmsIcon from '@mui/icons-material/Sms';
import { useMerchant } from 'src/context/MerchantContext';
import { FlagIcon, FlagIconCode } from 'react-flag-kit';
import { Divide } from '@phosphor-icons/react';
import { useAuth } from 'src/context/AuthContext';
import { useLocation, useNavigate } from 'react-router-dom';
import axiosService from 'src/services/axios';
import { CONSTANTS } from 'src/constants';
import { Member } from 'src/types/member-interface';
import { useAlert } from 'src/components/CommonAlert';
import { OTPMode } from 'src/types/enum';
const MemberRegister: React.FC<{ src: "forgotpassword" | "register" }> = ({ src }) => {
    const { merchant } = useMerchant();
    const countries = [
        { label: 'Malaysia', value: '+60', flag: "MY" },
        { label: 'United States', value: '+1', flag: "US" },
        { label: 'India', value: '+91', flag: "IN" },
    ]
    const [lastOtpMode, setLastOtpMode] = useState('');
    const [selectedCountry, setSelectedCountry] = useState<any>({ label: 'Malaysia', value: '+60', flag: "MY" });
    const [mobile, setMobile] = useState<string>();
    const navigate = useNavigate();
    const location = useLocation();
    const { showAlert } = useAlert();
    const [loading, setLoading] = useState<boolean>(true);
    const sendOtp = async (mode?: string) => {
        if (mode) {
            setLastOtpMode(mode)
        } else {
            mode = lastOtpMode;
        }
        try {
            const response = await (await axiosService.post<Member | any>(`${CONSTANTS.ApiConstants.user_memberLoginByOtp}/${merchant?.id}`, { countrycode: selectedCountry.value, mobile: mobile, otpMode: mode }))
            if (response.status != 200) {
                setLoading(false);
                showAlert(response?.data?.message && typeof response.data.message == "string" ? response.data.message : 'Oops! Something is not working', 'error');
            } else {
                if (src == "register") {
                    navigate(`/${merchant?.id}/memberverify/${selectedCountry.value}/${mobile}/${mode}`);
                } else {
                    navigate(`/${merchant?.id}/memberresetpass/${selectedCountry.value}/${mobile}/${mode}`);
                }
            }
        } catch (ex: any) {
            setLoading(false);
            showAlert(ex?.response?.data?.message && typeof ex.response.data.message == "string" ? ex.response.data.message : 'Oops! Something is not working. Signup Failed', 'error');
            console.error("Exception Caught", ex)
        }
    };


    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'space-evenly',
                minHeight: '85vh',
                padding: 3,
            }}
        >
            <Avatar
                sx={{
                    width: 120,
                    height: 120,
                    marginBottom: 3,
                }}
                src={merchant?.MerchantLogo}
                alt="Logo"
                variant='rounded'
            />
            <Stack sx={{ width: "100%", alignItems: "center" }}>
                <TextField
                    variant="outlined"
                    fullWidth
                    onChange={(e) => { setMobile(e.target.value) }}
                    placeholder="Phone Number"
                    sx={{
                        '& .MuiInputBase-input': {
                            fontSize: '1.5em', // Set the font size here
                        },
                        '& .MuiInputLabel-root': {
                            fontSize: '1.5em', // Set the label font size if needed
                        },
                    }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <FormControl variant="standard">
                                    <Select
                                        value={0}
                                        onChange={(e) => { setSelectedCountry(countries.find(c => c.value == e.target.value)); }}
                                        displayEmpty
                                        sx={{
                                            "minWidth": "80px",
                                            "minHeight": "0px",
                                            "& > .MuiSelect-select": {
                                                "minHeight": "0px !important"
                                            }
                                        }}
                                        renderValue={(value) => (
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                {selectedCountry ? <><FlagIcon code={selectedCountry?.flag as FlagIconCode} />
                                                    <h2 style={{ fontWeight: "normal", fontSize: "24px" }}>       {selectedCountry?.value}</h2></> : <></>}
                                            </div>
                                        )}
                                    >
                                        {countries.map((country) => (
                                            <MenuItem key={country.value} value={country.value}>
                                                <FlagIcon code={country.flag as FlagIconCode} />
                                                <h2 style={{ fontWeight: "normal", fontSize: "24px" }}> ({country.value})</h2>
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </InputAdornment>
                        ),
                    }}
                />
            </Stack>
            <Stack sx={{ width: "100%", alignItems: "center" }}>
                <Stack direction="row" sx={{ alignItems: "center" }}>
                    <h3 style={{ fontWeight: "normal" }}>
                        Receive 4-digit code via
                    </h3>
                </Stack>
                <Stack sx={{ gap: 2, width: "90%", mb: 4 }}>
                    <Button
                        variant="contained"
                        fullWidth
                        disableElevation={true}
                        startIcon={<WhatsAppIcon />}
                        onClick={(e) => { sendOtp(OTPMode.WHATSAPP) }}
                        sx={{
                            borderRadius: "50px",
                            flex: 1,
                            backgroundColor: '#25D366',
                            '&:hover': {
                                backgroundColor: '#20B758',
                            },
                            padding: "14px"
                        }}
                    >
                        <h3 style={{ fontWeight: "normal" }}>  WhatsApp</h3>
                    </Button>
                    <Button
                        onClick={(e) => { sendOtp(OTPMode.SMS); }}
                        variant="contained"
                        disableElevation={true}
                        startIcon={<SmsIcon />}
                        fullWidth
                        sx={{
                            flex: 1, borderRadius: "50px",
                            backgroundColor: '#007BFF',
                            '&:hover': {
                                backgroundColor: '#0069D9',
                            },
                            padding: "14px"
                        }}
                    >
                        <h3 style={{ fontWeight: "normal" }}>SMS</h3>
                    </Button>
                </Stack>
            </Stack>
            <h3 style={{ textAlign: "center", fontWeight: "normal" }}>
                Already Registered ?
                <br />
                <Link style={{ color: merchant?.FooterActiveButtonColor ?? "unset", textDecoration: "none" }} onClick={() => { navigate(`/${merchant?.id}/membersignin`) }}>Login</Link>
            </h3>
        </Box >
    );
};

export default MemberRegister;
