import React, { useState, useRef, useEffect, useCallback } from 'react';
import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'

// import '@emoji-mart/react/styles/emoji-mart.css'; // Adjust if necessary

interface EmojiPickerButtonProps {
  onEmojiSelect: (emoji: any) => void;
}

export const EmojiPickerButton: React.FC<EmojiPickerButtonProps> = ({ onEmojiSelect }) => {
  const [showPicker, setShowPicker] = useState<boolean>(false);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const pickerRef = useRef<HTMLDivElement>(null);
  const handleButtonClick = () => {
    //console.log("clicked",showPicker)
    setShowPicker(!showPicker); // Toggle picker visibility
  };

  const handleEmojiSelect = (emoji: any) => {
    console.log("clicked", emoji)
    onEmojiSelect(emoji); // Pass the selected emoji to the parent component
    setShowPicker(false); // Close picker after selection
  };
  const handleClickOutside = useCallback((event: MouseEvent) => {
    if (pickerRef && pickerRef.current && !pickerRef.current.contains(event.target as Node) && !buttonRef.current?.contains(event.target as Node)) {
      setShowPicker(false);
    }
  }, []);

  useEffect(() => {
    if (showPicker) {
      document.addEventListener('click', handleClickOutside);
    } else {
      document.removeEventListener('click', handleClickOutside);
    }

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [showPicker, handleClickOutside]);

  return (
    <div style={{ position: "relative" }}>
      <button ref={buttonRef} onClick={handleButtonClick} type="button" style={{ cursor: 'pointer' }}>
        😀
      </button>
      {showPicker && (
        <div ref={pickerRef} style={{ position: 'absolute', zIndex: 9, top: "10px", left: "10px" }}>
          <Picker onEmojiSelect={handleEmojiSelect} />
        </div>
      )}
    </div>
  );
};

