import { Box, Grid, Autocomplete, TextField, InputAdornment, Button, Typography, InputLabel, Table, TableBody } from "@mui/material"
import { DatePicker } from "@mui/x-date-pickers"
import moment from "moment";
import { useEffect, useState } from "react";
import { FormProvider, Controller, useForm } from "react-hook-form"
import { useAlert } from "src/components/CommonAlert";
import DataTable from "src/components/DataTable";
import { HtmlEditor } from "src/components/HtmlEditor";
import ImageUpload from "src/components/ImageUpload";
import { CONSTANTS } from "src/constants";
import { Coupon, CouponMapping } from "src/intefaces/coupon.interface";
import { WhatsAppMsg } from "src/intefaces/whatsapp-msg.interface";
import axiosService from "src/services/axios";
import { RecordTypes } from "src/types/enum";
import { Member } from "src/types/member-interface";
interface Receipent extends Member {
    status: string;
}
interface SendWhatsappProps {
    members: Member[],
    onCancel: () => any
}

export function SendWhatsapp({
    members,
    onCancel
}: SendWhatsappProps): React.JSX.Element {

    const [complete, setComplete] = useState<boolean>(false);
    const [loading, setLoading] = useState(false);
    const [couponMaster, setCouponMaster] = useState<Coupon[]>([]);
    const [receipents, setReceipents] = useState<Receipent[]>([]);
    const columns = [
        { id: 'id', label: 'Id' },
        { id: "id", label: "UID" },
        { id: "MemberName", label: "Name" },
        { id: "Mobile", label: "Mobile" },
        { id: "status", label: "Status" }
    ];
    const { showAlert } = useAlert();

    const methods = useForm<WhatsAppMsg>({
        defaultValues: {
            members: [],
            message: "",
            image: ""
        }
    });
    useEffect(() => {
        const toList = members.map((_m: any) => { _m.status = ""; return _m });
        setReceipents(toList);
    }, [members])
    const onSend = methods.handleSubmit(async (data: any) => {
        try {
            setLoading(true);
            const payload = { ...data };
            const response = await (await axiosService.post<CouponMapping | any>(`${CONSTANTS.ApiConstants.whatsppMessage}`, payload))
            if (response.status != 200) {
                setLoading(false);
                showAlert(response.data.message || 'Failed to Send whatsapp', 'error');
            } else {
                const toList = receipents;
                response?.data.map((_status: {
                    message: string, status: string, memberid: number
                }) => {
                    const m = toList.find(_m => _m.id == _status.memberid);
                    if (m) {
                        m['status'] = _status.status ?? "Failed";
                    }
                })
                setReceipents(toList);
                setComplete(true);
                setLoading(false);
                showAlert('Message sent Successfully', 'success');

            }
        } catch (ex: any) {
            setLoading(false);
            showAlert(ex.response.data.message || 'Failed to Send whatsapp', 'error');
            console.error("Exception Caught", ex)
        }
    })


    useEffect(() => {
        methods.setValue("members", members.map(_m => _m.id));
    }, [members])

    return <Box> {!complete ? <FormProvider {...methods} >
        <Box component="form" onSubmit={onSend} key="assignForm">
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                    <Controller
                        name="members"
                        control={methods.control}
                        render={() =>
                            <Typography variant="h5"> Send Message to {members?.length} members.</Typography>
                        } />
                </Grid>

                <Grid item xs={12}>
                    <Controller
                        name="message"
                        control={methods.control}
                        render={({ field }) => (
                            <div>
                                <InputLabel>Message</InputLabel>
                                <HtmlEditor
                                    toolbarMode={"basic"}
                                    key={field.name}
                                    {...field}
                                    placeholder="Enter your message"
                                />
                            </div>
                        )} />

                </Grid>
                <Grid item xs={12} sm={6}>
                    <Controller
                        name="image"
                        control={methods.control}
                        render={({ field }) =>
                            <ImageUpload onImageChange={(url: string | null) => { field.onChange(url) }} imageUrl={field.value ?? ""} name={"image"} label="eWallet Image" />
                        }
                    />
                </Grid>

                <Grid item xs={12} sm={12}>
                    <Button disabled={loading} variant="contained" color="primary" type="submit" onClick={onSend}>
                        Send
                    </Button>
                    &nbsp;
                    &nbsp;
                    <Button
                        disabled={loading}
                        variant="outlined"
                        color="secondary"
                        onClick={() => { onCancel && onCancel() }}
                    >
                        Cancel
                    </Button>
                </Grid>

            </Grid>
        </Box>
    </FormProvider> :
        <Box>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                    <Typography variant="h5">Status</Typography>
                </Grid>

                <Grid item xs={12} sm={12}>
                    <DataTable
                        rows={receipents}
                        columns={columns}
                        showActionDelete={false}
                        showActionExpand={false}
                        showActionEdit={false}
                        showActionView={false}
                        isSelectable={false}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>

                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => { onCancel && onCancel() }}
                    >
                        Close
                    </Button>
                </Grid>
            </Grid>
        </Box>
    }
    </Box>

}