import React, { useEffect, useState } from 'react';
import { Box, List, ListItem, ListItemText, Typography, Divider, Stack, IconButton } from '@mui/material';
import { Transactions } from 'src/intefaces/transactions.interface';
import { CONSTANTS } from 'src/constants';
import axiosService from 'src/services/axios';
import { formatLocalDateTime } from 'src/components/Helper';
import { eWallet } from 'src/intefaces/eWallet.interface';
import CancelIcon from '@mui/icons-material/Cancel';
const MTransactionList: React.FC<{ selectedeWallet: eWallet, onClose: () => any }> = ({ selectedeWallet, onClose }) => {
    const [mTxns, setMTxns] = useState<Transactions[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const getTxns = async () => {
        try {
            const response = await axiosService.get<Transactions[] | any>(`${CONSTANTS.ApiConstants.transactions}/${selectedeWallet.id}`)
            if (response.status != 200) {

            } else {
                setLoading(false);
                const json = response.data;
                setMTxns(json);
            }
        } catch (ex: any) {
            setLoading(false)
            setMTxns([])
            console.error("Exception Caught", ex)
        }
    }
    useEffect(() => {
        getTxns();
    }, [])
    return (
        <Stack sx={{ width: '100%', maxWidth: 480, height: "80vh", bgcolor: 'background.paper', borderRadius: 2, p: 3, justifyContent: "space-between" }}>
            <Box>
                <h1 style={{ textAlign: "center" }}>
                    Transactions
                </h1>
                <List>
                    {mTxns.map((transaction, index) => (
                        <React.Fragment key={index}>
                            <ListItem alignItems="flex-start" sx={{ pl: 0, pr: 0 }}>
                                <Stack direction={"row"} sx={{ width: "inherit", justifyContent: "space-between" }}>
                                    <Stack>

                                        <p style={{ margin: 0 }}>
                                            {transaction?.txn_date && formatLocalDateTime(transaction?.txn_date ?? "")}
                                        </p>


                                        <h3>
                                            {transaction.description}
                                        </h3>
                                        <p style={{ margin: 0 }}>
                                            {transaction.ref}
                                        </p>
                                    </Stack>
                                    <h2
                                        style={{ marginLeft: 2, color: (Number(transaction.qty ?? 0) >= 0) ? 'green' : 'red' }}
                                    >
                                        {(Number(transaction.qty ?? 0) >= 0) ? `+${transaction.qty}` : transaction.qty}
                                    </h2>
                                </Stack>
                            </ListItem>
                            <Divider component="li" sx={{ borderColor: 'rgba(0,0,0,0.75)' }} />
                        </React.Fragment>
                    ))}
                </List>
            </Box>
            <IconButton color='primary' size="large" onClick={() => { onClose && onClose() }} aria-label="cancel">
                <CancelIcon sx={{ fontSize: '1.5em' }} />
            </IconButton>
        </Stack>
    );
};

export default MTransactionList;
