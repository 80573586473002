
import React, { useEffect, useRef, useState } from 'react';
import { Tabs, Tab, Box, Typography, IconButton, ListItem, Drawer, Card, CardMedia, Avatar, Stack, Button, Paper, TextField, CircularProgress, Dialog, DialogActions, DialogContent } from '@mui/material';
import { styled } from '@mui/system';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { CONSTANTS } from 'src/constants';
import axiosService from 'src/services/axios';

import { useMerchant } from 'src/context/MerchantContext';
import { CouponMapping } from 'src/intefaces/coupon.interface';
import QRCode from 'qrcode.react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DangerousIcon from '@mui/icons-material/Dangerous';
import CancelIcon from '@mui/icons-material/Cancel';
import { keyframes } from '@mui/system';


import './coupon-page.css';
import moment from 'moment';
import { RecordTypes } from 'src/types/enum';
import { useAuth } from 'src/context/AuthContext';
import { Member } from 'src/types/member-interface';
import { useAlert } from 'src/components/CommonAlert';
import { Coupon } from './coupon-component';
import { TransferCoupon } from './TransferCoupon';
const bounceAnimation = keyframes`
  0% { transform: scale(0); }
  50% { transform: scale(1.1); }
  100% { transform: scale(1); }
`;
const FullWidthTab = styled(Tab)(({ theme }) => ({
    width: '50%', // Make each tab take up the full width
}));

const TabPanel = ({ children, value, index, ...other }: any) => (
    <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
    >
        {value === index && (
            <Box >
                {children}
            </Box>
        )}
    </div>
);
const StyledTabs = styled(Tabs)(({ theme }) => ({
    "border-bottom": 0,
    "font-size": "1em",
    "font-weight": "bold",
    '& .MuiTabs-indicator': {
        display: 'none', // Hide the default indicator
    },
}));
const StyledTab = styled(Tab)(({ theme }) => ({
    borderRadius: '25px',
    margin: theme.spacing(0.5),
    padding: theme.spacing(1, 2),
    color: theme.palette.text.primary,
    minHeight: 0,
    textTransform: 'none'

}));

// Styled components
const StyledListItem = styled(ListItem)(({ theme }) => ({
    '&:nth-of-type(even)': {
        backgroundColor: theme.palette.grey[100],
    },
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.common.white,
    },
}));

const OutletName = styled(Typography)(({ theme }) => ({
    fontWeight: 'bold',
    color: theme.palette.primary.main,
}));

const OutletDetails = styled(Typography)(({ theme }) => ({
    color: theme.palette.text.secondary,
}));

const ArrowIcon = styled(IconButton)(({ theme }) => ({
    marginLeft: 'auto',
}));

const ImageWrapper = styled('div')<{ src: string }>(({ src }) => ({
    backgroundImage: `url(${src})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: 'auto',
    width: '100%',
}));
const MobileCouponsView: React.FC = () => {
    const [value, setValue] = React.useState(0);
    const navigate = useNavigate();
    const [tabs, setTabs] = useState<string[]>(["Active", "Expired", "Redeemed"]);
    const [noticeLayout, setNoticeLayout] = useState<string>();
    const [memberCouponMap, setMemberCouponMap] = useState<CouponMapping[]>([]);
    const { merchantid } = useParams()
    const { merchant, setBackButton } = useMerchant();
    const [selectedCoupon, setSelectedCoupon] = useState<CouponMapping | undefined>();
    const [couponAction, setCouponAction] = useState<"transfer" | "redeem" | undefined>(undefined);
    const [scannedMember, setScannedMember] = useState<Member | undefined>(undefined);
    const [transferComplete, setTransferComplete] = useState<"Success" | "Invalid Phone Number" | "Wrong Password" | "Failed to Redeem" | undefined>(undefined);
    const [loadingCoupons, setLoadingCoupons] = useState<boolean>(true);
    const [uTUsername, setUTUsername] = useState<string>("");
    const [uTPassword, setUTPassword] = useState<string>("");
    const location = useLocation();
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };
    const { isAuthenticated } = useAuth();
    const openOutlet = (id: number) => {
        navigate("/" + id);
        window.scrollTo(0, 0);
        setValue(0)
    }
    const getNoticeLayout = async () => {
        try {
            const response = await axiosService.get<{ id: string, noticeLayout: string } | any>(`${CONSTANTS.ApiConstants.public_getNoticeLayout}/${merchantid}`)
            if (response.status != 200) {

            } else {
                const json = response.data;
                setNoticeLayout(json?.noticeLayout);
            }
        } catch (ex: any) {

            console.error("Exception Caught", ex)
        }
    }
    const { showAlert } = useAlert();
    const getCoupons = async () => {
        try {
            setLoadingCoupons(true)
            const response = await axiosService.get<CouponMapping[] | any>(`${CONSTANTS.ApiConstants.GENERIC.get[RecordTypes.couponMapping]}`)
            if (response.status != 200) {
                setLoadingCoupons(false)
                setMemberCouponMap([])
            } else {
                setLoadingCoupons(false)
                const json = response.data;
                console.log("response", json);
                setMemberCouponMap(json);
            }
        } catch (ex: any) {
            setLoadingCoupons(false)
            setMemberCouponMap([])
            console.error("Exception Caught", ex)
        }
    }
    const postRedeem = async (e: any) => {
        e.preventDefault();
        try {
            const payload = {
                password: uTPassword,
                couponMappingId: selectedCoupon?.id,
                merchantid: merchant?.id
            }
            const response: any = await axiosService.post<any>(`${CONSTANTS.ApiConstants.redeemCoupon}`, payload)
            if (response.status != 200) {
                setTransferComplete("Failed to Redeem");
            } else {
                setTransferComplete("Success");
                //showAlert("Transfer Successful", "success");               

            }

        } catch (ex: any) {
            if (ex.response.status == 401) {
                setTransferComplete("Wrong Password");
            }
            else {
                setTransferComplete("Failed to Redeem");
            }
        }
    }

    useEffect(() => {
        getCoupons()
        getNoticeLayout()
        setBackButton && setBackButton(false);
    }, [])
    // useEffect(() => {
    //     if (selectedCoupon) {
    //         couponTransferMode && startScan();
    //     } else {
    //         stopScan();
    //     }
    // }, [selectedCoupon])
    const closeTransferWindow = () => {
        if (transferComplete == "Success") {
            setSelectedCoupon(undefined);
            setScannedMember(undefined)
            setUTPassword("")
            setUTUsername("")
            setCouponAction(undefined)
            getCoupons();
            setTransferComplete(undefined)
        } else if (transferComplete == "Wrong Password") {
            setTransferComplete(undefined);
        } else {
            setScannedMember(undefined);
            setTransferComplete(undefined);

        }
    }

    const ReedemCoupon = () => {
        return <Dialog fullWidth sx={{ textAlign: "center" }} open={couponAction == "redeem"} onClose={() => setCouponAction(undefined)}>

            <DialogContent>
                {transferComplete ?
                    <Stack spacing={2}>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center'
                            }}
                        >
                            {transferComplete == "Success" ?
                                <CheckCircleIcon
                                    sx={{
                                        fontSize: '6em',
                                        color: '#4caf50',
                                        animation: `${bounceAnimation} 0.5s ease-in-out forwards`,
                                    }}
                                /> : <CancelIcon
                                    sx={{
                                        fontSize: '6em',
                                        color: '#ff0000',
                                        animation: `${bounceAnimation} 0.5s ease-in-out forwards`,
                                    }}
                                />}

                        </Box>
                        <h2>
                            {transferComplete}
                        </h2>
                        <Box>
                            <Button fullWidth type="button" color="primary" variant="outlined" size='large' onClick={() => closeTransferWindow()}>
                                <h3 style={{ fontWeight: "normal" }}>  Ok</h3>
                            </Button>
                        </Box>
                    </Stack>
                    :
                    <form onSubmit={postRedeem}>
                        <TextField
                            onChange={(e) => { setUTPassword(e.target.value) }}
                            fullWidth
                            id="password"
                            sx={{ '& .MuiInputBase-input': { fontSize: "1.5em" } }}
                            placeholder="Password"
                        />
                        <DialogActions sx={{ pl: 0, pr: 0 }}>
                            <Button size="large" fullWidth type="submit" color="primary" variant="contained" disableElevation={true}>
                                <h3 style={{ fontWeight: "normal" }}>Ok</h3>
                            </Button>
                            <Button size="large" fullWidth onClick={() => setCouponAction(undefined)} color="primary" variant="outlined">
                                <h3 style={{ fontWeight: "normal" }}>Cancel</h3>
                            </Button>
                        </DialogActions>

                    </form>
                }
            </DialogContent>
        </Dialog>
    }


    const CouponDetails = () => {
        if (selectedCoupon)
            return <Box
                sx={{ padding: 3 }}
                role="presentation"
            >
                <Stack style={{ alignItems: "center" }}>
                    <h1 style={{ textAlign: "center" }}>
                        {selectedCoupon?.coupon?.name}
                    </h1>
                    <p style={{ textAlign: "center", margin: 0 }}>
                        {selectedCoupon?.validTill ? `Expiry : ${moment(selectedCoupon?.validTill ?? null).utc(true).local().format('DD-MM-yyyy')}` : ""}
                    </p>
                    <Stack sx={{ mt: 4, alignItems: "center" }}>
                        <QRCode value={selectedCoupon?.coupon?.id.toString()} size={150} />
                        <h5 style={{ fontWeight: "normal" }}>{selectedCoupon?.id.toString()}</h5>
                    </Stack>
                    <Stack direction="row" sx={{ mt: 4, width: "100%", "justifyContent": "center" }}  >
                        <Button size='large' variant="outlined" style={{ flex: 1 }} color="primary" onClick={() => setCouponAction("transfer")}><h3 style={{ fontWeight: "normal" }}> Transfer</h3></Button>
                        &nbsp;
                        &nbsp;
                        &nbsp;
                        <Button size='large' variant="outlined" color="primary" style={{ flex: 1 }} onClick={() => setCouponAction("redeem")}><h3 style={{ fontWeight: "normal" }}>Redeem</h3></Button>
                    </Stack>
                    {
                        selectedCoupon?.coupon?.description && selectedCoupon?.coupon?.description != '<p><br></p>' ? <Paper square={true} elevation={0} sx={{ mt: 2, borderRadius: "10px", overflow: "hidden", padding: 3, backgroundColor: "#eee", width: "100%", textAlign: "left" }}>
                            {/* <Typography variant="h6">Description</Typography> */}
                            <Typography className='business-desc' variant="body2" dangerouslySetInnerHTML={{ __html: (selectedCoupon?.coupon?.description || "").replace(/<p><br><\/p>/g, '') }} sx={{ overflow: "hidden" }} />
                        </Paper> : <></>
                    }
                    {
                        selectedCoupon?.coupon?.terms && selectedCoupon?.coupon?.terms != '<p><br></p>' ? <Paper square={true} elevation={0} sx={{ mt: 2, borderRadius: "10px", overflow: "hidden", padding: 3, backgroundColor: "#eee", width: "100%", textAlign: "left" }}>
                            {/* <Typography variant="h6">Description</Typography> */}
                            <Typography className='business-desc' variant="body2" dangerouslySetInnerHTML={{ __html: (selectedCoupon?.coupon?.terms || "").replace(/<p><br><\/p>/g, '') }} sx={{ overflow: "hidden" }} />
                        </Paper> : <></>
                    }
                </Stack>
            </Box>
        else
            return <></>
    }
    const getPanelbody = (tabIndex: number) => {
        const filtered = memberCouponMap && memberCouponMap.filter((n) => {
            if (n.coupon) {
                const isValidFromToday = moment(new Date()).isSameOrAfter(moment(n?.validFrom), 'date');
                const isExpiredBeforeToday = moment(n?.validTill).isBefore(moment(new Date()), 'date');
                const isNtRedeemed = !n?.redeemed;
                if (value == 0) { //active
                    return n?.validFrom && n?.validTill && isValidFromToday && !isExpiredBeforeToday && isNtRedeemed;
                } else if (value == 1) { //expired
                    return isExpiredBeforeToday && isNtRedeemed;
                } else if (value == 2) { // redeemed
                    return !isNtRedeemed;
                }
                return false
            } else {
                return false
            }
        });

        return filtered && filtered.length ? filtered.map((n: CouponMapping) => {
            if (n?.coupon?.format == "image") {
                return <Avatar onClick={() => value == 0 && setSelectedCoupon(n)} variant="square" src={n?.coupon?.image} style={{ marginTop: "8px", width: "100%", height: "auto", filter: value == 0 ? 'unset' : 'grayscale(100%)' }} />
            }
            return <Box onClick={() => value == 0 && setSelectedCoupon(n)}><Coupon mcoupon={n} disabled={value != 0}></Coupon></Box>
        }) :
            (<Typography sx={{ textAlign: "center" }}>Sorry! No Coupons for you at this moment.</Typography>)
    }
    if (loadingCoupons) {
        return (<Box sx={{ minHeight: "80vh", alignContent: "center", justifyItems: "center" }}><Typography sx={{ textAlign: "center" }}>Loading your coupons...</Typography></Box>);
    }
    return (
        isAuthenticated ? <Box sx={{ width: '100%', p: 3 }}>
            {memberCouponMap && memberCouponMap.length ? <StyledTabs
                value={value}
                onChange={handleChange}
                aria-label=""
                TabIndicatorProps={{ style: { background: merchant?.HeaderColorCode } }}
                sx={{
                    "&.Mui-selected": {
                        background: merchant?.HeaderColorCode || "initial",
                        color: "white",
                        fontSize: "1em",
                        fontWeight: "bold"
                    },
                    fontSize: "1em",
                    fontWeight: "bold"
                }}
            >
                {tabs && tabs.map((t) => {
                    return <StyledTab sx={{
                        "&.Mui-selected": {
                            background: merchant?.HeaderColorCode || "initial",
                            color: "white",
                            fontSize: "1em",
                            fontWeight: "bold"
                        },
                        fontSize: "1em",
                        fontWeight: "bold"
                    }}
                        disableRipple label={t} id={t} aria-controls="map" />
                })}
            </StyledTabs> : <></>}
            <TabPanel fullWidth value={1} index={1} style={{ textAlign: "center" }}>
                {getPanelbody(value)}
            </TabPanel>
            {
                selectedCoupon && <Drawer
                    variant={'temporary'}
                    open={!!selectedCoupon}
                    onClose={() => setSelectedCoupon(undefined)}
                    anchor='bottom'
                    PaperProps={{
                        sx: {
                            borderRadius: '16px 16px 0 0', // Rounded corners at the top
                            height: '85vh', // Adjust height as needed
                        },
                    }}
                >
                    {couponAction == "transfer" ? <TransferCoupon selectedCoupon={selectedCoupon} setSelectedCoupon={setSelectedCoupon} onClose={(e: { reason: "transfer" | "cancel" }) => { setCouponAction(undefined); e.reason == "transfer" && getCoupons() }} /> : CouponDetails()}
                    {couponAction == "redeem" ? ReedemCoupon() : <></>}
                </Drawer>
            }
        </Box > :
            <Stack sx={{ width: "100%", height: "80vh", alignItems: "center", justifyContent: "center", p: 3 }}>
                <Typography variant="body1">Login to check your coupons</Typography>
                <br />
                <Button variant="text" onClick={() => navigate(`/${merchant?.id}/membersignin`, { state: { from: location.pathname } })}>Login</Button>
            </Stack>
    );
}

export default MobileCouponsView;
