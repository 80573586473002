import * as React from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Plus as PlusIcon } from '@phosphor-icons/react/dist/ssr/Plus';
import { MerchantsFilters } from './merchants-filters';
import { MerchantsTable } from './merchants-table';
import { useNavigate } from 'react-router-dom';
import axiosService from '../../services/axios';
import { Merchant } from '../../types/merchant-interface';
import { Box, Card, OutlinedInput, InputAdornment } from '@mui/material';
import { MagnifyingGlass as MagnifyingGlassIcon } from '@phosphor-icons/react/dist/ssr/MagnifyingGlass';
import { useState } from 'react';
import { CONSTANTS } from '../../constants';
import { RecordTypes } from '../../types/enum';

export default function MerchantsPage({ recordType }: { recordType: "outlet" | "merchant" | "partner" }): React.JSX.Element {
  const page = 0;
  const rowsPerPage = 25;
  const [merchants, setMerchants] = useState<Merchant[]>([]);
  const [filteredData, setFilteredData] = useState<Merchant[]>([]);
  const [searchQuery, setSearchQuery] = useState('');

  React.useEffect(() => {
    setFilteredData(merchants.filter((item: Merchant) => {
      return (item?.MerchantName && item?.MerchantName.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (item?.MerchantPhone1 && item?.MerchantPhone1.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (item?.MerchantEmail && item?.MerchantEmail.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (item?.MerchantIntro && item?.MerchantIntro.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (item?.MerchantPhone2 && item?.MerchantPhone2.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (item?.MerchantAddress && item?.MerchantAddress.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (item?.MerchantCode && item?.MerchantCode.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (item?.username && item?.username.toLowerCase().includes(searchQuery.toLowerCase()))
    }));
  }, [searchQuery])

  const getMerchants = async () => {
    try {
      const response = await axiosService.get<Merchant[]>(`${CONSTANTS.ApiConstants.GENERIC.get[recordType]}`)
      if (response.status != 200) {
        setMerchants([])
        setFilteredData([])
      } else {
        const json = response.data;
        setMerchants(json);
        setFilteredData(json)
      }
    } catch (ex) {
      setMerchants([])
      setFilteredData([])
      console.error("Exception Caught", ex)
    }
  }
  React.useEffect(() => {
    getMerchants()
  }, [])
  const addRecord = () => {
    if (recordType == RecordTypes.merchant) {
      navigate(`/merchants/add`)
    } else {
      navigate(`/${recordType}/add`)
    }
  }
  const onDeleteCallback = () => getMerchants()
  const navigate = useNavigate();
  return (
    <Stack spacing={3}>
      <Stack direction="row" spacing={3}>
        <Stack spacing={1} sx={{ flex: '1 1 auto' }}>
          <Typography variant="h4" sx={{ textTransform: "capitalize" }}>{recordType} List</Typography>
          <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
          </Stack>
        </Stack>
        <div>
          <Button
            startIcon={<PlusIcon fontSize="var(--icon-fontSize-md)" />}
            variant="text"
            onClick={addRecord}>
            Add
          </Button>
        </div>
      </Stack>
      <Box>
        <Card sx={{ p: 2 }}>
          <OutlinedInput
            onChange={(e) => setSearchQuery(e.target.value)}
            fullWidth
            placeholder={`Search ${recordType}`}
            startAdornment={
              <InputAdornment position="start">
                <MagnifyingGlassIcon fontSize="var(--icon-fontSize-md)" />
              </InputAdornment>
            }
            sx={{ maxWidth: '500px' }}
          />
        </Card>
      </Box>
      <MerchantsTable
        count={filteredData?.length ?? 0}
        page={page}
        rows={filteredData}
        rowsPerPage={rowsPerPage}
        onDeleteCallback={onDeleteCallback}
        recordType={recordType}
      />
    </Stack>
  );
}


