
import * as React from 'react';
import { useSelection } from '../../hooks/use-selection';
import { Staff } from '../../types/staff-interface';
import DataTable from '../../components/DataTable';
import { CONSTANTS } from '../../constants';
import axiosService from '../../services/axios';
import { useAlert } from '../../components/CommonAlert';
import DeleteConfirmationDialog from '../../components/ConfirmationDialog';
import { useNavigate } from 'react-router-dom';
import { RecordTypes } from '../../types/enum';
import { Paper } from '@mui/material';
import { Member } from 'src/types/member-interface';
import { Merchant } from 'src/types/merchant-interface';



interface StaffsTableProps {
  count?: number;
  page?: number;
  rows?: Staff[];
  rowsPerPage?: number;
  onDeleteCallback?: () => any
}

export function StaffsTable({
  count = 0,
  rows = [],
  page = 0,
  rowsPerPage = 0,
  onDeleteCallback
}: StaffsTableProps): React.JSX.Element {
  const rowIds = React.useMemo(() => {
    return rows.map((staff) => staff.id);
  }, [rows]);
  const navigate = useNavigate();
  const { selected } = useSelection(rowIds);
  const { showAlert } = useAlert();
  const selectedSome = (selected?.size ?? 0) > 0 && (selected?.size ?? 0) < rows.length;
  const [selectedId, setSelectedId] = React.useState<number | undefined>(undefined);
  const [openDeleteDlg, setOpenDeleteDlg] = React.useState<boolean>(false);
  const columns = [
    { id: "id", label: "UID" },
    { id: "firstName", label: "Firt Name" },
    { id: "lastName", label: "Last Name" },
    { id: "email", label: "Email" },
    { id: "phone", label: "Phone" },
    { id: "Gender", label: "Gender" }
  ];
  const handleEdit = (id: number, row: Staff) => {
    navigate(`/staff/edit/${id}`);
  };

  const handleDelete = async (id: number, row: Staff) => {
    setSelectedId(id);
    setOpenDeleteDlg(true);
  };

  const handleView = (id: number, row: Staff) => {
    navigate(`/staff/view/${id}`);
  };
  const onDeleteConfirm = async () => {
    try {
      setOpenDeleteDlg(false)
      const response = await axiosService.delete<Staff[]>(`${CONSTANTS.ApiConstants.GENERIC.delete[RecordTypes.staff]}/${selectedId}`);
      if (response.status != 200) {
        showAlert('Failed to Delete Merchant', 'error');
      } else {
        showAlert('Merchant Deleted Successfully', 'success');
        onDeleteCallback && onDeleteCallback();
      }
    } catch (ex) {
      showAlert('Failed to Delete Merchant', 'error');
      console.error("Exception Caught", ex)
    } finally {
      setSelectedId(undefined);

    }
  }
  return (<div>
    <Paper square={true}>  <DataTable
      rows={rows}
      columns={columns}
      onEdit={handleEdit}
      onDelete={handleDelete}
      onView={handleView}
    /></Paper>
    <DeleteConfirmationDialog
      open={openDeleteDlg}
      onClose={() => { setOpenDeleteDlg(false); setSelectedId(undefined) }}
      onConfirm={onDeleteConfirm}
      message={"Are you sure you want to delete merchant"}
      confirmLabel={"Delete"}
      cancelLabel={"Cancel"}
      title={"Confirm Delete"}
    />
  </div>)
}
