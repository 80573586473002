'use client';

import * as React from 'react';
import Box from '@mui/material/Box';

import { Button, Typography } from '@mui/material';
import { Merchant } from '../../types/merchant-interface';
import { FormProvider, useForm } from 'react-hook-form';
import { useAlert } from '../../components/CommonAlert';
import { CONSTANTS } from '../../constants';
import axiosService from '../../services/axios';
import { RecordActions, RecordTypes, Roles } from '../../types/enum';
import { MerchantForm } from './merchant-form/form';
import { useState } from 'react';
import { useAuth } from '../../context/AuthContext';
import { Link, useNavigate } from 'react-router-dom';

export function MerchantAccount({ recordType }: { recordType: "outlet" | "merchant" | "partner" }): React.JSX.Element {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { user } = useAuth();
  const { showAlert } = useAlert();
  const methods = useForm<any>({
    defaultValues: {
      "MerchantCode": "",
      "MerchantName": "",
      "MerchantSubTitle": "",
      "MerchantSMSHeader": "",
      "MerchantAddress": "",
      "MerchantPhone1": "",
      "MerchantPhone2": "",
      "MerchantEmail": "",
      "MerchantIntro": "",
      "MerchantDescription": "",
      "MerchantLatitude": "",
      "MerchantLongitude": "",
      "MerchantLogo": "",
      "MerchantCover": "",
      "AppLogo": "",
      "HeaderColorCode": "",
      "HeaderLogo": "",
      "FooterColorCode": "",
      "FooterButtonColor": "",
      "FooterActiveButtonColor": "",
      "ColorCodeButton": "",
      "ColorCodeStoreTheme": "",
      "NoticeBoardFontsizeTitle": "",
      "NoticeBoardFontsizeDescription": "",
      "NoticeBoardFontsizeTag": "",
      "RegistrationThankYouMessage": "",
      "RegistrationThankYouImage": "",
      "UrlPlaystore": "",
      "UrlAppstore": "",
      "UrlAppGallery": "",
      "FunctionBranches": false,
      "FunctionPartner": false,
      "FunctionProduct": false,
      "FunctionService": false,
      "FunctionPOS": false,
      "FunctionStore": false,
      "FunctionOrdering": false,
      "FunctionBooking": false,
      "FunctionMessage": false,
      "FunctionNoticeboard": false,
      "FunctionDirectory": false,
      "FunctionGallery": false,
      "FunctionGame": false,
      "FunctionMembershipRewardCoupon": false,
      "FunctionMembershipRewardeWallet": false,
      "FunctionMembershipRewardVIPCard": false,
      "FunctionMembershipRewardChopCard": false,
      "FunctionMembershipRewardUnlimited": false,
      "FunctionMembershipRewardRedemption": false,
      "FunctionMembershipRewardAngpao": false,
      "FunctionMembershipRewardSpinWin": false,
      "FunctionMembershipRewardReferral": false,
      "NumberofCoupon": "",
      "NumberofeWallet": "",
      "NumberofVIPCard": "",
      "NumberofChopCard": "",
      "NumberofUnlimited": "",
      "NumberofRedemption": "",
      "NumberofAngpao": "",
      "NumberofSpinWin": "",
      "NumberofBranches": "",
      "NumberofProduct": "",
      "NumberofService": "",
      "NumberofOrdering": "",
      "NumberofDirectory": "",
      "NumberofGallery": "",
      "WhatsappAppkey": "",
      "WhatsappAuthkey": "",
      "MetaAPIKey": "",
      "MetaAuthKey": "",
      "PayerID": "",
      "PayerKey": "",
      "StripePublicKey": "",
      "StripeSecreteKey": "",
      "OTPMode": "",
      "TermnPolicy": "",
      "Share2EarnDescription": "",
      "MapZoomLevel":0,
      "MsgEmailPassword": "",
      "MsgEmailId": "",
      "MsgEmailPort": "",
      "MsgEmailHost": "",
      "MemberCard":""
    }
  });
  const editMerchant = async (data: any) => {
    try {
      const response = await axiosService.put<Merchant|any>(`${CONSTANTS.ApiConstants.updateAccount}`, data)
      if (response.status != 200) {
        setLoading(false);
        showAlert(response?.data?.message || 'Failed to Save Merchant', 'error');
      } else {
        const json = response.data;
        setLoading(false);
        showAlert('Merchant Created Successfully', 'success');
      }
    } catch (ex:any) {
      setLoading(false);
      showAlert(ex?.response?.data?.message  ||'Failed to Save Merchant', 'error');
      console.error("Exception Caught", ex)
    }
  }

  const getMerchantDetails = async () => {
    try {
      const response = await axiosService.get<Merchant[]>(`${CONSTANTS.ApiConstants.getAccount}`)
      if (response.status != 200) {
        showAlert('Failed to Retrive Member Details', 'error');
      } else {
        const json = response.data;
        methods.reset(json);
      }
    } catch (ex) {
      showAlert('Failed to Retrive Member Details', 'error');
      console.error("Exception Caught", ex)
    }
  }
  const onSubmit = methods.handleSubmit(async (data: any) => {
    try {
      setTimeout(() => {
        console.log(loading)
        if (loading) {
          return
        }
        setLoading(true);
        editMerchant(data);

      });

    }
    catch (ex) {
      setLoading(false);
    } finally {

    }



  });
  React.useEffect(() => {
    getMerchantDetails();
  }, [])
  const getRecordType = (): "outlet" | "merchant" | "partner" => {
    switch (user?.role) {
      case Roles.merchant:
        return RecordTypes.merchant
        break;
      case Roles.outlet:
        return RecordTypes.outlet
        break;
      case Roles.partner:
        return RecordTypes.partner
        break;
      default:
        return RecordTypes.merchant
        break;
    }
  }
  return (
    <Box sx={{ width: 'calc(100vw - 300px)', bgcolor: 'background.paper' }}>
      <FormProvider {...methods} >
        <Box component="form" onSubmit={onSubmit} sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <MerchantForm action={RecordActions.edit} recordType={recordType ? recordType : getRecordType()} />
          {<Box
            sx={{
              position: 'fixed',
              bottom: 0,
              left: 0,
              zIndex: 9,
              width: '100%',
              backgroundColor: '#f5f5f5',
              padding: 2,
              display: 'flex',
              justifyContent: 'flex-end', // Align the button to the right
              boxShadow: '0px -1px 5px rgba(0,0,0,0.1)', // Optional shadow
            }}          >
            <Button href={"/" + methods.getValues('id')} target="_blank"  >Preview</Button>
            &nbsp;
            &nbsp;
            &nbsp;
            <Button disabled={loading} variant="contained" color="primary" type="submit">
              Save
            </Button>
          </Box>
          }
        </Box>

      </FormProvider>
    </Box>
  );
}
