import React, { createContext, useState, useMemo, useContext, ReactNode, useEffect } from 'react';
import { ThemeProvider, createTheme, CssBaseline, Button } from '@mui/material';
import { deepPurple, blue } from '@mui/material/colors';
import { CONSTANTS } from 'src/constants';
import axiosService from 'src/services/axios';
import { Merchant } from 'src/types/merchant-interface';
import { useParams } from 'react-router-dom';
import { useAuth } from 'src/context/AuthContext';
import { Roles } from 'src/types/enum';

// Define the context for theme control
interface ThemeContextType {
  toggleTheme: () => void;
  themeMode: 'light' | 'dark';
  setMerchantId?: any
}

const ThemeContext = createContext<ThemeContextType | undefined>(undefined);

export const useThemeContext = (): ThemeContextType => {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error('useThemeContext must be used within a ThemeProvider');
  }
  return context;
};
interface CustomThemeProviderProps {
  children: ReactNode; // Explicitly define children as a prop
}

// ThemeProvider component
const CustomThemeProvider: React.FC<CustomThemeProviderProps> = ({ children }) => {
  const [themeMode, setThemeMode] = useState<'light' | 'dark'>('light');
  const [merchant, setMerchant] = useState<Merchant>();
  const [merchantId, setMerchantId] = useState<number>();
  const { user } = useAuth();
  // Toggle between light and dark modes
  const toggleTheme = () => {
    setThemeMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
  };
  const getMerchant = async (merchantid: number) => {
    try {
      const response = await axiosService.get<Merchant | any>(`${CONSTANTS.ApiConstants.getMerchantProfile}/${merchantid}`)
      if (response.status != 200) {
        setMerchant(undefined)
      } else {
        setMerchant(response?.data)
      }
    } catch (ex: any) {
      setMerchant(undefined)
    }
  };
  useEffect(() => {
    let merchantid;
    switch (user?.role) {
      case Roles.merchant:
        merchantid = user?.account?.id;
        break;
      case Roles.outlet:
        merchantid = user?.account?.merchantid;
        break;
      case Roles.staff:
        merchantid = user?.account?.merchantid;
        break;
      case Roles.member:
        merchantid = user?.account?.merchantid;
        break;
      default:
        merchantid = null;
        break;
    }
    merchantid && getMerchant(merchantid);
  }, [user]);
  // Dynamically create theme based on the current mode
  const theme = useMemo(
    () => {
      return createTheme({
        palette: {
          mode: themeMode,
          primary: merchant?.ColorCodeButton ? {
            main: merchant?.ColorCodeButton
          } : {
            main: "#cc0400"
          },
          secondary: {
            main: "#636b74",
          }
        },
        typography: {
          fontFamily: '"Source Sans Pro", sans-serif',
          subtitle1: {
            lineHeight: "16.1px"
          }
        },
        components: {
          MuiButton: {
            styleOverrides: {
              root: {
                fontFamily: '"Source Sans Pro", sans-serif'
              },
            },
          },
          MuiInputLabel: {
            styleOverrides: {
              root: {
                color: 'black',
                fontSize: "14px"
              },
            },
          }
        },
      })
    },
    [merchant]
  );

  return (
    <ThemeContext.Provider value={{ toggleTheme, themeMode, setMerchantId }}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </ThemeContext.Provider>
  );
};

export default CustomThemeProvider;
