import { MagnifyingGlass as MagnifyingGlassIcon } from '@phosphor-icons/react/dist/ssr/MagnifyingGlass';
import * as React from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Plus as PlusIcon } from '@phosphor-icons/react/dist/ssr/Plus';
import { UserPlus as UserPlus } from '@phosphor-icons/react';
import { MagnifyingGlass as Search } from '@phosphor-icons/react';

import { CONSTANTS } from '../../constants';
import { TransactionsTable } from './transaction-table';
import { useNavigate } from 'react-router-dom';
import axiosService from '../../services/axios';
import { Box, Card, OutlinedInput, InputAdornment, CardContent, Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, MenuItem, Select, Switch, TextField, InputLabel, Drawer } from '@mui/material';
import { useAlert } from '../../components/CommonAlert';
import TransactionInviteDialog from '../../components/ConfirmationDialog';
import { useAuth } from '../../context/AuthContext';
import { RecordTypes, Roles } from 'src/types/enum';
import moment from 'moment';
import NoticeTabs from '../notice-board/notice-tabs';
import { Controller, useForm } from 'react-hook-form';
import { Transactions } from 'src/intefaces/transactions.interface';
export default function TransactionsPage(): React.JSX.Element {
    const page = 0;
    const rowsPerPage = 25;
    const [transactions, setTransactions] = React.useState<Transactions[]>([]);
    const [filteredData, setFilteredData] = React.useState<Transactions[]>([]);
    const [searchQuery, setSearchQuery] = React.useState('');
    const { user } = useAuth();
 
    const { showAlert } = useAlert();
  
    const getTransactions = async () => {
        try {
            const response = await axiosService.get<Transactions[] | any>(`${CONSTANTS.ApiConstants.GENERIC.get[RecordTypes.transations]}`)
            if (response.status != 200) {
                showAlert(response?.data?.message ?? 'Failed to get transactions', 'error');
                setTransactions([])
                setFilteredData([])
            } else {
                const json = response.data;

                setTransactions(json);
                setFilteredData(json)
            }
        } catch (ex: any) {
            setTransactions([])
            setFilteredData([])
            showAlert(ex?.response?.data?.message ?? 'Failed to get transactions', 'error');
            console.error("Exception Caught", ex)
        }
    }

    React.useEffect(() => {
        getTransactions();
    }, [])

    React.useEffect(() => {

        setFilteredData(transactions.filter((item: Transactions) => {
            console.log("item?.dateFrom", item)
            return (item?.description && item?.description.toLowerCase().toString().includes(searchQuery.toLowerCase())) ||
                (item?.action && item?.action.toLowerCase().includes(searchQuery.toLowerCase())) ||
                (item?.ref && item?.ref.toLowerCase().includes(searchQuery.toLowerCase())) ||
                (item?.refType && item?.refType.toLowerCase().includes(searchQuery.toLowerCase())) ||
                (item?.txn_date && item?.txn_date.toLowerCase().includes(searchQuery.toLowerCase()))||
                (item?.member_id && item?.member_id.toString().includes(searchQuery.toLowerCase()))
        }));
    }, [searchQuery])
    const onDeleteCallback = () => getTransactions();
    const navigate = useNavigate();
    return (
        <Stack spacing={3}>
            <Stack direction="row" spacing={3}>
                <Stack spacing={1} sx={{ flex: '1 1 auto' }}>
                    <Typography variant="h4">Transactions</Typography>

                </Stack>
            </Stack>
            <Box>
                <Card sx={{ p: 2 }}>
                    <OutlinedInput
                        onChange={(e) => setSearchQuery(e.target.value)}
                        fullWidth
                        placeholder="Search log"
                        startAdornment={
                            <InputAdornment position="start">
                                <MagnifyingGlassIcon fontSize="var(--icon-fontSize-md)" />
                            </InputAdornment>
                        }
                        sx={{ maxWidth: '500px' }}
                    />
                </Card>
            </Box>
            <TransactionsTable
                count={filteredData?.length ?? 0}
                page={page}
                rows={filteredData}
                rowsPerPage={rowsPerPage}
                onDeleteCallback={onDeleteCallback}
            />

        </Stack>
    );
}
