import { Box, Grid, Autocomplete, TextField, InputAdornment, Button, Typography } from "@mui/material"
import { DatePicker } from "@mui/x-date-pickers"
import moment from "moment";
import { useEffect, useState } from "react";
import { FormProvider, Controller, useForm } from "react-hook-form"
import { useAlert } from "src/components/CommonAlert";
import DataTable from "src/components/DataTable";
import { CONSTANTS } from "src/constants";
import { Coupon, CouponMapping } from "src/intefaces/coupon.interface";
import axiosService from "src/services/axios";
import { RecordTypes } from "src/types/enum";
import { Member } from "src/types/member-interface";
interface AsssignCouponsProps {
    members: Member[],
    onCancel: () => any,
    couponMapping?: CouponMapping,
    formMode: "add" | "edit"
}
interface Receipent extends Member {
    status: string;
    couponname: string;
    qty: string
}
export function AsssignCoupons({
    members,
    onCancel,
    couponMapping,
    formMode
}: AsssignCouponsProps): React.JSX.Element {
    const [validAfterDay, setValidAfterDay] = useState<string>("0");
    const [validTillDay, setValidTillDay] = useState<string>("0");
    const [memberCoupons, setMemberCoupons] = useState<CouponMapping[]>([]);
    const [member, setMember] = useState<Member>();
    const [isOpen, setIsOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [couponMaster, setCouponMaster] = useState<Coupon[]>([]);
    const [receipents, setReceipents] = useState<Receipent[]>([]);
    const columns = [
        { id: 'id', label: 'Id' },
        { id: "MemberName", label: "Name" },
        { id: "Mobile", label: "Mobile" },
        { id: "couponname", label: "Name" },
        { id: "qty", label: "qty" },
        { id: "status", label: "Status" }
    ];
    const { showAlert } = useAlert();
    const [complete, setComplete] = useState<boolean>(false);
    const methods = useForm<CouponMapping>({
        defaultValues: {
            id: 0,
            coupon_id: undefined,
            validFrom: moment().startOf('day'),
            validTill: moment().endOf('day'),
            member_id: undefined,
            redeemed: false,
            qty: undefined
        }
    });
    useEffect(() => {
        if (couponMapping) {
            methods.reset({
                id: couponMapping.id,
                coupon: couponMaster.find(c => c.id == couponMapping.coupon_id),
                validFrom: couponMapping.validFrom ? moment(couponMapping.validFrom) : null,
                validTill: couponMapping.validTill ? moment(couponMapping.validTill) : null,
                member_id: couponMapping.member_id,
                redeemed: couponMapping.redeemed
            });
            couponMaster.find(c => c.id == couponMapping.coupon_id);
            setValidAfterDay(moment(couponMapping.validFrom).diff(moment(), 'days').toString());
            setValidTillDay(moment(couponMapping.validTill).diff(moment(), 'days').toString());
        }
    }, [couponMapping])
    const validAfterWatch = methods.watch("validFrom")
    const validUntilWatch = methods.watch("validTill")
    const couponWatch = methods.watch("coupon")
    const onUpdate = methods.handleSubmit(async (data: any) => {
        try {
            const payload = { ...data };
            payload.coupon_id = data?.coupon?.id
            const response = await (await axiosService.put<CouponMapping | any>(`${CONSTANTS.ApiConstants.GENERIC.put[RecordTypes.couponMapping]}/${methods.getValues("id")}`, payload))
            if (response.status != 200) {
                setLoading(false);
                showAlert(response.data.message || 'Failed to Save Coupon', 'error');
            } else {
                const json = response.data;
                setLoading(false);
                showAlert('Coupon Updated Successfully', 'success');
                onCancel && onCancel();
            }
        } catch (ex: any) {
            setLoading(false);
            showAlert(ex.response.data.message || 'Failed to Save Coupon', 'error');
            console.error("Exception Caught", ex)
        }
    });
    const onAssign = methods.handleSubmit(async (data: any) => {
        try {
            const payload = { ...data };
            payload.coupon_id = data?.coupon?.id
            const response = await (await axiosService.post<CouponMapping | any>(`${CONSTANTS.ApiConstants.assignCouponToMany}`, payload))
            if (response.status != 200) {
                setLoading(false);
                showAlert(response.data.message || 'Failed to Save Coupon', 'error');
                setComplete(false);
            } else {
                const json = response.data;
                setLoading(false);
                showAlert('Coupon Assigned Successfully', 'success');
                setComplete(true);
                const _update = receipents.map(r => {
                    r.status = "Success";
                    r.couponname = payload.coupon.name;
                    r.qty = payload.qty;
                    return r
                });
                setReceipents(_update);
            }
        } catch (ex: any) {
            setLoading(false);
            setComplete(false);
            showAlert(ex.response.data.message || 'Failed to Save Coupon', 'error');
            console.error("Exception Caught", ex)
        }
    })
    const toggleDrawer = (open: boolean) => {
        setIsOpen(open);
    };
    const getCoupons = async () => {
        try {
            const response = await axiosService.get<Coupon[] | any>(`${CONSTANTS.ApiConstants.GENERIC.get[RecordTypes.coupon]}`)
            if (response.status != 200) {
                showAlert(response?.data?.message ?? 'Failed to get coupons', 'error');
                setCouponMaster([])
            } else {
                const json = response.data;

                setCouponMaster(json);
            }
        } catch (ex: any) {
            setCouponMaster([])
            showAlert(ex?.response?.data?.message ?? 'Failed to get coupons', 'error');
            console.error("Exception Caught", ex)
        }
    }
    useEffect(() => {
        if (moment(validAfterWatch).diff(moment(), "days") != Number(validAfterDay))
            methods.setValue("validFrom", moment().add(validAfterDay ?? 0, "days").startOf('day'))
        if (moment(validAfterWatch).diff(moment(), "days") != Number(validTillDay))
            methods.setValue("validTill", moment().add(validTillDay ?? 0, "days").endOf('day'))
    }, [validTillDay, validAfterDay])
    useEffect(() => {
        if (couponWatch) {
            setValidTillDay(couponWatch?.validUntil?.toString() || "0");
            setValidAfterDay(couponWatch?.validAfter?.toString() || "0");
        }
    }, [couponWatch])
    useEffect(() => {
        getCoupons();
    }, [])
    useEffect(() => {
        methods.setValue("members", members.map(_m => _m.id));
        const toList = members.map((_m: any) => { _m.status = ""; return _m });
        setReceipents(toList);
    }, [members])

    return (!complete ?
        <FormProvider {...methods} >
            <Box component="form" onSubmit={formMode == "edit" ? onUpdate : onAssign}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                        <Controller
                            name="members"
                            control={methods.control}
                            render={({ field, fieldState }) =>
                                <Typography variant="h6">
                                    {formMode == "edit" ? "Change Coupon Expiry" : `Assign Coupon to ${members?.length == 1 ? member?.MemberName : members?.length + " Members"}`}
                                </Typography>

                            } />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Controller
                            name="coupon"
                            control={methods.control}
                            render={({ field, fieldState }) =>
                                <Autocomplete
                                    onChange={(_, newValue) => field.onChange(newValue)}
                                    disabled={"edit" == formMode}
                                    value={field.value || null} // Ensure value is a Coupon object or null
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    options={couponMaster}
                                    getOptionLabel={(option) => option.name}
                                    loading={loading}
                                    renderInput={(params) => <TextField {...params} label="Select Coupon" variant="outlined" />}
                                />
                            } />
                    </Grid>
                    {formMode == "add" ? <Grid item xs={12} sm={6}>
                        <Controller
                            name="qty"
                            control={methods.control}
                            render={({ field, fieldState }) =>
                                <TextField
                                    {...field}
                                    fullWidth
                                    id="qty"
                                    label="Quantity"
                                />
                            } />
                    </Grid> : null}
                    <Grid item xs={6} sm={6}>
                        <TextField
                            fullWidth
                            disabled={formMode == "edit"}
                            onChange={e => { setValidAfterDay(isNaN(Number(e.target.value)) ? "0" : e.target.value) }}
                            id="validAfterDay"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        Days
                                    </InputAdornment>
                                )
                            }}
                            label="Valid date will take effect after"
                            value={validAfterDay}
                        />
                    </Grid>
                    <Grid item xs={6} sm={6}>
                        <Controller
                            disabled={formMode == "edit"}
                            name="validFrom"
                            control={methods.control}
                            render={({ field, fieldState }) =>
                                <DatePicker
                                    readOnly={true}
                                    sx={{ width: "100%" }}
                                    {...field}
                                    label="Valid From"
                                />
                            } />
                    </Grid>

                    <Grid item xs={6} sm={6}>
                        <TextField
                            fullWidth
                            id="validTillDay"
                            label="Valid Duration"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        Days
                                    </InputAdornment>
                                )
                            }}
                            onChange={e => { setValidTillDay(isNaN(Number(e.target.value)) ? "0" : e.target.value) }}
                            value={validTillDay}
                        />
                    </Grid>
                    <Grid item xs={6} sm={6}>
                        <Controller
                            name="validTill"
                            control={methods.control}
                            render={({ field, fieldState }) =>
                                <DatePicker
                                    readOnly={true}
                                    sx={{ width: "100%" }}
                                    {...field}
                                    label="Valid Until"
                                />
                            } />
                    </Grid>

                    <Grid item xs={12} sm={12}>
                        {formMode == "edit" ?
                            <Button variant="contained" color="primary" type="submit" onClick={onUpdate}>
                                Update
                            </Button>
                            : <Button variant="contained" color="primary" type="submit" onClick={onAssign}>
                                Assign
                            </Button>
                        }
                        &nbsp;
                        &nbsp;
                        <Button
                            variant="outlined"
                            color="secondary"
                            onClick={() => { onCancel && onCancel() }}
                        >
                            Cancel
                        </Button>
                    </Grid>

                </Grid>
            </Box>
        </FormProvider> : <Box>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                    <Typography variant="h5">Status</Typography>
                </Grid>

                <Grid item xs={12} sm={12}>
                    <DataTable
                        rows={receipents}
                        columns={columns}
                        showActionDelete={false}
                        showActionExpand={false}
                        showActionEdit={false}
                        showActionView={false}
                        isSelectable={false}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>

                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => { onCancel && onCancel() }}
                    >
                        Close
                    </Button>
                </Grid>
            </Grid>
        </Box>)

}