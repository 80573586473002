import { TextFieldsTwoTone } from "@mui/icons-material";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Link, Box, Typography, Button, Table, TableHead, TableRow, TableCell, TableBody, Drawer, Grid, InputAdornment, TextField, Card, CardContent, FormControl, InputLabel, MenuItem, Select, Autocomplete } from "@mui/material"
import moment from "moment";
import { useEffect, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useAlert } from "src/components/CommonAlert";
import { CONSTANTS } from "src/constants";
import { Coupon, CouponMapping } from "src/intefaces/coupon.interface";
import axiosService from "src/services/axios";
import { DataType, RecordActions, RecordTypes } from "src/types/enum";
import { Member } from "src/types/member-interface";
import { AsssignCoupons } from "./assign-coupon";
import DataTable from "src/components/DataTable";
import DeleteConfirmationDialog from '../../components/ConfirmationDialog';
import React from "react";
export const MemberCoupons: React.FC<{ member: Member }> = ({ member }) => {
    const [selectedId, setSelectedId] = React.useState<number | undefined>(undefined);
    const { showAlert } = useAlert();
    const [isOpen, setIsOpen] = useState(false);
    const [formMode, setFormMode] = useState<"add" | "edit">("add");
    const [loading, setLoading] = useState(false);
    const [couponMaster, setCouponMaster] = useState<Coupon[]>([]);
    const [memberCoupons, setMemberCoupons] = useState<CouponMapping[]>([]);
    const [validAfterDay, setValidAfterDay] = useState<string>("0");
    const [validTillDay, setValidTillDay] = useState<string>("0");
    const [currentEdit, setCurrentEdit] = useState<CouponMapping | undefined>();
    const toggleDrawer = (open: boolean) => {
        setIsOpen(open);
    };
    const [openDeleteDlg, setOpenDeleteDlg] = React.useState<boolean>(false);
    const [coupons, setCoupons] = useState<Coupon[]>([]);
    const fetchCoupons = async () => {
        try {
            const response = await axiosService.get<CouponMapping[] | any>(`${CONSTANTS.ApiConstants.GENERIC.get[RecordTypes.couponMapping]}/${member?.id}`)
            if (response.status != 200) {
                setMemberCoupons([]);
            } else {
                const json = response.data;
                json && json.map((_j: CouponMapping) => {
                    _j['expired'] = moment(_j.validTill).isBefore(moment.utc()) ? 1 : 0
                })
                setMemberCoupons(json);
            }
        } catch (ex: any) {
            setMemberCoupons([]);
            // showAlert(ex?.response?.data?.message ?? 'Failed to get coupons', 'error');
            console.error("Exception Caught", ex)
        }
    };

    const handleAddCoupon = (id: number) => {
        toggleDrawer(true);
    };
    const getCoupons = async () => {
        try {
            const response = await axiosService.get<Coupon[] | any>(`${CONSTANTS.ApiConstants.GENERIC.get[RecordTypes.coupon]}`)
            if (response.status != 200) {
                showAlert(response?.data?.message ?? 'Failed to get coupons', 'error');
                setCouponMaster([])
            } else {
                const json = response.data;

                setCouponMaster(json);
            }
        } catch (ex: any) {
            setCouponMaster([])
            showAlert(ex?.response?.data?.message ?? 'Failed to get coupons', 'error');
            console.error("Exception Caught", ex)
        }
    }
    useEffect(() => {
        fetchCoupons();
        getCoupons();
    }, [])
    const methods = useForm<CouponMapping>({
        defaultValues: {
            id: 0,
            coupon_id: undefined,
            validFrom: moment().startOf('day'),
            validTill: moment().endOf('day'),
            member_id: undefined,
            redeemed: false
        }
    });
    const validAfterWatch = methods.watch("validFrom")
    const validUntilWatch = methods.watch("validTill")
    const couponWatch = methods.watch("coupon")
    const columns = [
        { id: "id", label: "ID" },
        { id: "coupon_name", label: "Name" },
        { id: "expired", label: "Expired", type: DataType.boolean },
        { id: "redeemed", label: "Redeemed", type: DataType.boolean },
        { id: "validFrom", label: "Valid From ", type: DataType.date },
        { id: "validTill", label: "Valid Till", type: DataType.date }
    ];
    useEffect(() => {
        if (member) {
            methods.setValue("member_id", member?.id);
        }
    }, [member])
    useEffect(() => {
        if (moment(validAfterWatch).diff(moment(), "days") != Number(validAfterDay))
            methods.setValue("validFrom", moment().add(validAfterDay ?? 0, "days").startOf('day'))
        if (moment(validAfterWatch).diff(moment(), "days") != Number(validTillDay))
            methods.setValue("validTill", moment().add(validTillDay ?? 0, "days").endOf('day'))
    }, [validTillDay, validAfterDay])
    useEffect(() => {
        if (couponWatch && formMode == "add") {
            setValidTillDay(couponWatch?.validUntil?.toString() || "0");
            setValidAfterDay(couponWatch?.validAfter?.toString() || "0");
        }
    }, [couponWatch])
    const handleEdit = (couponid: number, couponmapping: any) => {
        setFormMode("edit");
        setCurrentEdit(couponmapping);
        toggleDrawer(true);
    }
    const handleDelete = (couponid: number, couponmapping: any) => {
        setOpenDeleteDlg(true);
        setSelectedId(couponid);
    }
    const onDeleteConfirm = async () => {
        try {
            setOpenDeleteDlg(false)
            const response = await axiosService.delete<CouponMapping[]>(`${CONSTANTS.ApiConstants.GENERIC.delete[RecordTypes.couponMapping]}/${selectedId}`);
            if (response.status != 200) {
                showAlert('Failed to unassign  coupon', 'error');
            } else {
                setOpenDeleteDlg(false);
                setSelectedId(undefined);
                fetchCoupons();
                showAlert('Coupon Unassigned Successfully', 'success');
            }
        } catch (ex) {
            showAlert('Failed to unassign coupon', 'error');
            console.error("Exception Caught", ex)
        } finally {
            setSelectedId(undefined);

        }
    }
    return (<Box margin={1}>
        <Typography variant="h6" gutterBottom component="div">
            Coupons
        </Typography>
        <Button variant="outlined" color="primary" onClick={() => handleAddCoupon(1)}>
            Add New Coupon
        </Button>
        <DataTable
            rows={memberCoupons}
            columns={columns}
            onEdit={(handleEdit)}
            onDelete={handleDelete}
            showActionView={false}
            showActionExpand={false}
            rowsPerPageOptions={[5, 10, 25.50, 100]}
        />
        <Drawer
            anchor="right"
            open={isOpen}
            onClose={() => toggleDrawer(false)}
        >
            <Box
                sx={{ width: "50vw", p: 2 }}
                role="presentation"
            >   <AsssignCoupons members={[member]} onCancel={() => { toggleDrawer(false); fetchCoupons() }} formMode={formMode} couponMapping={currentEdit} />
            </Box>
        </Drawer>
        <DeleteConfirmationDialog
            open={openDeleteDlg}
            onClose={() => { setOpenDeleteDlg(false); setSelectedId(undefined) }}
            onConfirm={onDeleteConfirm}
            confirmLabel={"Delete"}
            cancelLabel={"Cancel"}
            message={"Are you sure you want to delete merchant"}
            title={"Confirm Delete"}
        />
    </Box>
    )
}