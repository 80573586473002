import React, { useEffect, useState } from 'react';
import { Card, CardContent, Avatar, Typography, List, ListItem, ListItemIcon, ListItemText, BottomNavigation, BottomNavigationAction, Stack, Box, Button, Dialog, DialogActions, DialogContent, TextField, Drawer } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LockIcon from '@mui/icons-material/Lock';
import HistoryIcon from '@mui/icons-material/History';
import DescriptionIcon from '@mui/icons-material/Description';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import NotificationsIcon from '@mui/icons-material/Notifications';
import HomeIcon from '@mui/icons-material/Home';
import PersonIcon from '@mui/icons-material/Person';
import { useMerchant } from 'src/context/MerchantContext';
import styled from '@emotion/styled';
import { useAuth } from 'src/context/AuthContext';
import { useNavigate } from 'react-router-dom';
import ImageUpload from 'src/components/ImageUpload';
import { Controller, useForm } from 'react-hook-form';
import { Member } from 'src/types/member-interface';
import { useAlert } from 'src/components/CommonAlert';
import { CONSTANTS } from 'src/constants';
import axiosService from 'src/services/axios';
import MemberSetUp from './member-set-up';
import ChangePasswordForm from './change-password';
import MerchantTerms from './merchant-terms';

const MemberProfile: React.FC = () => {
    const { user, logout, isAuthenticated } = useAuth();
    const navigate = useNavigate();
    const [editProfilePic, setEditProfilePic] = useState<boolean>();
    const [menuAction, setMenuAction] = useState<"editprofile" | "changepassword" | "transactions" | "terms" | undefined>(undefined);
    const ImageWrapper = styled('div')<{ src: string }>(({ src }) => ({
        backgroundImage: `url(${src})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        width: '100%',
    }));
    const { merchant, setBackButton } = useMerchant();
    const { control, handleSubmit, setValue } = useForm<{ Avatar: string }>()
    const { showAlert } = useAlert();
    const onSubmit = async (data: { Avatar: string }) => {
        try {
            const response = await axiosService.put<any>(`${CONSTANTS.ApiConstants.memberAc_updateAvatar}`, data)
            if (response.status != 200) {
                showAlert(response?.data?.message || 'Failed to update', 'error');
            } else {
                const json = response.data;
                showAlert('Avatar updated', 'success');
            }
        } catch (ex: any) {
            showAlert(ex?.response?.data?.message || 'Failed toupdate', 'error');
            console.error("Exception Caught", ex)
        }
    }
    useEffect(() => {
        setValue("Avatar", user?.account.logo ?? "")
    }, [user])
    useEffect(() => {
        setBackButton && setBackButton(false);
    }, [])
    return (
        <Stack>
            <Stack style={{ backgroundColor: merchant?.HeaderColorCode ?? "unset" }}>
                <Card elevation={0} style={{ margin: "16px", background: "unset" }}>
                    <Stack style={{
                        backgroundImage: `url(${merchant?.MemberCard ?? ""})`,
                        color: "white",
                        backgroundSize: '100% 100%',
                        backgroundPosition: 'center',
                        borderRadius: "10px",
                        width: '100%',
                        padding: "24px"
                    }}>
                        <div>
                            <Avatar
                                alt="User"
                                onClick={() => setEditProfilePic(true)}
                                src={user?.account.logo}
                                style={{
                                    width: "80px",
                                    height: "80px",
                                    marginTop: "30px",
                                }}
                            />
                            <h3>
                                {user?.account?.name}
                            </h3>
                            <Typography variant="body2" style={{
                            }} >
                                {user?.account?.mobile}
                            </Typography>
                        </div>
                    </Stack>
                </Card>
            </Stack>
            {/* Menu Items */}
            <List >
                <ListItem button onClick={() => { setBackButton && setBackButton(true); navigate(`/${merchant?.id}/membersetup/editprofile`) }} sx={{ pl: 3, pr: 3 }}>
                    <Stack direction={"row"} style={{ justifyContent: "space-between", width: "100%" }}>
                        <h3 color="primary" style={{ fontWeight: "normal" }}>Edit Profile</h3>
                        <AccountCircleIcon style={{ color: merchant?.HeaderColorCode ?? 'primary' }} />
                    </Stack>
                </ListItem>

                <ListItem button onClick={() => { navigate(`/${merchant?.id}/changepassword`) }} sx={{ pl: 3, pr: 3 }}>
                    <Stack direction={"row"} style={{ justifyContent: "space-between", width: "100%" }}>
                        <h3 color="primary" style={{ fontWeight: "normal" }}>Password</h3>
                        <LockIcon style={{ color: merchant?.HeaderColorCode ?? 'primary' }} />
                    </Stack>
                </ListItem>

                <ListItem button sx={{ pl: 3, pr: 3 }}>
                    <Stack direction={"row"} style={{ justifyContent: "space-between", width: "100%" }}>
                        <h3 color="primary" style={{ fontWeight: "normal" }}>Transaction History</h3>
                        <HistoryIcon style={{ color: merchant?.HeaderColorCode ?? 'primary' }} />
                    </Stack>
                </ListItem>

                <ListItem button onClick={() => navigate(`/${merchant?.id}/merchantterms`)} sx={{ pl: 3, pr: 3 }}>
                    <Stack direction={"row"} style={{ justifyContent: "space-between", width: "100%" }}>
                        <h3 color="primary" style={{ fontWeight: "normal" }}>Terms & Conditions</h3>
                        <DescriptionIcon style={{ color: merchant?.HeaderColorCode ?? 'primary' }} />
                    </Stack>
                </ListItem>

                <ListItem button onClick={() => { logout(); navigate(`/${merchant?.id}`) }} sx={{ pl: 3, pr: 3 }}>
                    <Stack direction={"row"} style={{ justifyContent: "space-between", width: "100%" }}>
                        <h3 color="primary" style={{ fontWeight: "normal" }}>Log Out</h3>
                        <ExitToAppIcon style={{ color: merchant?.HeaderColorCode ?? 'primary' }} />
                    </Stack>
                </ListItem>
            </List>
            <Dialog fullWidth sx={{ textAlign: "center" }} open={!!editProfilePic} onClose={() => setEditProfilePic(undefined)}>

                <DialogContent>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Controller
                            name="Avatar"
                            control={control}
                            render={({ field, fieldState }) =>
                                <ImageUpload onImageChange={(url: string | null) => { field.onChange(url) }} imageUrl={field.value} name={"Avatar"} label="" width="calc(100vw - 100px)" height="calc(100vw - 100px)" />
                            }
                        />
                        <DialogActions sx={{ pl: 0, pr: 0 }}>
                            <Button fullWidth type="submit" disableElevation={true} color="primary" variant="contained" size="large" sx={{ fontSize: "1.17em" }}>
                                Ok
                            </Button>
                            <Button fullWidth onClick={() => setEditProfilePic(false)} color="primary" variant="outlined" size="large" sx={{ fontSize: "1.17em" }}>
                                Cancel
                            </Button>
                        </DialogActions>
                    </form>
                </DialogContent>
            </Dialog>
        </Stack >
    );
};

export default MemberProfile;
