import React, { useCallback, useRef } from "react";
import ReactQuill from "react-quill";
import { EmojiPickerButton } from "./EmojiPickerButton";
const ClearContentButton = ({ handleButtonClick }: { handleButtonClick: any }) => {
  return <button onClick={handleButtonClick} type="button" style={{ cursor: 'pointer' }}  >
    Clear
  </button>
}
/*
 * Custom toolbar component including insertStar button and dropdowns
 */
const CustomToolbar = ({ onEmojiSelect, name, handleClearClick, toolbarMode }: { onEmojiSelect: any, name: string, handleClearClick: any, toolbarMode: "basic" | undefined }) => {
  if (toolbarMode == "basic") {
    return <div id={name}>
      <EmojiPickerButton onEmojiSelect={onEmojiSelect} />
      <button className="ql-bold"></button>
      <button className="ql-italic"></button>
      <button className="ql-strike"></button>
      <button className="ql-link"></button>
      <button className="ql-clear"></button>
      <ClearContentButton handleButtonClick={handleClearClick} ></ClearContentButton>
    </div>
  } else {
    return <div id={name}>
      <EmojiPickerButton onEmojiSelect={onEmojiSelect} />
      <select className="ql-font"></select>
      {/* <select className="ql-size"></select> */}
      <button className="ql-bold"></button>
      <button className="ql-italic"></button>
      <button className="ql-underline"></button>
      <button className="ql-strike"></button>
      <select className="ql-color"></select>
      <select className="ql-background"></select>
      <button className="ql-script" value="sub"></button>
      <button className="ql-script" value="super"></button>
      <select className="ql-header">
        <option value="1"></option>
        <option value="2"></option>
        <option value="3"></option>
        <option value="4"></option>
        <option value="5"></option>
        <option value="6"></option>
        <option selected></option>
      </select>
      <button className="ql-blockquote"></button>
      <button className="ql-code-block"></button>
      <button className="ql-list" value="ordered"></button>
      <button className="ql-list" value="bullet"></button>
      <button className="ql-indent" value="-1"></button>
      <button className="ql-indent" value="+1"></button>
      <button className="ql-direction" value="rtl"></button>
      <select className="ql-align"></select>
      <button className="ql-link"></button>
      <button className="ql-image"></button>
      <button className="ql-video"></button>
      <button className="ql-clean"></button>
      <button className="ql-clear"></button>
      <ClearContentButton handleButtonClick={handleClearClick} ></ClearContentButton>
    </div>
  }
};

/*
 * Editor component with custom toolbar and content containers
 */
export const HtmlEditor: React.FC<any> = ({ onChange, value, placeholder, name, toolbarMode }: { onChange: (e: any) => any, value: string, placeholder: string, name: string, toolbarMode?: "basic" | undefined }) => {
  const quillRef = useRef<ReactQuill>(null);
  const modules = {

    toolbar: {
      container: `#${name}`,
      handlers: {
        //insertStar: insertStar,
      }
    },
    clipboard: {
      matchVisual: false
    }
  };
  const formats = [
    'header',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
    'color',
  ];

  // const propTypes = {
  //   placeholder: React.PropTypes.string,
  // };
  const handleChange = useCallback((content: string) => {
    // Ensure this function does not cause unnecessary re-renders
    // Using field.onChange safely
    onChange(content);
  }, []);
  // const handleChange = (html: any) => {
  //   console.log("called");
  //   // onChange(html)
  // }
  const handleEmojiSelect = (emoji: any) => {
    if (quillRef) {
      const cursorPosition = quillRef.current?.getEditor().getSelection()?.index || 0;
      quillRef.current?.getEditor().insertText(cursorPosition, emoji.native);
      quillRef.current?.getEditor().setSelection(cursorPosition + emoji.native.length);
    }
  };
  const handleClearClick = () => {
    quillRef.current?.getEditor().setText('');
  }
  return (
    <div className="text-editor" >
      <CustomToolbar toolbarMode={toolbarMode} onEmojiSelect={handleEmojiSelect} name={name} handleClearClick={handleClearClick} />
      <ReactQuill
        ref={quillRef}
        onChange={(content) => handleChange(content)}
        modules={modules}
        value={value || ''}
        placeholder={placeholder || "Description"}
        style={{ height: '250px', marginBottom: '10px' }}
      />
    </div>
  );

}