
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import { Button, Card, CardContent, FormControl, Grid, InputLabel, MenuItem, Select, Stack, TextField, Typography } from '@mui/material';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { CONSTANTS } from '../../constants';
import axiosService from '../../services/axios';
import { Merchant } from '../../types/merchant-interface';
import { Member } from '../../types/member-interface';
import { useAlert } from '../../components/CommonAlert';
import { useParams } from 'react-router-dom';
import { RecordActions, RecordTypes, Roles } from '../../types/enum';
import ImageUpload from '../../components/ImageUpload';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import moment from 'moment';
import { useState } from 'react';
import { useAuth } from '../../context/AuthContext';

const genderOptions = [
    { value: 'Male', label: 'Male' },
    { value: 'Female', label: 'Female' },
    { value: 'Other', label: 'Other' }
]
export function MForm({ publicForm = false }: { publicForm: boolean }): React.JSX.Element {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [value, setValue] = React.useState("General");
    const [outlets, setOutlets] = React.useState<Merchant[]>([])
    const { action, id, token } = useParams();
    const { user, getToken } = useAuth();
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };
    const { showAlert } = useAlert();
    const methods = useForm<any>({
        defaultValues: {
            "Mobile": "",
            "MemberName": "",
            "DOB": null,
            "Email": "",
            "Gender": "",
            "MemberName2": "",
            "Address1": "",
            "Address2": "",
            "Address3": "",
            "Postcode": "",
            "MemberLocation": "",
            "MemberState": "",
            "Country": "",
            "Occupation": "",
            "PreferLanguage": "",
            "MGM": "",
            "RelationUpline": "",
            "Upline": "",
            "Avatar": "",
            "RegistredTime": null,
            "UplinePhone": "",
            "MemberType": "",
            "Tag": "",
            "Race": "",
            "IdNumber": "",
            "outletid": ""
        }
    });
    const createNewMember = async (data: any) => {
        try {

            const response = await (await axiosService.post<Member | any>(`${publicForm ? CONSTANTS.ApiConstants.postPublicMember + "/" + token : CONSTANTS.ApiConstants.postMembers}`, data))
            if (response.status != 200) {
                setLoading(false);
                showAlert(response.data.message || 'Failed to Save Merchant', 'error');
            } else {
                const json = response.data;
                setLoading(false);
                showAlert('Merchant Created Successfully', 'success');
                if (publicForm) {
                    navigate(`/mregister/${token}?complete=true`)
                } else {
                    navigate(`/members/edit/${response.data.id}`)
                }
            }
        } catch (ex: any) {
            setLoading(false);
            showAlert(ex.response.data.message || 'Failed to Save Merchant', 'error');
            console.error("Exception Caught", ex)
        }
    }
    const editMember = async (data: any) => {
        try {
            const response = await axiosService.put<Member>(`${CONSTANTS.ApiConstants.putMember}/${id}`, data)
            if (response.status != 200) {
                setLoading(false);
                showAlert('Failed to Update Member', 'error');
            } else {
                const json = response.data;
                setLoading(false);
                showAlert('Member Updated Successfully', 'success');
            }
        } catch (ex) {
            showAlert('Failed to Update Member', 'error');
            setLoading(false);
            console.error("Exception Caught", ex)
        }
    }

    const getMemberDetails = async () => {
        try {
            const response = await axiosService.get<Member>(`${CONSTANTS.ApiConstants.getMemberbyId}/${id}`)
            if (response.status != 200) {
                showAlert('Failed to Retrive Member Details', 'error');
            } else {
                const json: Member = response.data;
                if (json.DOB) {
                    json.DOB = moment(json.DOB ?? null).utc(true).local();;
                    json.RegistredTime = moment(json.RegistredTime ?? null).utc(true).local();
                }
                methods.reset(json);
            }
        } catch (ex) {
            showAlert('Failed to Retrive Member Details', 'error');
            console.error("Exception Caught", ex)
        }
    }
    const onSubmit = methods.handleSubmit(async (data: any) => {
        try {
            if (loading) {
                return
            }
            setLoading(true);
            if (publicForm || action == RecordActions.add) {
                createNewMember(data);
            } else if (action == RecordActions.edit) {
                editMember(data);
            }
        }
        catch (ex) {
            setLoading(false);
        } finally {

        }
    });
    const getOutlets = async () => {
        try {
            const response = await axiosService.get<Merchant[]>(`${CONSTANTS.ApiConstants.GENERIC.get[RecordTypes.outlet]}`)
            if (response.status != 200) {
                setOutlets([])
            } else {
                const json = response.data;
                setOutlets([{ MerchantName: "--Select Outlet--" } as Merchant, ...json]);
            }
        } catch (ex) {
            setOutlets([])
            console.error("Exception Caught", ex)
        }

    }
    React.useEffect(() => {
        !publicForm && getOutlets();
        if (action == RecordActions.edit || action == RecordActions.view) {
            getMemberDetails();
        }
    }, [])
    return (
        <FormProvider {...methods} >
            <Box component="form" onSubmit={onSubmit} sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                <Card>
                    <CardContent>
                        <Grid container spacing={2}>
                            {!publicForm && <Grid item xs={12} sm={4}  >
                                <Controller
                                    name="outletid"

                                    control={methods.control}
                                    render={({ field, fieldState }) =>
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">Outlet</InputLabel>
                                            <Select {...field} label="Outlet" name="outlet" variant="outlined">
                                                {outlets.map((option) => (
                                                    <MenuItem key={option.id} value={option.id}>
                                                        {option.MerchantName}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    } />
                            </Grid>}
                            <Grid item xs={12} sm={4}>
                                <Controller
                                    name="Mobile"
                                    rules={{ required: 'Mobile is required' }}
                                    control={methods.control}
                                    render={({ field, fieldState }) =>
                                        <TextField
                                            InputProps={{
                                                readOnly: action == RecordActions.view,
                                            }}
                                            helperText={typeof methods.formState.errors?.Mobile?.message === 'string' ? methods.formState.errors?.Mobile?.message : ""}
                                            FormHelperTextProps={{ style: { color: 'red' } }}
                                            {...field}
                                            fullWidth
                                            id="Mobile"
                                            label="Mobile"
                                        />} />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Controller
                                    name="MemberName"

                                    control={methods.control}
                                    render={({ field, fieldState }) =>
                                        <TextField
                                            InputProps={{
                                                readOnly: action == RecordActions.view,
                                            }}
                                            {...field}
                                            fullWidth
                                            id="MemberName"
                                            label="Name"
                                        />} />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Controller
                                    name="MemberName2"
                                    control={methods.control}
                                    render={({ field, fieldState }) =>
                                        <TextField
                                            InputProps={{
                                                readOnly: action == RecordActions.view,
                                            }}
                                            {...field}
                                            fullWidth
                                            label="Name 2"
                                            id="MemberName2"
                                        />} />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Controller
                                    name="DOB"
                                    control={methods.control}
                                    render={({ field, fieldState }) =>
                                        <DatePicker
                                            sx={{ width: "100%" }}
                                            {...field}
                                            label="Select DOB"
                                        />
                                    } />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Controller
                                    name="Email"
                                    control={methods.control}
                                    render={({ field, fieldState }) =>
                                        <TextField
                                            InputProps={{
                                                readOnly: action == RecordActions.view,
                                            }}
                                            {...field}
                                            fullWidth
                                            label="Email"
                                            id="Email"
                                        />} />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Controller
                                    name="Gender"
                                    control={methods.control}
                                    render={({ field }) =>
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">Gender</InputLabel>
                                            <Select {...field} defaultValue={null} label="Gender" name="Gender" variant="outlined">
                                                {genderOptions.map((option) => (
                                                    <MenuItem key={option.value} value={option.value}>
                                                        {option.label}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    } />
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <Controller
                                    name="Address1"
                                    control={methods.control}
                                    render={({ field, fieldState }) =>
                                        <TextField
                                            InputProps={{
                                                readOnly: action == RecordActions.view,
                                            }}
                                            {...field}
                                            fullWidth
                                            label="Address 1"
                                            id="Address1"
                                        />} />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Controller
                                    name="Address2"
                                    control={methods.control}
                                    render={({ field, fieldState }) =>
                                        <TextField
                                            InputProps={{
                                                readOnly: action == RecordActions.view,
                                            }}
                                            {...field}

                                            fullWidth
                                            label="Address 2"
                                            id="Address2"
                                        />} />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Controller
                                    name="Address3"
                                    control={methods.control}
                                    render={({ field, fieldState }) =>
                                        <TextField
                                            InputProps={{
                                                readOnly: action == RecordActions.view,
                                            }}
                                            {...field}
                                            fullWidth
                                            label="Address 3"
                                            id="Address3"
                                        />} />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Controller
                                    name="Postcode"
                                    control={methods.control}
                                    render={({ field, fieldState }) =>
                                        <TextField
                                            InputProps={{
                                                readOnly: action == RecordActions.view,
                                            }}
                                            {...field}
                                            fullWidth
                                            label="Postcode"
                                            id="Postcode"
                                        />} />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Controller
                                    name="IdNumber"
                                    control={methods.control}
                                    render={({ field, fieldState }) =>
                                        <TextField
                                            InputProps={{
                                                readOnly: action == RecordActions.view,
                                            }}
                                            {...field}
                                            fullWidth
                                            label="IdNumber"
                                            id="IdNumber"
                                        />} />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Controller
                                    name="MemberLocation"
                                    control={methods.control}
                                    render={({ field, fieldState }) =>
                                        <TextField
                                            InputProps={{
                                                readOnly: action == RecordActions.view,
                                            }}
                                            {...field}
                                            fullWidth
                                            label="Location"
                                            id="MemberLocation"
                                        />} />
                            </Grid> <Grid item xs={12} sm={4}>
                                <Controller
                                    name="MemberState"
                                    control={methods.control}
                                    render={({ field, fieldState }) =>
                                        <TextField
                                            InputProps={{
                                                readOnly: action == RecordActions.view,
                                            }}
                                            {...field}
                                            fullWidth
                                            label="State"
                                            id="MemberState"
                                        />} />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Controller
                                    name="Country"
                                    control={methods.control}
                                    render={({ field, fieldState }) =>
                                        <TextField
                                            InputProps={{
                                                readOnly: action == RecordActions.view,
                                            }}
                                            {...field}
                                            fullWidth
                                            label="Country"
                                            id="Country"
                                        />} />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Controller
                                    name="Occupation"
                                    control={methods.control}
                                    render={({ field, fieldState }) =>
                                        <TextField
                                            InputProps={{
                                                readOnly: action == RecordActions.view,
                                            }}
                                            {...field}
                                            fullWidth
                                            label="Occupation"
                                            id="Occupation"
                                        />} />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Controller
                                    name="PreferLanguage"
                                    control={methods.control}
                                    render={({ field, fieldState }) =>
                                        <TextField
                                            InputProps={{
                                                readOnly: action == RecordActions.view,
                                            }}
                                            {...field}
                                            fullWidth
                                            label="PreferLanguage"
                                            id="PreferLanguage"
                                        />} />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Controller
                                    name="MGM"
                                    control={methods.control}
                                    render={({ field, fieldState }) =>
                                        <TextField
                                            InputProps={{
                                                readOnly: action == RecordActions.view,
                                            }}
                                            {...field}
                                            fullWidth
                                            label="MGM"
                                            id="MGM"
                                        />} />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Controller
                                    name="RelationUpline"
                                    control={methods.control}
                                    render={({ field, fieldState }) =>
                                        <TextField
                                            InputProps={{
                                                readOnly: action == RecordActions.view,
                                            }}
                                            {...field}
                                            fullWidth
                                            label="RelationUpline"
                                            id="RelationUpline"
                                        />} />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Controller
                                    name="Upline"
                                    control={methods.control}
                                    render={({ field, fieldState }) =>
                                        <TextField
                                            InputProps={{
                                                readOnly: action == RecordActions.view,
                                            }}
                                            {...field}
                                            fullWidth
                                            label="Upline"
                                            id="Upline"
                                        />} />
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <Controller
                                    name="UplinePhone"
                                    control={methods.control}
                                    render={({ field, fieldState }) =>
                                        <TextField
                                            InputProps={{
                                                readOnly: action == RecordActions.view,
                                            }}
                                            {...field}
                                            fullWidth
                                            label="Upline Phone"
                                            id="UplinePhone"
                                        />} />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Controller
                                    name="MemberType"
                                    control={methods.control}
                                    render={({ field, fieldState }) =>
                                        <TextField
                                            InputProps={{
                                                readOnly: action == RecordActions.view,
                                            }}
                                            {...field}
                                            fullWidth
                                            label="Member Type"
                                            id="MemberType"
                                        />} />
                            </Grid><Grid item xs={12} sm={4}>
                                <Controller
                                    name="Tag"
                                    control={methods.control}
                                    render={({ field, fieldState }) =>
                                        <TextField
                                            InputProps={{
                                                readOnly: action == RecordActions.view,
                                            }}
                                            {...field}
                                            fullWidth
                                            label="Tag"
                                            id="Tag"
                                        />} />
                            </Grid><Grid item xs={12} sm={4}>
                                <Controller
                                    name="Race"
                                    control={methods.control}
                                    render={({ field, fieldState }) =>
                                        <TextField
                                            InputProps={{
                                                readOnly: action == RecordActions.view,
                                            }}
                                            {...field}
                                            fullWidth
                                            label="Race"
                                            id="Race"
                                        />} />
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <Controller
                                    name="Avatar"
                                    control={methods.control}
                                    render={({ field, fieldState }) =>
                                        <ImageUpload onImageChange={(url: string | null) => { field.onChange(url) }} imageUrl={field.value} mode={action} name={"Avatar"} label="Image" />
                                    }
                                />
                            </Grid>

                        </Grid>
                    </CardContent >
                </Card >
                <br />
                <br />
                <br />
                <br />
                <br />
                {action != RecordActions.view && <Box
                    sx={{
                        position: 'fixed',
                        bottom: 0,
                        left: 0,
                        zIndex: 9,
                        width: '100%',
                        backgroundColor: '#f5f5f5',
                        padding: 2,
                        display: 'flex',
                        justifyContent: 'flex-end', // Align the button to the right
                        boxShadow: '0px -1px 5px rgba(0,0,0,0.1)', // Optional shadow
                    }}
                >
                    <Button disabled={loading} variant="contained" color="primary" type="submit">
                        Save
                    </Button>
                </Box>
                }
            </Box>
        </FormProvider >
    );
}

