import React, { useState } from 'react';
import { Box, TextField, Button, Typography, Avatar, Select, MenuItem, InputLabel, FormControl, Stack, InputAdornment, Divider, Link, FormControlLabel, Checkbox } from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import SmsIcon from '@mui/icons-material/Sms';
import { useMerchant } from 'src/context/MerchantContext';
import { FlagIcon, FlagIconCode } from 'react-flag-kit';
import { Divide } from '@phosphor-icons/react';
import { useAuth } from 'src/context/AuthContext';
import { useLocation, useNavigate } from 'react-router-dom';
import axiosService from 'src/services/axios';
import { CONSTANTS } from 'src/constants';
import { Member } from 'src/types/member-interface';
import { useAlert } from 'src/components/CommonAlert';
import { OTPMode } from 'src/types/enum';
const MemberLogin: React.FC = () => {
    const { merchant } = useMerchant();
    const countries = [
        { label: 'Malaysia', value: '+60', flag: "MY" },
        { label: 'United States', value: '+1', flag: "US" },
        { label: 'India', value: '+91', flag: "IN" },
    ]
    const [lastOtpMode, setLastOtpMode] = useState('');
    const { memberLogin } = useAuth();
    const [selectedCountry, setSelectedCountry] = useState<any>({ label: 'Malaysia', value: '+60', flag: "MY" });
    const [mobile, setMobile] = useState('');
    const [password, setPassword] = useState('');
    const [rememberMe, setRememberMe] = useState(false);
    const [otp, setOtp] = useState<string[]>(["0", "0", "0", "0"]);
    const [codesent, setCodesent] = useState<boolean>(false);
    const [message, setMessage] = useState('');
    const navigate = useNavigate();
    const location = useLocation();
    const { showAlert } = useAlert();
    const [loading, setLoading] = useState<boolean>(true);

    const from = location.state?.from || '/';
    const handleSubmit = (event: any) => {
        const intPhone = (selectedCountry.value + mobile).replace(/[+-]/g, '');
        memberLogin(intPhone, password, merchant?.id || 0, rememberMe).then((isLoggedIn: string) => {
            if (isLoggedIn == "true") {
                setMessage('');
                if (from && from != "/")
                    navigate(from, { replace: true });
                else
                    navigate(`/${merchant?.id}`);
            }
            else {
                showAlert(isLoggedIn, 'error')
            }

        }).catch((ex: any) => {
            showAlert("Failed to Login", 'error')
            setMessage(ex.message)
        });
        event.preventDefault();
    };


    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'space-evenly',
                minHeight: '85vh',
                padding: 3,
            }}
        >
            <Avatar
                variant='rounded'
                sx={{
                    width: 120,
                    height: 120,
                    marginBottom: 3,
                }}
                src={merchant?.MerchantLogo}
                alt="Logo"
            />
            <Stack sx={{ width: "100%", alignItems: "center" }}>
                <Stack sx={{ width: "100%" }}>

                    <TextField
                        variant="outlined"
                        fullWidth
                        autoComplete="off"
                        onChange={(e) => { setMobile(e.target.value) }}
                        placeholder="Phone Number"
                        sx={{
                            '& .MuiInputBase-input': {
                                fontSize: '1.5em', // Set the font size here
                            },
                            '& .MuiInputLabel-root': {
                                fontSize: '1.5em', // Set the label font size if needed
                            },
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <FormControl variant="standard">
                                        <Select
                                            value={0}
                                            onChange={(e) => { setSelectedCountry(countries.find(c => c.value == e.target.value)); }}
                                            displayEmpty
                                            sx={{
                                                "minWidth": "80px",
                                                "minHeight": "0px",
                                                "& > .MuiSelect-select": {
                                                    "minHeight": "0px !important"
                                                }
                                            }}
                                            renderValue={(value) => (
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    {selectedCountry ? <><FlagIcon code={selectedCountry?.flag as FlagIconCode} />
                                                        <h2 style={{ fontWeight: "normal", fontSize: "24px" }}>{selectedCountry?.value}</h2></> : <></>}
                                                </div>
                                            )}
                                        >
                                            {countries.map((country) => (
                                                <MenuItem key={country.value} value={country.value}>
                                                    <FlagIcon code={country.flag as FlagIconCode} />
                                                    <h2 style={{ fontWeight: "normal", fontSize: "24px" }}> ({country.value})</h2>
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        sx={{
                            '& .MuiInputBase-input': {
                                fontSize: '1.5em', // Set the font size here
                            },
                            '& .MuiInputLabel-root': {
                                fontSize: '1.5em', // Set the label font size if needed
                            },
                        }} fullWidth
                        name="password"
                        placeholder="Password*"
                        type="password"
                        id="password"
                        autoComplete="off"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <FormControlLabel
                        sx={{
                            fontSize: "1.1em",
                            '& .MuiTypography-root': {
                                fontSize: "1.1em"
                            }
                        }}
                        control={
                            <Checkbox
                                checked={rememberMe}
                                onChange={(e) => setRememberMe(e.target.checked)}
                            />
                        }
                        label="Remember Me"
                    />
                </Stack>
                <Stack direction="row" sx={{ alignItems: "center" }}>
                    <h3 style={{ fontWeight: "normal" }}>
                        <Link color="primary" onClick={() => { navigate(`/${merchant?.id}/forgotpassword`) }} sx={{
                            textDecoration: "none", '&.MuiTypography-root': {
                                fontSize: "1em"
                            }
                        }}>Forgot Password ?</Link>
                    </h3>
                </Stack>
            </Stack>
            <Stack sx={{ width: "100%", alignItems: "center" }}>
                <Stack sx={{ gap: 2, width: "100%", mt: 2 }}>
                    <Button
                        type="submit"
                        fullWidth
                        variant="outlined"
                        color="primary"
                        onClick={handleSubmit}
                        size='large'
                    >
                        <h3>Log In</h3>
                    </Button>

                </Stack>

            </Stack>
            <h3 style={{ textAlign: "center", fontWeight: "normal", fontSize: "1.17em" }}>
                Don't have account ?
                <br />
                <Link color="primary" sx={{
                    textDecoration: "none", '&.MuiTypography-root': {
                        fontSize: "1em"
                    }
                }} onClick={() => { navigate(`/${merchant?.id}/membersignup`) }}>Register</Link>
            </h3>
        </Box >

    );
};

export default MemberLogin;
