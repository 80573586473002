// src/components/Home.tsx
import { AppBar, Toolbar, Stack, IconButton, Typography, Avatar, Box, Divider, MenuItem, Button } from '@mui/material';
import React from 'react';
import { List as ListIcon } from '@phosphor-icons/react/dist/ssr/List';
import { useNavigate } from 'react-router-dom';
import { MForm } from './form';
    
export const MemberForm: React.FC = () => {
    const navigate = useNavigate();
    return (
        <Stack spacing={3}>
            <Stack direction="row" spacing={3}>
                <Stack spacing={1} sx={{ flex: '1 1 auto' }}>
                    <Typography variant="h4">Member Details</Typography>
                </Stack>
                <div>
                    <Button
                        startIcon={<ListIcon fontSize="var(--icon-fontSize-md)" />}
                        variant="text"
                        onClick={() => navigate('/members')}
                    >
                        View All
                    </Button>
                </div>
            </Stack>
            <MForm publicForm={false} />
        </Stack>
    );
};
