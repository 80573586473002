import React, { useState } from 'react';
import { Box, TextField, Button, Typography, Avatar, Select, MenuItem, InputLabel, FormControl, Stack, InputAdornment, Divider, Link } from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import SmsIcon from '@mui/icons-material/Sms';
import { useMerchant } from 'src/context/MerchantContext';
import { FlagIcon, FlagIconCode } from 'react-flag-kit';
import { Divide } from '@phosphor-icons/react';
import { useAuth } from 'src/context/AuthContext';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import axiosService from 'src/services/axios';
import { CONSTANTS } from 'src/constants';
import { Member } from 'src/types/member-interface';
import { useAlert } from 'src/components/CommonAlert';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import moment from 'moment';
import ImageUpload from 'src/components/ImageUpload';
import { RecordActions } from 'src/types/enum';
const MemberSetUp: React.FC<{ formMode?: "editprofile" | undefined, onComplete?: () => any }> = ({ formMode, onComplete }) => {
    const genderOptions = [
        { value: 'Male', label: 'Male' },
        { value: 'Female', label: 'Female' },
        { value: 'Other', label: 'Other' }
    ]
    const { merchant, setBackButton } = useMerchant();
    const countries = [
        { label: 'Malaysia', value: '+60', flag: "MY" },
        { label: 'United States', value: '+1', flag: "US" },
        { label: 'India', value: '+91', flag: "IN" },
    ]
    const { user, logout } = useAuth();
    const { mode } = useParams();
    const methods = useForm<any>({
        defaultValues: {
            "Mobile": "",
            "MemberName": "",
            "DOB": null,
            "Email": "",
            "Gender": "",
            "MemberName2": "",
            "Address1": "",
            "Address2": "",
            "Address3": "",
            "Postcode": "",
            "MemberLocation": "",
            "MemberState": "",
            "Country": "",
            "Occupation": "",
            "PreferLanguage": "",
            "MGM": "",
            "RelationUpline": "",
            "Upline": "",
            "Avatar": "",
            "RegistredTime": null,
            "UplinePhone": "",
            "MemberType": "",
            "Tag": "",
            "Race": "",
            "IdNumber": "",
            "outletid": "",
            "Password": ""
        }
    });
    const getMemberDetails = async () => {
        try {
            const response = await axiosService.get<Member>(`${CONSTANTS.ApiConstants.getMemberById}/${user?.account?.id}`)
            if (response.status != 200) {
                showAlert('Failed to Retrive Member Details', 'error');
            } else {
                const json: Member = response.data;
                if (json.DOB) {
                    json.DOB = moment(json.DOB ?? null).utc(true).local();;
                }
                methods.reset(json);
            }
        } catch (ex) {
            showAlert('Failed to Retrive Member Details', 'error');
            console.error("Exception Caught", ex)
        }
    }
    const [formSubmitted, setFormSubmitted] = useState<boolean>(false);
    const navigate = useNavigate();
    const location = useLocation();
    const { showAlert } = useAlert();
    const [loading, setLoading] = useState<boolean>(true);
    const onSubmit = methods.handleSubmit(async (data: any) => {
        try {
            const response = await axiosService.put<Member>(`${CONSTANTS.ApiConstants.updateMemberAcc}`, data)
            if (response.status != 200) {
                setLoading(false);
                showAlert('Failed to Update Member', 'error');
            } else {
                const json = response.data;
                if (formMode == "editprofile" || mode == "editprofile") {
                    showAlert('Profile updated', 'success');
                    onComplete && onComplete();
                    return;
                }
                setLoading(false);
                setFormSubmitted(true);
            }
        } catch (ex) {
            showAlert('Failed to register', 'error');
            setLoading(false);
            console.error("Exception Caught", ex)
        }
    });
    React.useEffect(() => {
        user && getMemberDetails();
        setBackButton && setBackButton(true);
    }, [user])
    return (
        <FormProvider {...methods} >
            <Box
                onSubmit={onSubmit}
                component={"form"}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',

                }}
            >
                <Stack p={3} style={{
                    justifyContent: "space-between",
                    minHeight: '80vh',
                    alignItems: "center"
                }}>
                    {formSubmitted ? <Avatar
                        variant='rounded'
                        sx={{
                            width: 120,
                            height: 120,
                            marginBottom: 3,
                        }}
                        src={merchant?.MerchantLogo}
                        alt="Logo"
                    /> : null}
                    <Stack sx={{ width: "100%", alignItems: !formSubmitted ? "start" : "center" }} >
                        {!formSubmitted ?
                            <Stack sx={{ width: "100%" }} spacing={2}>

                                <Controller
                                    name="MemberName"
                                    key={"MemberNamectrl"}
                                    control={methods.control}
                                    render={({ field, fieldState }) =>
                                        <div>
                                            <InputLabel>Name*</InputLabel>
                                            <TextField
                                                {...field}
                                                key={"MemberNametxt"}
                                                fullWidth
                                                autoComplete='off'
                                                id="MemberName"
                                            />
                                        </div>
                                    } />

                                <Controller
                                    name="DOB"
                                    control={methods.control}
                                    render={({ field, fieldState }) =>
                                        <div>
                                            <InputLabel>DOB*</InputLabel>
                                            <DatePicker
                                                sx={{ width: "100%" }}
                                                {...field}
                                            />
                                        </div>
                                    } />
                                <Controller
                                    name="Gender"
                                    control={methods.control}
                                    render={({ field }) =>
                                        <div><InputLabel >Gender*</InputLabel>
                                            <FormControl fullWidth>
                                                <Select {...field} defaultValue={null} name="Gender" variant="outlined">
                                                    {genderOptions.map((option) => (
                                                        <MenuItem key={option.value} value={option.value}>
                                                            {option.label}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </div>
                                    } />
                                <Controller
                                    name="Mobile"
                                    disabled={formMode == "editprofile" || mode == "editprofile"}
                                    control={methods.control}
                                    render={({ field }) =>
                                        <div><InputLabel >Mobile*</InputLabel>
                                            <TextField
                                                {...field}
                                                fullWidth
                                                id="Mobile"
                                            />
                                        </div>
                                    } />
                                <Controller
                                    name="Email"
                                    control={methods.control}
                                    render={({ field, fieldState }) =>
                                        <div>
                                            <InputLabel>Email*</InputLabel>
                                            <TextField
                                                {...field}
                                                fullWidth
                                                id="Email"
                                            /></div>
                                    } />
                                {formMode == "editprofile" || mode == "editprofile" ? null : <Controller
                                    name="Password"
                                    control={methods.control}
                                    render={({ field, fieldState }) =>
                                        <div>
                                            <InputLabel>New Password</InputLabel>
                                            <TextField
                                                variant="outlined"
                                                {...field}
                                                required
                                                fullWidth
                                                name="password"
                                                type="password"
                                                id="Password"
                                            />
                                        </div>} />}
                            </Stack>
                            : <h2 style={{ fontWeight: "normal", textAlign: "center" }}>
                                Congratulations!<br /> You been successful register.
                            </h2>}
                    </Stack>
                    <Stack sx={{ width: "100%", alignItems: "center" }} spacing={2}>
                        {!formSubmitted ? <Button
                            size='large'
                            variant="outlined"
                            fullWidth
                            type="submit"
                        >
                            <h3 style={{ fontWeight: "normal" }}>Submit</h3>
                        </Button> : <Button
                            size="large"
                            onClick={() => { logout(); navigate(`/${merchant?.id}/membersignin`) }}
                            variant="outlined"
                            fullWidth
                        >
                            OK
                        </Button>}
                    </Stack>
                </Stack>
            </Box >
        </FormProvider>

    );
};

export default MemberSetUp;
