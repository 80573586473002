import styled from "@emotion/styled";
import { Box, Stack, Avatar, Typography } from "@mui/material";
import { Merchant } from "src/types/merchant-interface";

interface HeaderImageProps {
    merchant: Merchant
}

const ImageWrapper = styled('div')<{ src: string }>(({ src }) => ({
    backgroundImage: `url(${src})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '50vh',
    width: '100%',
}));

export const HeaderImage: React.FC<HeaderImageProps> = ({ merchant }) => {
    return (
    <Box sx={{ position: "relative", mb: "8px" }}>
            {merchant.MerchantCover && <Box sx={{ minHeight: 90 }}>
                <img src={merchant.MerchantCover} style={{ width: "100%", height: "auto" }} />
            </Box>}
            <Stack sx={merchant.MerchantCover ? { position: "absolute", bottom: "-23px", left: "0", pl: 3, pr: 3, } : { p: 2, pb: 0 }} direction="row">
                <Box>
                    <Avatar
                        src={merchant.MerchantLogo} // URL of the uploaded image
                        alt="Logo"
                        variant="rounded"
                        sx={{
                            objectFit: 'contain',
                            width: 100,
                            height: 100,
                            boxShadow: "0 5px 30px 0 rgba(0, 0, 0, 0.11), 0 5px 15px 0 rgba(0, 0, 0, 0.08) !important",
                            padding: "8px"
                        }}
                    />
                </Box>
                <Stack sx={{ color: merchant.MerchantCover ? "white" : "auto", pl: "0.5em", height: "70px", justifyContent: "end" }}>
                    <h3 style={{margin:0}}>{merchant.MerchantName}</h3>
                    <Typography variant="body2" style={{margin:0}}>{merchant.MerchantSubTitle}</Typography>
                </Stack>
            </Stack>
        </Box>
    );
};