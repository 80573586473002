import React, { useState } from 'react';
import { Box, Button, Typography, Avatar, Paper, IconButton, InputLabel, Tooltip, Icon } from '@mui/material';
import { Clear as ClearIcon, PictureAsPdf as PdfIcon } from '@mui/icons-material';
import axios from 'axios';
import { CONSTANTS } from '../constants';
import axiosService from '../services/axios';
import { RecordActions } from '../types/enum';
import AddCircleIcon from '@mui/icons-material/AddCircle';
interface ImageUploadProps {
    imageUrl: string | null;
    onImageChange: (url: string | null) => void;
    onRemove?: () => void;
    mode?: string;
    name: string;
    label?: string;
    allowPdf?: boolean
    width?: string
    height?: string;
}

const ImageUpload: React.FC<ImageUploadProps> = ({ imageUrl, onImageChange, onRemove, mode, name, label, height, width, allowPdf = false }) => {
    const [uploading, setUploading] = useState<boolean>(false);
    const [message, setMessage] = useState<string | null>(null);

    const handleUpload = async (file: File) => {
        setUploading(true);
        setMessage(null);

        const formData = new FormData();
        formData.append('image', file);

        try {
            const response = await axiosService.post<{ message: string, fileUrl: string }>(CONSTANTS.ApiConstants.uploadImage, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            onImageChange(response.data.fileUrl); // Set the file URL on success
            setMessage(response.data.message);
        } catch (error) {
            if (axios.isAxiosError(error) && error.response) {
                setMessage(error.response.data.message);
            } else {
                setMessage('Error uploading file');
            }
        } finally {
            setUploading(false);
        }
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files ? event.target.files[0] : null;
        if (file) {
            handleUpload(file);
        }
    };

    const isFile = allowPdf && imageUrl && !(imageUrl.split('.').pop()?.toLowerCase() === "png" || imageUrl.split('.').pop()?.toLowerCase() === "jpg" || imageUrl.split('.').pop()?.toLowerCase() === "jpeg" || imageUrl.split('.').pop()?.toLowerCase() === "gif");
    const getFileIcon = () => {
        switch ((imageUrl ?? "").split('.').pop()?.toLowerCase()) {
            case "pdf":
                return "/assets/icons/pdf.png";
            case "doc":
            case "docx":
                return "/assets/icons/word.png";
            case "xls":
            case "xlsx":
                return "/assets/icons/xls.png";
            case "pptx":
                return "/assets/icons/ppt.png";
            default:
                return "/assets/icons/file.png";
        }
    }
    return (
        <Box>
            <InputLabel>{label ?? ""}</InputLabel>
            <Paper
                sx={{
                    position: 'relative',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: 2,
                    border: '2px dashed #ccc',
                    borderRadius: 2,
                    minHeight: height ?? '200px',
                    width: width ?? '100%',
                    maxWidth: "100%"
                }}
            >
                {imageUrl ? (
                    <Box sx={{ position: 'relative', width: width ?? '100%', height: height ?? '200px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        {isFile ? (
                            <Avatar
                                src={getFileIcon()}
                                alt="Selected"
                                variant="square"
                                sx={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'contain',
                                    display: 'flex',
                                    margin: 'auto'
                                }}
                            />
                        ) : (
                            <Avatar
                                src={imageUrl}
                                alt="Selected"
                                variant="square"
                                sx={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'contain',
                                    display: 'flex',
                                    margin: 'auto'
                                }}
                            />
                        )}

                        {/* Delete File IconButton at the Top-Right */}
                        <IconButton
                            onClick={() => {
                                onImageChange(null);
                                if (onRemove) onRemove();
                            }}
                            color="secondary"
                            sx={{
                                position: 'absolute',
                                top: 0,
                                right: 0,
                            }}
                        >
                            <ClearIcon />
                        </IconButton>

                        {/* Change File Button in the Center */}
                        <Box
                            id="editor"
                            sx={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                display: mode && mode === RecordActions.view ? 'none' : 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            <input
                                type="file"
                                accept={allowPdf ? "image/*,application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.openxmlformats-officedocument.presentationml.presentation" : "image/*"}
                                onChange={handleFileChange}
                                style={{ display: 'none' }}
                                id={name}
                            />
                            <label htmlFor={name}>
                                <Button variant="contained" component="span" color="primary" size="small">
                                    Change File
                                </Button>
                            </label>
                        </Box>
                    </Box>
                ) : (
                    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" width="100%">
                        <input
                            type="file"
                            accept={allowPdf ? "image/*,application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.openxmlformats-officedocument.presentationml.presentation" : "image/*"}
                            onChange={handleFileChange}
                            style={{ display: 'none' }}
                            id={name}
                        />
                        <label htmlFor={name}>
                            <IconButton
                                color="primary"
                                component="span"
                                sx={{
                                    width: 64,
                                    height: 64,
                                    padding: 2,
                                    fontSize: '5rem',  // Increase the icon size
                                }}
                            >
                                <AddCircleIcon fontSize="inherit" />
                            </IconButton>
                        </label>

                    </Box>
                )}
            </Paper>
            {message && (
                <Box mt={2}>
                    <Typography variant="body1">{message}</Typography>
                </Box>
            )}
        </Box>
    );
};

export default ImageUpload;
