
import React, { useEffect, useState } from 'react';
import { Tabs, Tab, Box, Typography, Divider, IconButton, List, ListItem, ListItemText, Drawer, Stack } from '@mui/material';
import { styled } from '@mui/system';
import { Merchant } from 'src/types/merchant-interface';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useNavigate, useParams } from 'react-router-dom';
import { CONSTANTS } from 'src/constants';
import { NoticeBoard, NoticeTab } from 'src/intefaces/notice-board.inteface';
import axiosService from 'src/services/axios';
import { NoticeLayout, RecordTypes, ZoomMode } from 'src/types/enum';
import { FullCardViewNotice } from './fullcardview-notice';
import { useMerchant } from 'src/context/MerchantContext';
import { ShortPreviewImgNotice } from './short-preview-img-notice';
import { ShortPreviewTileNotice } from './short-preview-tile';

const FullWidthTab = styled(Tab)(({ theme }) => ({
    width: '50%', // Make each tab take up the full width
}));

const TabPanel = ({ children, value, index, ...other }: any) => (
    <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
    >
        {value === index && (
            <Box sx={{ p: 3 }}>
                {children}
            </Box>
        )}
    </div>
);


// Styled components
const StyledListItem = styled(ListItem)(({ theme }) => ({
    '&:nth-of-type(even)': {
        backgroundColor: theme.palette.grey[100],
    },
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.common.white,
    },
}));

const OutletName = styled(Typography)(({ theme }) => ({
    fontWeight: 'bold',
    color: theme.palette.primary.main,
}));

const OutletDetails = styled(Typography)(({ theme }) => ({
    color: theme.palette.text.secondary,
}));

const ArrowIcon = styled(IconButton)(({ theme }) => ({
    marginLeft: 'auto',
}));
const MobileNoticesView: React.FC = () => {
    const [value, setValue] = React.useState(0);
    const navigate = useNavigate();
    const [tabs, setTabs] = useState<NoticeTab[]>([]);
    const [noticeLayout, setNoticeLayout] = useState<string>();
    const [noticeBoards, setNoticeBoards] = useState<NoticeBoard[]>([]);
    const { merchantid } = useParams()
    const { merchant, setBackButton } = useMerchant();
    const [openQuickView, setOpenQuickView] = useState<NoticeBoard | undefined>();
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };
    const openOutlet = (id: number) => {
        navigate("/" + id);
        window.scrollTo(0, 0);
        setValue(0)
    }
    const getNoticeTabs = async () => {
        try {
            const response = await axiosService.get<NoticeTab[] | any>(`${CONSTANTS.ApiConstants.getNoticeTabs}/${merchantid}`)
            if (response.status != 200) {
                setTabs([])
            } else {
                const json = response.data;
                json && json.sort((a: NoticeTab, b: NoticeTab) => Number(a.sortId) - Number(b.sortId));
                setTabs(json);
            }
        } catch (ex: any) {
            setTabs([])

            console.error("Exception Caught", ex)
        }
    }
    const getNoticeLayout = async () => {
        try {
            const response = await axiosService.get<{ id: string, noticeLayout: string } | any>(`${CONSTANTS.ApiConstants.public_getNoticeLayout}/${merchantid}`)
            if (response.status != 200) {

            } else {
                const json = response.data;
                setNoticeLayout(json?.noticeLayout);
            }
        } catch (ex: any) {

            console.error("Exception Caught", ex)
        }
    }

    const getNoticeBoards = async () => {
        try {
            const response = await axiosService.get<NoticeBoard[] | any>(`${CONSTANTS.ApiConstants.getAllNotices}/${merchantid}`)
            if (response.status != 200) {
                setNoticeBoards([])
            } else {
                const json = response.data;
                setNoticeBoards(json);
            }
        } catch (ex: any) {
            setNoticeBoards([])
            console.error("Exception Caught", ex)
        }
    }
    useEffect(() => {
        getNoticeTabs()
        getNoticeBoards()
        getNoticeLayout()
        setBackButton && setBackButton(false);
    }, [])
    const getNoticesToView = () => {
        return noticeBoards.filter((n) => n.tabs.find(t => t?.id == tabs[value]?.id))
    }
    return (
        <Box sx={{ width: '100%' }}>
            <Tabs
                value={value}
                onChange={handleChange}
                aria-label=""
                variant="fullWidth"
                TabIndicatorProps={{ style: { background: merchant?.HeaderColorCode } }}
                sx={{
                    borderBottom: 1,
                    borderColor: 'divider',
                    "&.Mui-selected": {
                        color: merchant?.HeaderColorCode || "initial"
                    }
                }}
            >
                {tabs && tabs.map((t) => {
                    return <Tab sx={{
                        fontSize: `${merchant?.NoticeBoardFontsizeTag}px`,
                        "&.Mui-selected": {
                            color: merchant?.HeaderColorCode || "initial"
                        }
                    }}
                        disableRipple label={t.tabName} id={t.tabName} aria-controls="map" />
                })}
            </Tabs>
            <TabPanel fullWidth value={1} index={1} style={{ textAlign: "center" }}>
                {noticeBoards && noticeBoards.length ? (
                    noticeLayout == NoticeLayout.FullDetails ?
                        getNoticesToView().map((n: NoticeBoard, index: number) => {
                            return (<Stack><Stack sx={{ pb: index == getNoticesToView().length - 1 ? 0 : 3, pt: index == 0 ? 0 : 3 }}>
                                <FullCardViewNotice notice={n} />
                            </Stack>
                                {index != getNoticesToView().length - 1 ? <Divider sx={{ borderColor: "rgb(0 0 0 / 50%)" }} /> : null}
                            </Stack>)
                        }) : (NoticeLayout.ImageSummaries == noticeLayout ? getNoticesToView().map((n: NoticeBoard) => {
                            return (<>
                                <ShortPreviewImgNotice onClick={() => n.zoomMode == ZoomMode.Ascend ? setOpenQuickView(n) : navigate(`${n.id}`)} notice={n} />
                                <br />
                            </>)
                        }) : getNoticesToView().map((n: NoticeBoard) => {
                            return (<>
                                <ShortPreviewTileNotice onClick={() => n.zoomMode == ZoomMode.Ascend ? setOpenQuickView(n) : navigate(`${n.id}`)} notice={n} />
                                <br />
                            </>)
                        }))) :
                    <Typography sx={{ textAlign: "center" }}>Nothing Found.</Typography>
                }
            </TabPanel>
            <Drawer
                variant={'temporary'}
                open={!!openQuickView}
                onClose={() => setOpenQuickView(undefined)}
                anchor='bottom'
                PaperProps={{
                    sx: {
                        borderRadius: '16px 16px 0 0', // Rounded corners at the top
                        height: '85vh', // Adjust height as needed
                    },
                }}
            >
                <Box
                    sx={{ p: 3 }}
                    role="presentation"
                >
                    <FullCardViewNotice notice={openQuickView} />
                </Box>

            </Drawer>
        </Box >
    );
};

export default MobileNoticesView;
