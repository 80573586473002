import React, { useEffect, useState } from 'react';
import logo from './logo.svg';
import './App.css';
import MyButton from './components/Button';
import ResponsiveDrawer from './components/ResponsiveDrawer';
import { ThemeProvider } from '@emotion/react';
import { Box, CssBaseline } from '@mui/material';
import theme from './theme/theme';
import { AuthProvider, useAuth } from './context/AuthContext';
import AppRoutes from './routes/Routes';
import { AlertProvider } from './components/CommonAlert';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import CustomThemeProvider from './theme/theme-provider';
function App() {
  const [deferredPrompt, setDeferredPrompt] = useState<BeforeInstallPromptEvent | null>(null);
  const [isInstallable, setIsInstallable] = useState<boolean>(false);

  useEffect(() => {
    const handleBeforeInstallPrompt: any = (e: BeforeInstallPromptEvent) => {
      // Prevent the default prompt
      e.preventDefault();
      setDeferredPrompt(e);
      setIsInstallable(true); // Show the install button
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };
  }, []);
  return (
    <AlertProvider>
      <AuthProvider>
        <CustomThemeProvider>
          <CssBaseline />
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <AppRoutes />
          </LocalizationProvider>
        </CustomThemeProvider>
      </AuthProvider>
    </AlertProvider>
  );
}

export default App;
