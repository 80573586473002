import React from 'react';
import { Paper, Typography } from '@mui/material';
import { Merchant } from 'src/types/merchant-interface';
import './business-info.css';
interface BusinessInfoProps {
    merchant: Merchant;
}

const BusinessInfo: React.FC<BusinessInfoProps> = ({ merchant }) => {
    return merchant.MerchantDescription && merchant.MerchantDescription != '<p><br></p>' ? <Paper square={true} elevation={0} sx={{ pl: 3, pr: 3, overflow: "hidden" }}>
        <Typography className='business-desc' variant="body2" dangerouslySetInnerHTML={{ __html: merchant.MerchantDescription }} sx={{ overflow: "hidden" }} />
    </Paper> : <></>
};

export default BusinessInfo;
