
import * as React from 'react';
import { useSelection } from '../../hooks/use-selection';
import DataTable from '../../components/DataTable';
import { CONSTANTS } from '../../constants';
import axiosService from '../../services/axios';
import { useAlert } from '../../components/CommonAlert';
import DeleteConfirmationDialog from '../../components/ConfirmationDialog';
import { useNavigate } from 'react-router-dom';
import { DataType, RecordTypes } from 'src/types/enum';
import { Coupon } from 'src/intefaces/coupon.interface';
import { Paper } from '@mui/material';


function noop(): void {
  // do nothing
}



interface CouponsTableProps {
  count?: number;
  page?: number;
  rows?: Coupon[];
  rowsPerPage?: number;
  onDeleteCallback?: () => any
}

export function CouponsTable({
  count = 0,
  rows = [],
  page = 0,
  rowsPerPage = 0,
  onDeleteCallback
}: CouponsTableProps): React.JSX.Element {
  const rowIds = React.useMemo(() => {
    return rows.map((coupon) => coupon.id);
  }, [rows]);
  console.log("rows",rows);
  const navigate = useNavigate();
  const { selectAll, deselectAll, selectOne, deselectOne, selected } = useSelection(rowIds);
  const { showAlert } = useAlert();
  const selectedSome = (selected?.size ?? 0) > 0 && (selected?.size ?? 0) < rows.length;
  const [selectedId, setSelectedId] = React.useState<number | undefined>(undefined);
  const [openDeleteDlg, setOpenDeleteDlg] = React.useState<boolean>(false);
  const columns = [
    { id: 'id', label: 'Id' },
    { id: "name", label: "Name" },
    { id: 'image', label: 'imageId', type: DataType.image },
    { id: "validAfter", label: "Valid After" },
    { id: "validUntil", label: "Valid Until" },
    { id: "format", label: "Format" },
    { id: "Assigned", label: "Assigned" },
    { id: "Paid", label: "Redeemed" },
    { id: "expired_coupons", label: "Expired" },
    { id: "active_calculated", label: "Active" }
  ];
  const handleEdit = (id: number) => {
    navigate(`/coupons/edit/${id}`);
  };

  const handleDelete = async (id: number) => {
    setSelectedId(id);
    setOpenDeleteDlg(true);
  };

  const handleView = (id: number) => {
    navigate(`/coupons/view/${id}`);
  };
  const onDeleteConfirm = async () => {
    try {
      setOpenDeleteDlg(false)
      const response = await axiosService.delete<Coupon[]>(`${CONSTANTS.ApiConstants.GENERIC.delete[RecordTypes.coupon]}/${selectedId}`);
      if (response.status != 200) {
        showAlert('Failed to Delete Merchant', 'error');
      } else {
        showAlert('Merchant Deleted Successfully', 'success');
        onDeleteCallback && onDeleteCallback();
      }
    } catch (ex) {
      showAlert('Failed to Delete Merchant', 'error');
      console.error("Exception Caught", ex)
    } finally {
      setSelectedId(undefined);

    }
  }
  return (<div>
    <Paper square={true}>
      <DataTable
        rows={rows}
        columns={columns}
        onEdit={handleEdit}
        onDelete={handleDelete}
        onView={handleView}
      />
    </Paper>
    <DeleteConfirmationDialog
      open={openDeleteDlg}
      onClose={() => { setOpenDeleteDlg(false); setSelectedId(undefined) }}
      onConfirm={onDeleteConfirm}
      confirmLabel={"Delete"}
      cancelLabel={"Cancel"}
      message={"Are you sure you want to delete merchant"}
      title={"Confirm Delete"}
    />
  </div>)
}
