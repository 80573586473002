import React, { useState } from 'react';
import { Box, TextField, Button, Typography, Avatar, Select, MenuItem, InputLabel, FormControl, Stack, InputAdornment, Divider, Link } from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import SmsIcon from '@mui/icons-material/Sms';
import { useMerchant } from 'src/context/MerchantContext';
import { FlagIcon, FlagIconCode } from 'react-flag-kit';
import { Divide } from '@phosphor-icons/react';
import { useAuth } from 'src/context/AuthContext';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import axiosService from 'src/services/axios';
import { CONSTANTS } from 'src/constants';
import { Member } from 'src/types/member-interface';
import { useAlert } from 'src/components/CommonAlert';
import { OTPMode } from 'src/types/enum';
const MemberResetPassword: React.FC = () => {
    const { merchant } = useMerchant();
    const countries = [
        { label: 'Malaysia', value: '+60', flag: "MY" },
        { label: 'United States', value: '+1', flag: "US" },
        { label: 'India', value: '+91', flag: "IN" },
    ]
    const [password, setPassword] = useState('');
    const [lastOtpMode, setLastOtpMode] = useState('');
    const { memberLoginByOTP } = useAuth();
    let { mode, mobile, countrycode } = useParams();
    const [otp, setOtp] = useState<string[]>(["0", "0", "0", "0"]);
    const [formSubmitted, setFormSubmitted] = useState<boolean>(false);
    const [message, setMessage] = useState('');
    const navigate = useNavigate();
    const location = useLocation();
    const { showAlert } = useAlert();
    const [loading, setLoading] = useState<boolean>(true);
    const sendOtp = async () => {
        if (!mode) {
            mode = OTPMode.SMS;
        }
        try {
            const response = await (await axiosService.post<Member | any>(`${CONSTANTS.ApiConstants.user_memberLoginByOtp}/${merchant?.id}`, { countrycode: countrycode, mobile: mobile, otpMode: mode }))
            if (response.status != 200) {
                setLoading(false);
                setFormSubmitted(false)
                showAlert(response?.data?.message && typeof response.data.message == "string" ? response.data.message : 'Oops! Something is not working', 'error');
            } else {

                // setCodesent(true)
            }
        } catch (ex: any) {
            setLoading(false);
            // setCodesent(false)
            showAlert(ex?.response?.data?.message && typeof ex.response.data.message == "string" ? ex.response.data.message : 'Oops! Something is not working. Signup Failed', 'error');
            console.error("Exception Caught", ex)
        }
    };
    const handleSubmit = async (event: any) => {
        const eOtp = otp.join("");
        try {
            const response = await axiosService.post<Member | any>(`${CONSTANTS.ApiConstants.resetPassByOtp}/${merchant?.id}`, { countrycode: countrycode, mobile: mobile, OTP: eOtp, password: password })
            if (response.status != 200) {
                setLoading(false);
                setFormSubmitted(false)
                showAlert(response?.data?.message && typeof response.data.message == "string" ? response.data.message : 'Oops! Failed to reset password', 'error');
            } else {
                setFormSubmitted(true);
            }
        } catch (ex: any) {
            setLoading(false);
            setFormSubmitted(false);
            showAlert(ex?.response?.data?.message && typeof ex.response.data.message == "string" ? ex.response.data.message : 'Oops! Failed to reset password', 'error');
            console.error("Exception Caught", ex)
        }
        event.preventDefault();
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'space-evenly',
                minHeight: '80vh',
                padding: 2,
            }}
        >
            <Avatar
                sx={{
                    width: 80,
                    height: 80,
                    marginBottom: 3,
                }}
                src={merchant?.AppLogo}
                alt="Logo"
            />
            <Stack sx={{ width: "100%", alignItems: "center" }}>
                {!formSubmitted ?
                    <><Typography variant="subtitle2" sx={{ fontWeight: "bold" }} gutterBottom>
                        Enter 4-digit code
                    </Typography>
                        <Stack direction="row" sx={{ width: "100%" }} spacing={2}>

                            {[...Array(4)].map((_, index) => (
                                <TextField
                                    key={"otp" + index}
                                    focused={index == 0}
                                    variant="outlined"
                                    autoComplete='off'
                                    inputProps={{ maxLength: 1, style: { textAlign: "center" } }}
                                    onChange={(e) => {
                                        const currentOtp = otp;
                                        currentOtp[index] = e.target.value;
                                        setOtp(currentOtp);

                                    }}
                                />
                            ))}
                        </Stack>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            autoComplete='off'
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </> : <Typography variant="h6" sx={{ fontWeight: "bold", textAlign: "center" }} gutterBottom>
                        Password has been changed.<br />Press OK to login.
                    </Typography>}
            </Stack>
            <Stack sx={{ width: "100%", alignItems: "center" }}>

                {!formSubmitted ? <> <Button
                    onClick={handleSubmit}
                    variant="contained"
                    fullWidth
                    style={{ background: merchant?.FooterActiveButtonColor ?? "unset" }}
                >
                    Submit
                </Button>
                    <Stack direction="row" sx={{ justifyContent: "space-around", mt: 2, width: "100%" }}>
                        <Typography variant="subtitle1" align="center">
                            <Link onClick={() => { sendOtp() }} style={{ color: merchant?.FooterActiveButtonColor ?? "unset", textDecoration: "none" }}>Resend OTP</Link>

                        </Typography>
                        <Typography variant="subtitle1" align="center">
                            <Link onClick={() => { navigate(`/${merchant?.id}/membersignup`) }} style={{ color: merchant?.FooterActiveButtonColor ?? "unset", textDecoration: "none" }}>Change Number</Link>
                        </Typography>
                    </Stack>
                </> : <Button
                    onClick={() => navigate(`/${merchant?.id}/membersignin`)}
                    variant="contained"
                    fullWidth
                    style={{ background: merchant?.FooterActiveButtonColor ?? "unset" }}
                >
                    OK
                </Button>}
            </Stack>

        </Box >


    );
};

export default MemberResetPassword;
