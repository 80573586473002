import React, { useEffect } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import {
    Box,
    Button,
    TextField,
    Typography,
    Container,
    Grid,
    InputLabel,
    Stack,
} from '@mui/material';
import { CONSTANTS } from 'src/constants';
import axiosService from 'src/services/axios';
import { RecordActions } from 'src/types/enum';
import { Member } from 'src/types/member-interface';
import { useAlert } from 'src/components/CommonAlert';
import { useNavigate } from 'react-router-dom';
import { useMerchant } from 'src/context/MerchantContext';

type FormValues = {
    currentPassword: string;
    newPassword: string;
    reEnterNewPassword: string;
};

const ChangePasswordForm: React.FC<{ onComplete?: () => any }> = ({ onComplete }) => {
    const { register, handleSubmit, formState: { errors }, watch } = useForm<FormValues>();
    const { showAlert } = useAlert();
    const { merchant, setBackButton } = useMerchant();
    const navigate = useNavigate();
    const onSubmit: SubmitHandler<FormValues> = async (data) => {
        try {
            const response = await axiosService.put<Member>(`${CONSTANTS.ApiConstants.updatePassword}`, data)
            if (response.status != 200) {
                showAlert('Failed to Update ', 'error');
            } else {
                const json = response.data;
                showAlert('Password Updated', 'success');
                onComplete && onComplete();
            }
        } catch (ex: any) {
            console.log("ex?.response?.message", ex?.response?.message);
            showAlert(ex?.response?.data?.message ?? 'Failed to update', 'error');
            console.error("Exception Caught", ex)
        }
        // Handle password change logic here (API call etc.)
    };

    // Watching the newPassword field to compare with reEnterNewPassword
    const newPassword = watch('newPassword', '');
    useEffect(() => {
        setBackButton && setBackButton(true);
    }, [])
    return (

        <Box
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            noValidate
        >
            <Stack style={{ justifyContent: "space-between", minHeight: "80vh" }} sx={{ p: 3 }}>
                <Stack spacing={2}  >
                    <Stack >
                        <InputLabel>Current Password</InputLabel>
                        <TextField
                            variant="outlined"
                            fullWidth
                            type="password"
                            {...register('currentPassword', { required: 'Current password is required' })}
                            error={!!errors.currentPassword}
                            helperText={errors.currentPassword?.message}
                        />
                    </Stack>

                    <Stack >
                        <InputLabel>New Password</InputLabel>
                        <TextField
                            variant="outlined"
                            fullWidth
                            type="password"
                            {...register('newPassword', {
                                required: 'New password is required',
                                minLength: { value: 6, message: 'Password must be at least 6 characters long' },
                            })}
                            error={!!errors.newPassword}
                            helperText={errors.newPassword?.message}
                        />
                    </Stack>

                    <Stack >
                        <InputLabel>Re-enter New Password</InputLabel>
                        <TextField
                            variant="outlined"
                            fullWidth
                            type="password"
                            {...register('reEnterNewPassword', {
                                required: 'Please confirm your new password',
                                validate: value =>
                                    value === newPassword || 'Passwords do not match',
                            })}
                            error={!!errors.reEnterNewPassword}
                            helperText={errors.reEnterNewPassword?.message}
                        />
                    </Stack>
                </Stack>
                <Stack>
                    <Button
                        type="submit"
                        fullWidth
                        variant="outlined"
                        color="primary"
                    >
                        Change Password
                    </Button>
                </Stack>
            </Stack>
        </Box>
    );
};

export default ChangePasswordForm;
