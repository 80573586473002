import { Box, Stack, Typography, CircularProgress, Avatar, TextField, Button, IconButton, InputAdornment, FormControl, MenuItem, Select, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Container } from "@mui/material"
import moment from "moment";
import { useState } from "react";
import { useAlert } from "src/components/CommonAlert";
import { CONSTANTS } from "src/constants";
import axiosService from 'src/services/axios';
import { Member } from "src/types/member-interface";
import { useMerchant } from "src/context/MerchantContext";
import CloseIcon from '@mui/icons-material/Close';
import { FlagIcon, FlagIconCode } from 'react-flag-kit';
import { useAuth } from "src/context/AuthContext";
import { Roles } from "src/types/enum";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { keyframes } from '@mui/system';
import { eWallet } from "src/intefaces/eWallet.interface";
import CancelIcon from '@mui/icons-material/Cancel';

const bounceAnimation = keyframes`
  0% { transform: scale(0); }
  50% { transform: scale(1.1); }
  100% { transform: scale(1); }
`;

export const TransfereWallet = ({ selectedeWallet, onClose, eWalletAction }: { selectedeWallet: eWallet, onClose: any, eWalletAction?: "transfer" | "pay" | "transations" }) => {
    const { showAlert } = useAlert();
    const [searchingMember, setSearchingMember] = useState<boolean>(false);
    const [scannedMember, setScannedMember] = useState<Member | undefined>(undefined);
    const [transferComplete, setTransferComplete] = useState<"Success" | "Invalid Phone Number" | "Wrong Password" | "Failed to Transfer" | "Failed to Pay" | "Cannot Transfer to Self" | "Insufficient Balance" | undefined>(undefined);
    const [uTUsername, setUTUsername] = useState<string>("");
    const [uTPassword, setUTPassword] = useState<string>("");
    const { merchant } = useMerchant();
    const { user } = useAuth();
    const countries = [
        { label: 'Malaysia', value: '+60', flag: "MY" },
        { label: 'United States', value: '+1', flag: "US" },
        { label: 'India', value: '+91', flag: "IN" },
    ];
    const [selectedCountry, setSelectedCountry] = useState<any>({ label: 'Malaysia', value: '+60', flag: "MY" });
    const [mobile, setMobile] = useState<string>();
    const [points, setPoints] = useState<number>();
    const onQrScanned = async (mobileNumber: string) => {

        try {
            setSearchingMember(true);
            const response = await axiosService.get<Member>(`${CONSTANTS.ApiConstants.getMemberByMob}/${mobileNumber}`)

            if (response.status != 200) {
                setScannedMember(undefined)
                setSearchingMember(false);
                showAlert('Failed to Get Member Details', 'error');
            } else {
                const json: Member = response.data;
                if (json.DOB) {
                    json.DOB = moment(json.DOB ?? null).utc(true).local();;
                    json.RegistredTime = moment(json.RegistredTime ?? null).utc(true).local();
                }
                setSearchingMember(false);
                if (json.id == user?.account?.id) {
                    setScannedMember({ Mobile: mobile } as Member);
                    setTransferComplete("Cannot Transfer to Self");
                } else if (json?.Mobile) {
                    setScannedMember(json)
                }
                else {
                    setScannedMember({ Mobile: mobile } as Member);
                    setTransferComplete("Invalid Phone Number");
                }
            }
        } catch (ex) {
            setScannedMember(undefined)
            setSearchingMember(false);
            showAlert('Failed to Get Member Details', 'error');
            console.error("Exception Caught", ex)
        }
    }
    const postTransfer = async (e: any) => {
        e.preventDefault();
        try {
            let username = user?.username;
            if (user?.role == Roles.member) {
                username = user?.username.split("@")[0]
            }
            const payload = {
                password: uTPassword,
                ewalletMappingId: selectedeWallet?.id,
                username: username,
                transferTo: scannedMember?.id,
                transferToPhone: scannedMember?.Mobile,
                merchantid: merchant?.id,
                ewalletName: selectedeWallet?.name,
                points: points

            }
            const response: any = await axiosService.post<any>(`${CONSTANTS.ApiConstants.transferEwalletPoints}`, payload)
            if (response.status != 200) {
                setTransferComplete(response.message ?? "Failed to Transfer");
            } else {
                setTransferComplete("Success");
                //showAlert("Transfer Successful", "success");               

            }
        } catch (ex: any) {
            if (ex.response.status == 401) {
                setTransferComplete("Wrong Password");
            } else {
                setTransferComplete(ex?.response?.data?.message ?? "Failed to Transfer");
            }
        }
    }
    const postPay = async (e: any) => {
        e.preventDefault();
        try {
            let username = user?.username;
            if (user?.role == Roles.member) {
                username = user?.username.split("@")[0]
            }
            const payload = {
                password: uTPassword,
                ewalletMappingId: selectedeWallet?.id,
                ewalletName: selectedeWallet?.name,
                points: points

            }
            const response: any = await axiosService.post<any>(`${CONSTANTS.ApiConstants.payEwalletPoints}`, payload)
            if (response.status != 200) {
                setTransferComplete(response?.message ?? "Failed to Pay");
            } else {
                setTransferComplete("Success");
                //showAlert("Transfer Successful", "success");               

            }
        } catch (ex: any) {
            if (ex.response.status == 401) {
                setTransferComplete("Wrong Password");
            } else {
                setTransferComplete(ex?.response?.data?.message ?? "Failed to Transfer");
            }
        }
    }
    const closeTransferWindow = () => {
        if (transferComplete == "Success") {
            setScannedMember(undefined)
            setUTPassword("")
            setUTUsername("")
            onClose && onClose({ reason: "transfer" })
        } else if (transferComplete == "Wrong Password") {
            setTransferComplete(undefined);
        } else {
            setScannedMember(undefined);
            setTransferComplete(undefined);

        }
    }
    return <Box sx={{ width: '100%', p: 3 }}>
        <Stack style={{ alignItems: "center", height: "80vh", justifyContent: "space-between" }}>
            <h1>{eWalletAction == "transfer" ? "Transfer" : "Pay"}</h1>
            <br />
            <Stack sx={{ width: "100%" }}>

                {eWalletAction == "transfer" ? <>  <TextField
                    variant="outlined"
                    fullWidth
                    disabled={!!scannedMember}
                    onChange={(e) => { setMobile(e.target.value) }}
                    placeholder="Phone Number"
                    sx={{
                        '& .MuiInputBase-input': {
                            fontSize: '1.5em', // Set the font size here
                        },
                        '& .MuiInputLabel-root': {
                            fontSize: '1.5em', // Set the label font size if needed
                        },
                    }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <FormControl variant="standard">
                                    <Select
                                        value={0}
                                        onChange={(e) => { setSelectedCountry(countries.find(c => c.value == e.target.value)); }}
                                        displayEmpty
                                        sx={{
                                            "minWidth": "80px",
                                            "minHeight": "0px",
                                            "& > .MuiSelect-select": {
                                                "minHeight": "0px !important"
                                            }
                                        }}
                                        renderValue={(value) => (
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                {selectedCountry ? <><FlagIcon code={selectedCountry?.flag as FlagIconCode} />
                                                    <h2 style={{ fontWeight: "normal", fontSize: "24px" }}>                                                        {selectedCountry?.value}</h2></> : <></>}
                                            </div>
                                        )}
                                    >
                                        {countries.map((country) => (
                                            <MenuItem key={country.value} value={country.value}>
                                                <FlagIcon code={country.flag as FlagIconCode} />
                                                <h2 style={{ fontWeight: "normal", fontSize: "24px" }}>({country.value})</h2>
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </InputAdornment>
                        ),
                    }}
                />
                    <br />
                </> : <></>}
                <TextField
                    variant="outlined"
                    fullWidth
                    sx={{
                        '& .MuiInputBase-input': {
                            fontSize: '1.5em', // Set the font size here
                        },
                        '& .MuiInputLabel-root': {
                            fontSize: '1.5em', // Set the label font size if needed
                        },
                    }}
                    disabled={!!scannedMember}
                    onChange={(e) => { setPoints(isNaN(Number(e.target.value)) ? 0 : Number(e.target.value)) }}
                    placeholder={selectedeWallet.unitName}
                />
            </Stack>
            <br />
            <Stack sx={{ width: "100%" }}>
                {!scannedMember ?
                    <Button size="large" fullWidth variant="outlined" color="primary" onClick={() => {
                        if (eWalletAction == "transfer") {
                            const countrycode = selectedCountry.value;
                            const intPhone = (countrycode + mobile).replace(/[+-]/g, '');
                            onQrScanned(intPhone);
                        } else {
                            setScannedMember({ Mobile: "00000" } as Member)
                        }

                    }}><h3>Transfer</h3></Button> :

                    <></>
                }
                <br />
                <IconButton color='primary' size="large" onClick={() => { onClose && onClose({ reason: "cancel" }) }} aria-label="cancel">
                    <CancelIcon sx={{ fontSize: '1.5em' }} />
                </IconButton>
            </Stack>
        </Stack>
        {scannedMember && <Dialog fullWidth sx={{ textAlign: "center" }} open={!!scannedMember} onClose={() => setScannedMember(undefined)} disableScrollLock={true}>

            <DialogContent>
                {transferComplete ?
                    <Stack spacing={2}>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                mb: 3,
                            }}
                        >
                            {transferComplete == "Success" ?
                                <CheckCircleIcon
                                    sx={{
                                        fontSize: '4rem',
                                        color: '#4caf50',
                                        animation: `${bounceAnimation} 0.5s ease-in-out forwards`,
                                    }}
                                /> : <CancelIcon
                                    sx={{
                                        fontSize: '4rem',
                                        color: '#ff0000',
                                        animation: `${bounceAnimation} 0.5s ease-in-out forwards`,
                                    }}
                                />}

                        </Box>
                        <h2>
                            {transferComplete}
                        </h2>
                        <Box>
                            <Button size="large" fullWidth type="button" color="primary" variant="outlined" onClick={() => closeTransferWindow()}>
                                <h3 style={{ fontWeight: "normal" }}>Ok</h3>
                            </Button>
                        </Box>
                    </Stack>
                    :
                    <form onSubmit={eWalletAction == "transfer" ? postTransfer : postPay}>
                        <TextField
                            onChange={(e) => { setUTPassword(e.target.value) }}
                            fullWidth
                            id="password"
                            sx={{
                                '& .MuiInputBase-input': {
                                    fontSize: '1.5em', // Set the font size here
                                },
                                '& .MuiInputLabel-root': {
                                    fontSize: '1.5em', // Set the label font size if needed
                                },
                            }}
                            placeholder="Password"
                        />
                        <DialogActions sx={{ pl: 0, pr: 0 }}>
                            <Button size="large" fullWidth type="submit" color="primary" variant="contained">
                                <h3 style={{ fontWeight: "normal" }}>Ok</h3>
                            </Button>
                            <Button size="large" fullWidth onClick={() => setScannedMember(undefined)} color="primary" variant="outlined">
                                <h3 style={{ fontWeight: "normal" }}>Cancel</h3>
                            </Button>
                        </DialogActions>

                    </form>
                }
            </DialogContent>
        </Dialog>}
    </Box>
}