import React, { useDebugValue, useEffect, useState } from 'react';
import { Box, Grid, styled, Typography, IconButton, Avatar, Backdrop, Fade, Modal } from '@mui/material';
import { Merchant } from 'src/types/merchant-interface';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
interface HeaderImageProps {
    src: string;
}

const ImageWrapper = styled('div')<{ src: string }>(({ src }) => ({
    backgroundImage: `url(${src})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '44vw',
    maxHeight: "300px",
    width: '100%',
}));



interface BusinessInfoProps {
    merchant: Merchant;
}
// Styled component for the overlay icon
const OverlayIcon = styled(IconButton)({
    position: 'absolute',
    bottom: '10px',
    right: '10px',
    backgroundColor: 'white',
    borderRadius: '50%',
    '&:hover': {
        backgroundColor: 'lightgrey',
    },
});

const Gallery: React.FC<BusinessInfoProps> = ({ merchant }) => {
    const [images, setImages] = useState<string[]>([]);
    const [showAll, setShowAll] = useState<boolean>(false);
    const [open, setOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState<string | null>(null);
    const [zoom, setZoom] = useState(1);
    const _m: any = merchant;
    useEffect(() => {
        setImages([]);
        if (merchant) {
            const _m: any = merchant;
            const img: string[] = []
            merchant && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((i) => {
                _m[`g_img_${i}`] && img.push(_m[`g_img_${i}`])
            })
            setImages(img);
        }
    }, [merchant])
    const handleOpen = (src: string) => {
        setSelectedImage(src);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setSelectedImage(null);
        setZoom(1);
    };
    return images.length ? <Box sx={{ pl: 3, pr:3 }}>
        <h2 style={{lineHeight:"24px"}}>Gallery</h2>

        <Grid container spacing={2} sx={{pt:2}}>
            {images.map((avatar, index) => (
                (!showAll ? index < 3 : true) && <Grid item xs={6} sm={6} md={6} key={index}>
                    <Box sx={{ position: 'relative' }} onClick={() => handleOpen(avatar)}>
                        <Zoom>
                            <Avatar src={avatar} sx={{ maxWidth: 248, maxHeight: 248, width: "43vw", height: "43vw" }} variant='rounded' />
                        </Zoom>
                    </Box>
                </Grid>
            ))}
            {!showAll && images[4] && <Grid item xs={6} sm={6} md={6} key={"4"}>
                <Box sx={{ position: 'relative' }} onClick={() => { setShowAll(true) }}>
                    <Avatar src={images[4]} sx={{ maxWidth: "258px", maxHeight: "258px", width: "43vw", height: "43vw",borderRadius:"10px" }} variant='rounded' />
                    <Box
                        sx={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            backgroundColor: 'rgba(0, 0, 0, 0.6)',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderRadius: '10px',
                            color: 'white',
                        }}
                    >
                        <h3>+{images.length - 4}</h3>
                    </Box>
                </Box>
            </Grid>}
        </Grid>

    </Box> : <></>
};

export default Gallery;
