'use client';

import * as React from 'react';
import Box from '@mui/material/Box';

import { Card, CardContent, Tab, Tabs, Typography } from '@mui/material';
import { MerchantInfo } from './merchant-info';
import { AppSettings } from './app-settings';
import { Noticeboard } from './notice-board';
import { ThankyouBox } from './thankyoubox';
import { MajorFunction } from './major-function';
import { RewardFunction } from './reward-function';
import { FunctionControl } from './function-control';
import { WhatsAppFunction } from './whatsapp-function';
import { PaymentGateway } from './payment-gateway';
import { OtherDetails } from './other-details';
import { RecordActions, RecordTypes, Roles } from '../../../types/enum';
import { FormProp } from '../../../types/formprop-interface';
import { LoginDetails } from './login-details';
import { useAuth } from '../../../context/AuthContext';
import { EmailConfig } from './email-config';





export function MerchantForm({ action, id, recordType }: FormProp): React.JSX.Element {
  const [value, setValue] = React.useState(0);
  const { user } = useAuth();
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  function TabPanel(props: any) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  return (
    <Box >
      <Card>
        <CardContent>
          {(recordType == RecordTypes.merchant && Roles.superadmin == user?.role)}
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
            allowScrollButtonsMobile
          >

            <Tab label="General" value={0} />
            {recordType == RecordTypes.merchant && (Roles.superadmin == user?.role || Roles.merchant == user?.role) && <Tab disabled={action == RecordActions.add} label="App Settings" value={1} />}
            {recordType == RecordTypes.merchant && (Roles.superadmin == user?.role || Roles.merchant == user?.role) && <Tab disabled={action == RecordActions.add} label="Noticeboard" value={2} />}
            {recordType == RecordTypes.merchant && (Roles.superadmin == user?.role || Roles.merchant == user?.role) && <Tab disabled={action == RecordActions.add} label="ThankYou Box" value={3} />}
            {((recordType == RecordTypes.merchant && Roles.superadmin == user?.role) || (Roles.merchant == user?.role && recordType == RecordTypes.outlet)) && <Tab disabled={action == RecordActions.add} label="Major Function" value={4} />}
            {((recordType == RecordTypes.merchant && Roles.superadmin == user?.role) || (Roles.merchant == user?.role && recordType == RecordTypes.outlet)) && <Tab disabled={action == RecordActions.add} label="Reward Function" value={5} />}
            {((recordType == RecordTypes.merchant && Roles.superadmin == user?.role) || (Roles.merchant == user?.role && recordType == RecordTypes.outlet) || (Roles.merchant == user?.role && recordType == RecordTypes.partner)) && <Tab disabled={action == RecordActions.add} label="Function Control" value={6} />}
            {(Roles.superadmin == user?.role || Roles.merchant == user?.role) && <Tab disabled={action == RecordActions.add} label="Whatsapp Function" value={7} />}
            {(Roles.superadmin == user?.role || Roles.merchant == user?.role) && <Tab disabled={action == RecordActions.add} label="Payment Gateway" value={8} />}
            {(Roles.superadmin == user?.role || Roles.merchant == user?.role) && <Tab disabled={action == RecordActions.add} label="Others" value={9} />}
            {<Tab disabled={action == RecordActions.add} label="Login" value={10} />}
            {(Roles.superadmin == user?.role || Roles.merchant == user?.role) && <Tab disabled={action == RecordActions.add} label="Email" value={11} />}
            
          </Tabs>
          <TabPanel value={value} index={0}>
            <MerchantInfo action={action} id={id} />
          </TabPanel>

          <TabPanel value={value} index={1}>
            <AppSettings action={action} id={id} />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <Noticeboard action={action} id={id} />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <ThankyouBox action={action} id={id} />
          </TabPanel>
          <TabPanel value={value} index={4}>
            <MajorFunction action={action} id={id} />
          </TabPanel>
          <TabPanel value={value} index={5}>
            <RewardFunction action={action} id={id} />
          </TabPanel>
          <TabPanel value={value} index={6}>
            <FunctionControl action={action} id={id} recordType={recordType} />
          </TabPanel>
          <TabPanel value={value} index={7}>
            <WhatsAppFunction action={action} id={id} />
          </TabPanel>
          <TabPanel value={value} index={8}>
            <PaymentGateway action={action} id={id} />
          </TabPanel>
          <TabPanel value={value} index={9}>
            <OtherDetails action={action} id={id} recordType={recordType}/>
          </TabPanel>
          <TabPanel value={value} index={10}>
            <LoginDetails action={action} id={id} />
          </TabPanel>
          <TabPanel value={value} index={11}>
            <EmailConfig action={action} id={id} />
          </TabPanel>
          
        </CardContent >
      </Card >
      <br />
      <br />
      <br />
      <br />
      <br />

    </Box>
  );
}
