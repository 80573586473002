import { Box, Grid, Autocomplete, TextField, InputAdornment, Button, Typography } from "@mui/material"
import { DatePicker } from "@mui/x-date-pickers"
import moment from "moment";
import { useEffect, useState } from "react";
import { FormProvider, Controller, useForm } from "react-hook-form"
import { useAlert } from "src/components/CommonAlert";
import DataTable from "src/components/DataTable";
import { CONSTANTS } from "src/constants";
import { eWallet, eWalletMapping } from "src/intefaces/eWallet.interface";
import axiosService from "src/services/axios";
import { RecordTypes } from "src/types/enum";
import { Member } from "src/types/member-interface";
interface AsssigneWalletsProps {
    members: Member[],
    onCancel: () => any
}
interface Receipent extends Member {
    status: string;
    ewalletname: string;
    points: string
}
export function AsssigneWallets({
    members,
    onCancel
}: AsssigneWalletsProps): React.JSX.Element {
    const [complete, setComplete] = useState<boolean>(false);
    const [receipents, setReceipents] = useState<Receipent[]>([]);
    const columns = [
        { id: 'id', label: 'Id' },
        { id: "MemberName", label: "Name" },
        { id: "Mobile", label: "Mobile" },
        { id: "ewalletname", label: "Name" },
        { id: "points", label: "Points" },
        { id: "status", label: "Status" }
    ]; const [validAfterDay, setValidAfterDay] = useState<string>("0");
    const [validTillDay, setValidTillDay] = useState<string>("0");
    const [membereWallets, setMembereWallets] = useState<eWalletMapping[]>([]);
    const [member, setMember] = useState<Member>();
    const [isOpen, setIsOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [eWalletMaster, seteWalletMaster] = useState<eWallet[]>([]);

    const { showAlert } = useAlert();

    const methods = useForm<eWalletMapping>({
        defaultValues: {
            id: 0,
            eWallet_id: undefined,
            member_id: undefined,
            points: undefined,
            eWallet: undefined
        }
    });

    const onAssign = methods.handleSubmit(async (data: any) => {
        try {
            const payload = { ...data };
            payload.eWallet_id = data?.eWallet?.id
            const response = await (await axiosService.post<eWalletMapping | any>(`${CONSTANTS.ApiConstants.assignEWalletToMany}`, payload))
            if (response.status != 200) {
                setLoading(false);
                setComplete(false);
                showAlert(response.data.message || 'Failed to Save eWallet', 'error');
            } else {
                const json = response.data;
                setLoading(false);
                showAlert('eWallet Assigned Successfully', 'success');
                setComplete(true);
                const _update = receipents.map(r => {
                    r.status = "Success";
                    r.ewalletname = payload.eWallet.name;
                    r.points = payload.points;
                    return r
                });
                setReceipents(_update);

            }
        } catch (ex: any) {
            setComplete(false);
            setLoading(false);
            showAlert(ex.response.data.message || 'Failed to Save eWallet', 'error');
            console.error("Exception Caught", ex)
        }
    })
    const toggleDrawer = (open: boolean) => {
        setIsOpen(open);
    };
    const geteWallets = async () => {
        try {
            const response = await axiosService.get<eWallet[] | any>(`${CONSTANTS.ApiConstants.GENERIC.get[RecordTypes.eWallet]}`)
            if (response.status != 200) {
                showAlert(response?.data?.message ?? 'Failed to get eWallets', 'error');
                seteWalletMaster([])
            } else {
                const json = response.data;

                seteWalletMaster(json);
            }
        } catch (ex: any) {
            seteWalletMaster([])
            showAlert(ex?.response?.data?.message ?? 'Failed to get eWallets', 'error');
            console.error("Exception Caught", ex)
        }
    }
    useEffect(() => {
        geteWallets();
    }, [])
    useEffect(() => {
        methods.setValue("members", members.map(_m => _m.id));
        const toList = members.map((_m: any) => { _m.status = ""; return _m });
        setReceipents(toList);
    }, [members])

    return (!complete ?
        <FormProvider {...methods} >
            <Box component="form" onSubmit={onAssign} key="assignForm">
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                        <Controller
                            name="members"
                            control={methods.control}
                            render={({ field, fieldState }) =>
                                <Typography variant="h5"> Assign eWallets to {members?.length} members.</Typography>
                            } />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Controller
                            name="eWallet"
                            control={methods.control}
                            render={({ field, fieldState }) =>
                                <Autocomplete
                                    onChange={(_, newValue) => field.onChange(newValue)}
                                    value={field.value || null} // Ensure value is a eWallet object or null
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    options={eWalletMaster}
                                    getOptionLabel={(option) => option.name}
                                    loading={loading}
                                    renderInput={(params) => <TextField {...params} label="Select eWallet" variant="outlined" />}
                                />
                            } />
                    </Grid>
                    <Grid item xs={6} sm={6}>
                        <Controller
                            name="points"
                            control={methods.control}
                            render={({ field, fieldState }) => <TextField
                                fullWidth
                                id="points"
                                label="Points"
                                {...field}
                            />} />
                    </Grid>


                    <Grid item xs={12} sm={12}>
                        <Button variant="contained" color="primary" type="submit" onClick={onAssign}>
                            Assign
                        </Button>
                        &nbsp;
                        &nbsp;
                        <Button
                            variant="outlined"
                            color="secondary"
                            onClick={() => { onCancel && onCancel() }}
                        >
                            Cancel
                        </Button>
                    </Grid>

                </Grid>
            </Box>
        </FormProvider> : <Box>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                    <Typography variant="h5">Status</Typography>
                </Grid>

                <Grid item xs={12} sm={12}>
                    <DataTable
                        rows={receipents}
                        columns={columns}
                        showActionDelete={false}
                        showActionExpand={false}
                        showActionEdit={false}
                        showActionView={false}
                        isSelectable={false}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>

                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => { onCancel && onCancel() }}
                    >
                        Close
                    </Button>
                </Grid>
            </Grid>
        </Box>)

}