import React from 'react';
import { Button, Card, CardActions, CardContent, FormControl, Grid, InputLabel, Stack, TextField } from "@mui/material";
import { Image as ImageIcon } from '@phosphor-icons/react/dist/ssr/Image';
import { Controller, FormProvider, useFormContext } from 'react-hook-form';
import { RecordActions } from '../../../types/enum';
import { useParams } from 'react-router-dom';
import { FormProp } from '../../../types/formprop-interface';
import ImageUpload from '../../../components/ImageUpload';
import { MuiColorInput } from 'mui-color-input';

export const AppSettings = ({ action, id }: FormProp) => {
    const methods = useFormContext();
    return (
        <Grid container spacing={2}>
            <Grid item xs={6}>
                <Controller
                    name="AppLogo"
                    control={methods.control}
                    render={({ field, fieldState }) =>
                        <ImageUpload onImageChange={(url: string | null) => { console.log("2222"); field.onChange(url) }} imageUrl={field.value} mode={action} name="AppLogo" label="App Logo" />
                    }
                />

            </Grid>

            <Grid item xs={6}>
                <Controller
                    name="HeaderLogo"
                    control={methods.control}
                    render={({ field, fieldState }) =>
                        <ImageUpload onImageChange={(url: string | null) => { console.log("2222"); field.onChange(url) }} imageUrl={field.value} mode={action} name="HeaderLogo" label="Header Logo" />
                    }
                />
            </Grid>
            <Grid item xs={6} sm={4}>
                <Controller
                    name="HeaderColorCode"
                    control={methods.control}
                    render={({ field, fieldState }) =>
                        <Stack>
                            <InputLabel>Header ColorCode</InputLabel>
                            <MuiColorInput format="hex" {...field} />
                        </Stack>
                    } />
            </Grid>
            <Grid item xs={6} sm={4}>
                <Controller
                    name="FooterColorCode"
                    control={methods.control}
                    render={({ field, fieldState }) =>
                        <Stack>
                            <InputLabel>Footer ColorCode</InputLabel>
                            <MuiColorInput format="hex" {...field} />
                        </Stack>
                    } />
            </Grid>
            <Grid item xs={6} sm={4}>
                <Controller
                    name="FooterButtonColor"
                    control={methods.control}
                    render={({ field, fieldState }) =>
                        <Stack>
                            <InputLabel>Footer ButtonColor</InputLabel>
                            <MuiColorInput format="hex" {...field} />
                        </Stack>
                    } />
            </Grid>
            <Grid item xs={6} sm={4}>
                <Controller
                    name="FooterActiveButtonColor"
                    control={methods.control}
                    render={({ field, fieldState }) =>
                        <Stack>
                            <InputLabel>Footer ActiveButtonColor</InputLabel>
                            <MuiColorInput format="hex" {...field} />
                        </Stack>
                    } />
            </Grid>
            <Grid item xs={6} sm={4}>
                <Controller
                    name="ColorCodeButton"
                    control={methods.control}
                    render={({ field, fieldState }) =>
                        <Stack>
                            <InputLabel>ColorCode Button</InputLabel>
                            <MuiColorInput format="hex" {...field} />
                        </Stack>
                    } />
            </Grid>
            <Grid item xs={6} sm={4}>
                <Controller
                    name="ColorCodeStoreTheme"
                    control={methods.control}
                    render={({ field, fieldState }) =>
                        <Stack>
                            <InputLabel>ColorCode StoreTheme</InputLabel>
                            <MuiColorInput format="hex" {...field} />
                        </Stack>
                    } />
            </Grid>
            <Grid item xs={6} sm={6}>
                <Controller
                    name="MemberCard"
                    control={methods.control}
                    render={({ field, fieldState }) =>
                        <ImageUpload onImageChange={(url: string | null) => { field.onChange(url) }} imageUrl={field.value} mode={action} name="MemberCard" label="Member Card" />
                    }
                />
            </Grid>
        </Grid>)
}