
import * as React from 'react';
import { useSelection } from '../../hooks/use-selection';
import DataTable from '../../components/DataTable';
import { CONSTANTS } from '../../constants';
import axiosService from '../../services/axios';
import { useAlert } from '../../components/CommonAlert';
import DeleteConfirmationDialog from '../../components/ConfirmationDialog';
import { useNavigate } from 'react-router-dom';
import { DataType } from 'src/types/enum';
import { Transactions } from 'src/intefaces/transactions.interface';


function noop(): void {
  // do nothing
}



interface TransactionsTableProps {
  count?: number;
  page?: number;
  rows?: Transactions[];
  rowsPerPage?: number;
  onDeleteCallback?: () => any
  onRowsSelected?: (rows: any) => any
}

export function TransactionsTable({
  count = 0,
  rows = [],
  page = 0,
  rowsPerPage = 0,
  onDeleteCallback,
  onRowsSelected
}: TransactionsTableProps): React.JSX.Element {
  const rowIds = React.useMemo(() => {
    return rows.map((transaction) => transaction.id);
  }, [rows]);
  const navigate = useNavigate();
  const { selectAll, deselectAll, selectOne, deselectOne, selected } = useSelection(rowIds);
  const { showAlert } = useAlert();
  const selectedSome = (selected?.size ?? 0) > 0 && (selected?.size ?? 0) < rows.length;
  const [selectedId, setSelectedId] = React.useState<number | undefined>(undefined);
  const [openDeleteDlg, setOpenDeleteDlg] = React.useState<boolean>(false);
  const [seletedTransactions, setSeletedTransactions] = React.useState<Transactions[]>([]);

  const columns = [
    { id: 'id', label: 'Id' },
    { id: "txn_date", label: "Date", type: DataType.datetime },
    { id: "ref", label: "Description" },
    { id: "member_name", label: "Member" },
    { id: "Mobile", label: "Mobile" },
    { id: "qty", label: "Quantity" },
    { id: "action", label: "Action" },
    { id: "description", label: "Ref" }
  ];

 

  return (<div>
    <DataTable
      rows={rows}
      columns={columns}
      showActionDelete={false}
      showActionEdit={false}
      showActionView={false}
      expandComponent={(row) => <></>}
      showActionExpand={false}
      expandButtonIcon='LocalOffer'
      onRowsSelected={onRowsSelected}
      onDelete={() => { }}
      onEdit={() => { }}
      onView={() => { }}
    />    
  </div>)
}
