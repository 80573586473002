import React, { useEffect } from 'react';
import { Button, Paper, Stack, Typography } from '@mui/material';
import { useMerchant } from 'src/context/MerchantContext';
interface BusinessInfoProps {
    onComplete?: () => any;
}

const MerchantTerms: React.FC<BusinessInfoProps> = ({ onComplete }) => {
    const { merchant, setBackButton } = useMerchant();
    useEffect(() => {
        setBackButton && setBackButton(true);
    }, [])
    return merchant?.TermnPolicy && merchant.TermnPolicy != '<p><br></p>' ? <Paper square={true} elevation={0} sx={{
        p: 3, '& .business-desc img': {
            maxWidth: "100%"
        }
    }}>
        <h3>The Terms & Policies</h3>
        <br />
        <Typography className='business-desc' variant="body2" dangerouslySetInnerHTML={{ __html: merchant.TermnPolicy }} sx={{ overflow: "hidden" }} />
    </Paper > : <></>
};

export default MerchantTerms;
