import React, { useState } from 'react';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    TablePagination, IconButton, Paper, Box, Tooltip, Collapse, Button, TableSortLabel,
    Checkbox,
    Avatar
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { format } from 'date-fns';
import { DataType } from '../types/enum';
import { Download as DownloadIcon } from '@mui/icons-material';
import * as Icons from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

interface RowData {
    id: number;
    [key: string]: any; // Allows for additional dynamic properties
}

interface DataTableProps {
    rows: RowData[];
    columns: { id: string; label: string; type?: string }[];
    onEdit?: (id: number, row?: any) => void;
    onDelete?: (id: number, row?: any) => void;
    onView?: (id: number, row?: any) => void;
    rowsPerPageOptions?: number[];
    expandComponent?: (row: RowData) => React.ReactNode;
    expandButtonLabel?: string;
    expandButtonIcon?: string;
    showActionEdit?: boolean;
    showActionDelete?: boolean;
    showActionView?: boolean;
    showActionExpand?: boolean;
    onRowsSelected?: (rows: RowData[]) => any;
    isSelectable?: boolean;
}

const formatLocalDateTime = (utcDate: string) => {
    if (!utcDate) return null;
    const date = new Date(utcDate);
    return format(date, 'Pp'); // Example: '07/27/2024, 12:00 PM'
};

const downloader = (url: string) => (
    <Tooltip title="Download">
        <IconButton color="primary" onClick={() => window.open(url, '_blank')}>
            <DownloadIcon />
        </IconButton>
    </Tooltip>
);

const avatar = (url: string) => (
    <Tooltip title="Download">
        <Avatar
            variant="rounded"
            color="primary"
            src={url}
            onClick={() => window.open(url, '_blank')}
            sx={{
                '& img': {
                    objectFit: 'contain',
                },
                width: 50,
                height: 50,
            }}
        />
    </Tooltip>
);

const formatLocalDate = (utcDate: string) => {
    if (!utcDate) return null;
    const date = new Date(utcDate);
    return format(date, 'P'); // Example: '07/27/2024'
};

const DataTable: React.FC<DataTableProps> = ({
    rows,
    columns,
    onEdit,
    onDelete,
    onView,
    rowsPerPageOptions = [25, 50, 100],
    expandComponent,
    expandButtonLabel,
    expandButtonIcon,
    showActionDelete = true,
    showActionEdit = true,
    showActionView = true,
    showActionExpand = false,
    onRowsSelected,
    isSelectable
}) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);
    const [expandedRows, setExpandedRows] = useState<Set<number>>(new Set());
    const [selectedRows, setSelectedRows] = useState<number[]>([]); // Track selected rows
    const [order, setOrder] = useState<'asc' | 'desc'>('asc');
    const [orderBy, setOrderBy] = useState<string | null>(null);

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleExpandRow = (id: number) => {
        const newExpandedRows = new Set(expandedRows);
        if (expandedRows.has(id)) {
            newExpandedRows.delete(id);
        } else {
            newExpandedRows.add(id);
        }
        setExpandedRows(newExpandedRows);
    };

    const handleSelectRow = (id: number) => {
        setSelectedRows((prevSelectedRows) => {
            const newRows = prevSelectedRows.includes(id)
                ? prevSelectedRows.filter((rowId) => rowId !== id)
                : [...prevSelectedRows, id];
            onRowsSelected && onRowsSelected(paginatedRows.filter(p => newRows.includes(p.id)));
            return newRows;
        }
        );

    };

    const handleSelectAllRows = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const allRowIds = rows.map((row) => row.id);
            setSelectedRows(allRowIds);
            onRowsSelected && onRowsSelected(paginatedRows);
        } else {
            setSelectedRows([]);
            onRowsSelected && onRowsSelected([]);
        }
    };

    const isRowSelected = (id: number) => selectedRows.includes(id);

    const handleRequestSort = (property: string) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const sortedRows = React.useMemo(() => {
        if (!orderBy) return rows;

        return [...rows].sort((a, b) => {
            const aValue = a[orderBy];
            const bValue = b[orderBy];

            if (aValue == null || bValue == null) return 0;

            if (typeof aValue === 'string' || typeof bValue === 'string') {
                return (order === 'asc' ? 1 : -1) * aValue.localeCompare(bValue);
            } else {
                return (order === 'asc' ? 1 : -1) * (aValue > bValue ? 1 : -1);
            }
        });
    }, [rows, order, orderBy]);

    const paginatedRows = sortedRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    return (
        <Box sx={{ p: 2 }}>
            <TableContainer>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            {isSelectable && <TableCell padding="checkbox">
                                <Checkbox
                                    indeterminate={
                                        selectedRows.length > 0 && selectedRows.length < rows.length
                                    }
                                    checked={selectedRows.length === rows.length}
                                    onChange={handleSelectAllRows}
                                    inputProps={{ 'aria-label': 'select all rows' }}
                                />
                            </TableCell>}
                            {columns.map((column) => (
                                <TableCell key={column.id}>
                                    <TableSortLabel
                                        active={orderBy === column.id}
                                        direction={orderBy === column.id ? order : 'asc'}
                                        onClick={() => handleRequestSort(column.id)}
                                    >
                                        {column.label}
                                    </TableSortLabel>
                                </TableCell>
                            ))}
                            {(showActionDelete || showActionEdit || showActionView || showActionExpand) && (
                                <TableCell>Actions</TableCell>
                            )}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {paginatedRows.map((row, index) => (
                            <React.Fragment key={index}>
                                <TableRow key={'tr_' + index} selected={isRowSelected(row.id)}>
                                    {isSelectable && <TableCell padding="checkbox">
                                        <Checkbox
                                            checked={isRowSelected(row.id)}
                                            onChange={() => handleSelectRow(row.id)}
                                        />
                                    </TableCell>}
                                    {columns.map((column) => (
                                        <TableCell key={column.id}>
                                            {(column?.type ?? '').toLowerCase() === DataType.date
                                                ? formatLocalDate(row[column.id])
                                                : (column?.type ?? '').toLowerCase() === DataType.datetime
                                                    ? formatLocalDateTime(row[column.id])
                                                    : (column?.type ?? '').toLowerCase() === DataType.file
                                                        ? downloader(row[column.id])
                                                        : (column?.type ?? '').toLowerCase() === DataType.image
                                                            ? avatar(row[column.id])
                                                            : (column?.type ?? '').toLowerCase() === DataType.boolean
                                                                ? row[column.id] ? "Yes" : "No"
                                                                : row[column.id]}
                                        </TableCell>
                                    ))}
                                    {(showActionDelete ||
                                        showActionEdit ||
                                        showActionView ||
                                        showActionExpand) && (
                                            <TableCell sx={{ minWidth: '160px' }}>
                                                {showActionEdit && (
                                                    <IconButton onClick={() => onEdit && onEdit(row.id, row)} color="primary">
                                                        <EditIcon />
                                                    </IconButton>
                                                )}
                                                {showActionExpand && (
                                                    <IconButton onClick={() => handleExpandRow(row.id)}>
                                                        {expandedRows.has(row.id) ? (
                                                            expandButtonLabel ? (
                                                                <Button
                                                                    variant="outlined"
                                                                    startIcon={<ExpandLessIcon />}
                                                                >
                                                                    {expandButtonLabel}
                                                                </Button>
                                                            ) : (
                                                                <ExpandLessIcon />
                                                            )
                                                        ) : expandButtonLabel ? (
                                                            <Button
                                                                variant="outlined"
                                                                startIcon={
                                                                    expandButtonIcon
                                                                        ? React.createElement(
                                                                            Icons[
                                                                            expandButtonIcon as keyof typeof Icons
                                                                            ],
                                                                            { color: 'primary' }
                                                                        )
                                                                        : <ExpandMoreIcon color="primary" />
                                                                }
                                                            >
                                                                {expandButtonLabel}
                                                            </Button>
                                                        ) : expandButtonIcon ? (
                                                            React.createElement(
                                                                Icons[expandButtonIcon as keyof typeof Icons],
                                                                { color: 'primary' }
                                                            )
                                                        ) : (
                                                            <ExpandMoreIcon color="primary" />
                                                        )}
                                                    </IconButton>
                                                )}
                                                {showActionDelete && (
                                                    <IconButton
                                                        onClick={() => onDelete && onDelete(row.id, row)}
                                                        color="secondary"
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                )}
                                            </TableCell>
                                        )}
                                </TableRow>
                                <TableRow key={'tr2_' + index}>
                                    <TableCell
                                        style={{ paddingBottom: 0, paddingTop: 0, background: "#f3f3f3" }}
                                        colSpan={columns.length + 2}
                                    >
                                        <Collapse
                                            in={expandedRows.has(row.id)}
                                            timeout="auto"
                                            unmountOnExit
                                            key={row.id}
                                        >
                                            <Box key={`ex${row.id}`}>
                                                {expandComponent ? expandComponent(row) : <></>}
                                            </Box>
                                        </Collapse>
                                    </TableCell>
                                </TableRow>
                            </React.Fragment>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={rowsPerPageOptions}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Box>
    );
};

export default DataTable;
