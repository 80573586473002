'use client';

import * as React from 'react';
import Box from '@mui/material/Box';

import { Button, Typography } from '@mui/material';
import { Merchant } from '../../../types/merchant-interface';
import { FormProvider, useForm } from 'react-hook-form';
import { useAlert } from '../../../components/CommonAlert';
import axiosService from '../../../services/axios';
import { useNavigate } from 'react-router-dom';
import { RecordActions, RecordTypes } from '../../../types/enum';
import { FormProp } from '../../../types/formprop-interface';
import { MerchantForm } from './form';
import { useState } from 'react';
import { CONSTANTS } from '../../../constants';


export function MerchantFormWrapper({ action, id, recordType = "merchant" }: FormProp): React.JSX.Element {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const { showAlert } = useAlert();
  const methods = useForm<any>({
    defaultValues: {
      "MerchantCode": "",
      "MerchantName": "",
      "MerchantSubTitle": "",
      "MerchantSMSHeader": "",
      "MerchantAddress": "",
      "MerchantPhone1": "",
      "MerchantPhone2": "",
      "MerchantEmail": "",
      "MerchantIntro": "",
      "MerchantDescription": "",
      "MerchantLatitude": "",
      "MerchantLongitude": "",
      "MerchantLogo": "",
      "MerchantCover": "",
      "AppLogo": "",
      "HeaderColorCode": "",
      "HeaderLogo": "",
      "FooterColorCode": "",
      "FooterButtonColor": "",
      "FooterActiveButtonColor": "",
      "ColorCodeButton": "",
      "ColorCodeStoreTheme": "",
      "NoticeBoardFontsizeTitle": "",
      "NoticeBoardFontsizeDescription": "",
      "NoticeBoardFontsizeTag": "",
      "RegistrationThankYouMessage": "",
      "RegistrationThankYouImage": "",
      "UrlPlaystore": "",
      "UrlAppstore": "",
      "UrlAppGallery": "",
      "FunctionBranches": false,
      "FunctionPartner": false,
      "FunctionProduct": false,
      "FunctionService": false,
      "FunctionPOS": false,
      "FunctionStore": false,
      "FunctionOrdering": false,
      "FunctionBooking": false,
      "FunctionMessage": false,
      "FunctionNoticeboard": false,
      "FunctionDirectory": false,
      "FunctionGallery": false,
      "FunctionGame": false,
      "FunctionMembershipRewardCoupon": false,
      "FunctionMembershipRewardeWallet": false,
      "FunctionMembershipRewardVIPCard": false,
      "FunctionMembershipRewardChopCard": false,
      "FunctionMembershipRewardUnlimited": false,
      "FunctionMembershipRewardRedemption": false,
      "FunctionMembershipRewardAngpao": false,
      "FunctionMembershipRewardSpinWin": false,
      "FunctionMembershipRewardReferral": false,
      "NumberofCoupon": "",
      "NumberofeWallet": "",
      "NumberofVIPCard": "",
      "NumberofChopCard": "",
      "NumberofUnlimited": "",
      "NumberofRedemption": "",
      "NumberofAngpao": "",
      "NumberofSpinWin": "",
      "NumberofBranches": "",
      "NumberofProduct": "",
      "NumberofService": "",
      "NumberofOrdering": "",
      "NumberofDirectory": "",
      "NumberofGallery": "",
      "WhatsappAppkey": "",
      "WhatsappAuthkey": "",
      "MetaAPIKey": "",
      "MetaAuthKey": "",
      "PayerID": "",
      "PayerKey": "",
      "StripePublicKey": "",
      "StripeSecreteKey": "",
      "OTPMode": "",
      "TermnPolicy": "",
      "Share2EarnDescription": "",
      "open_mon": "",
      "open_tue": "",
      "open_wed": "",
      "open_thus": "",
      "open_fri": "",
      "open_sat": "",
      "open_sun": "",
      "open_mon_from": "",
      "open_tue_from": "",
      "open_wed_from": "",
      "open_thus_from": "",
      "open_fri_from": "",
      "open_sat_from": "",
      "open_sun_from": "",
      "open_mon_to": "",
      "open_tue_to": "",
      "open_wed_to": "",
      "open_thus_to": "",
      "open_fri_to": "",
      "open_sat_to": "",
      "open_sun_to": "",
      "MsgEmailPassword": "",
      "MsgEmailId": "",
      "MsgEmailPort": "",
      "MsgEmailHost": "",
      "MemberCard":""
    }
  });
  const createNewMerchant = async (data: any) => {
    try {
      const response = await axiosService.post<Merchant | any>(`${CONSTANTS.ApiConstants.GENERIC.post[recordType]}`, data)
      if (response.status != 200) {
        setLoading(false);
        showAlert(response?.data?.message ?? `Failed to save ${recordType}`, 'error');
      } else {
        const json = response.data;
        setLoading(false);
        showAlert('Created Successfully', 'success');
        navigate(`/${recordType == RecordTypes.merchant ? "merchants" : recordType}/edit/${response.data.id}`)
      }
    } catch (ex: any) {
      showAlert(ex?.response?.data?.message ?? `Failed to save ${recordType}`, 'error');
      setLoading(false);
      console.error("Exception Caught", ex)
    }
  }
  const editMerchant = async (data: any) => {
    try {
      const response = await axiosService.put<Merchant | any>(`${CONSTANTS.ApiConstants.GENERIC.put[recordType]}/${id}`, data)
      if (response.status != 200) {
        setLoading(false);
        showAlert(response?.data?.message ?? `Failed to save ${recordType}`, 'error');
      } else {
        const json = response.data;
        setLoading(false);
        showAlert('Updated successfully', 'success');
      }
    } catch (ex: any) {
      setLoading(false);
      showAlert(ex?.response?.data?.message ?? `Failed to save ${recordType}`, 'error');
      console.error("Exception Caught", ex)
    }
  }

  const getMerchantDetails = async () => {
    try {
      const response = await axiosService.get<Merchant[] | any>(`${CONSTANTS.ApiConstants.GENERIC.getById[recordType]}/${id}`)
      if (response.status != 200) {
        showAlert(response?.data?.message ?? 'Failed to Retrive Details', 'error');
      } else {
        const json = response.data;
        methods.reset(json);
      }
    } catch (ex) {
      showAlert('Failed to Retrive  Details', 'error');
      console.error("Exception Caught", ex)
    }
  }
  const onSubmit = methods.handleSubmit(async (data: any) => {
    try {
      setTimeout(() => {
        if (loading) {
          return
        }
        setLoading(true);

        if (action == RecordActions.add) {
          createNewMerchant(data);

        } else if (action == RecordActions.edit) {
          editMerchant(data);
        }
      });

    }
    catch (ex) {
      setLoading(false);
    } finally {

    }


  });
  React.useEffect(() => {
    if (action == RecordActions.edit || action == RecordActions.view) {
      getMerchantDetails();
    }
  }, [])
  return (
    <Box sx={{ width: 'calc(100vw - 300px)', bgcolor: 'background.paper' }}>

      <FormProvider {...methods} >
        <Box component="form" onSubmit={onSubmit} sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <MerchantForm action={action} id={id} recordType={recordType} />
          {action != RecordActions.view && <Box
            sx={{
              position: 'fixed',
              bottom: 0,
              left: 0,
              zIndex: 9,
              width: '100%',
              backgroundColor: '#f5f5f5',
              padding: 2,
              display: 'flex',
              justifyContent: 'flex-end', // Align the button to the right
              boxShadow: '0px -1px 5px rgba(0,0,0,0.1)', // Optional shadow
            }}
          >
            {action != RecordActions.add && <div> <Button href={"/" + methods.getValues('id')} target="_blank"  >Preview</Button>
              &nbsp;
              &nbsp;</div>

            }
            <Button disabled={loading} variant="contained" color="primary" type="submit">
              Save
            </Button>
          </Box>
          }
        </Box>
      </FormProvider>
    </Box>
  );
}
