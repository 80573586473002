
import * as React from 'react';
import { useSelection } from '../../hooks/use-selection';
import { Member } from '../../types/member-interface';
import DataTable from '../../components/DataTable';
import { CONSTANTS } from '../../constants';
import axiosService from '../../services/axios';
import { useAlert } from '../../components/CommonAlert';
import DeleteConfirmationDialog from '../../components/ConfirmationDialog';
import { useNavigate } from 'react-router-dom';
import { MemberCoupons } from './member-coupons';
import { Paper } from '@mui/material';


function noop(): void {
  // do nothing
}



interface MembersTableProps {
  count?: number;
  page?: number;
  rows?: Member[];
  rowsPerPage?: number;
  onDeleteCallback?: () => any
  onRowsSelected?: (rows: any) => any
}

export function MembersTable({
  count = 0,
  rows = [],
  page = 0,
  rowsPerPage = 0,
  onDeleteCallback,
  onRowsSelected
}: MembersTableProps): React.JSX.Element {
  const rowIds = React.useMemo(() => {
    return rows.map((member) => member.id);
  }, [rows]);
  const navigate = useNavigate();
  const { selectAll, deselectAll, selectOne, deselectOne, selected } = useSelection(rowIds);
  const { showAlert } = useAlert();
  const selectedSome = (selected?.size ?? 0) > 0 && (selected?.size ?? 0) < rows.length;
  const [selectedId, setSelectedId] = React.useState<number | undefined>(undefined);
  const [openDeleteDlg, setOpenDeleteDlg] = React.useState<boolean>(false);
  const [seletedMembers, setSeletedMembers] = React.useState<Member[]>([]);

  const columns = [
    { id: 'id', label: 'Id' },
    { id: "id", label: "UID" },
    { id: "MemberName", label: "Name" },
    { id: "Avatar", label: "Avatar", type: "image" },
    { id: "Mobile", label: "Mobile" },
    { id: "DOB", label: "DOB", type: "date" },
    { id: "Email", label: "Email" },
    { id: "IdNumber", label: "ID Number" },
    { id: "Gender", label: "Gender" },
    { id: "MemberType", label: "Member Type" },
    { id: "Upline", label: "Upline" }
  ];
  const handleEdit = (id: number) => {
    navigate(`/members/edit/${id}`);
  };

  const handleDelete = async (id: number) => {
    setSelectedId(id);
    setOpenDeleteDlg(true);
  };

  const handleView = (id: number) => {
    navigate(`/members/view/${id}`);
  };
  const onDeleteConfirm = async () => {
    try {
      setOpenDeleteDlg(false)
      const response = await axiosService.delete<Member[]>(`${CONSTANTS.ApiConstants.deleteMember}/${selectedId}`);
      if (response.status != 200) {
        showAlert('Failed to Delete Merchant', 'error');
      } else {
        showAlert('Merchant Deleted Successfully', 'success');
        onDeleteCallback && onDeleteCallback();
      }
    } catch (ex) {
      showAlert('Failed to Delete Merchant', 'error');
      console.error("Exception Caught", ex)
    } finally {
      setSelectedId(undefined);

    }
  }

  return (<div>
    <Paper square={true}> <DataTable
      rows={rows}
      columns={columns}
      onEdit={handleEdit}
      onDelete={handleDelete}
      onView={handleView}
      expandComponent={(row) => <MemberCoupons key={row?.id} member={row as Member} />}
      showActionExpand={true}
      expandButtonIcon='LocalOffer'
      onRowsSelected={onRowsSelected}
      isSelectable={true}
    /></Paper>
    <DeleteConfirmationDialog
      open={openDeleteDlg}
      onClose={() => { setOpenDeleteDlg(false); setSelectedId(undefined) }}
      onConfirm={onDeleteConfirm}
      confirmLabel={"Delete"}
      cancelLabel={"Cancel"}
      message={"Are you sure you want to delete merchant"}
      title={"Confirm Delete"}
    />
  </div>)
}
