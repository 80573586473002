import React, { useEffect, useState } from 'react';
import { Box, Container, Grid, Typography, Paper, Avatar, IconButton, Stack } from '@mui/material';
import { CONSTANTS } from 'src/constants';
import axiosService from 'src/services/axios';
import { Merchant } from 'src/types/merchant-interface';
import { useNavigate, useParams } from 'react-router-dom';
import BusinessInfo from './business-info';
import OperatingHours from './operating-hours';
import ContactInfo from './contact-info';
import Gallery from './image-gallery';
import Map from './google-map-view';
import OutletList from './outlet-list';
import { HeaderImage } from './header-image';
import { useMerchant } from 'src/context/MerchantContext';


const AboutUs: React.FC = () => {
    const [merchant, setMerchant] = useState<Merchant>();
    const [outlets, setOutlets] = useState<Merchant[]>();
    const { merchantid } = useParams();
    const navigate = useNavigate();
    const { setBackButton } = useMerchant()
    useEffect(() => {
        setMerchant(undefined)
        setOutlets(undefined)
        getMerchantDetails();
        getMerchantOutlets();
    }, [merchantid])
    const getMerchantDetails = async () => {
        try {
            const response = await axiosService.get<Merchant>(`${CONSTANTS.ApiConstants.getMerchantProfile}/${merchantid}`)
            if (response.status != 200) {
                console.log('Failed to Retrive Member Details', 'error');
            } else {
                setBackButton && setBackButton(!!response?.data?.parentid)
                setMerchant(response?.data);
            }
        } catch (ex) {
            console.error("Exception Caught", ex)
        }
    }
    const getMerchantOutlets = async () => {
        try {
            const response = await axiosService.get<Merchant[]>(`${CONSTANTS.ApiConstants.getMerchantOutlets}/${merchantid}`)
            if (response.status != 200) {
                console.log('Failed to Retrive Member Details', 'error');
            } else {
                setOutlets(response?.data);
            }
        } catch (ex) {
            console.error("Exception Caught", ex)
        }
    }
    return (<Container sx={{ p: 0 }} maxWidth="sm">

        {merchant && <Box sx={{pb:3}}><HeaderImage merchant={merchant} /></Box>}
        {merchant && <Box sx={{pb:3}}><BusinessInfo merchant={merchant} /></Box>}
        {merchant && <Box sx={{pb:3}}><ContactInfo merchant={merchant} /> </Box>}
        {merchant && <Box sx={{pb:3}}><OperatingHours merchant={merchant} /></Box>}
        {merchant && <Box sx={{pb:3}}><Gallery merchant={merchant} /></Box>}
        {merchant && <Box sx={{pb:3}}><OutletList merchant={merchant} outlets={outlets ?? []} /></Box>}

    </Container>)
};

export default AboutUs;
