
import { TransferWithinAStation as TransferIcon, Payment as PayIcon, Receipt as TransactionIcon } from '@mui/icons-material';
import Slider from 'react-slick';
import React, { useEffect, useRef, useState } from 'react';
import { Tabs, Tab, Box, Typography, IconButton, ListItem, Drawer, Card, CardMedia, Avatar, Stack, Button, Paper, TextField, CircularProgress, Dialog, DialogActions, DialogContent, Grid } from '@mui/material';
import { styled } from '@mui/system';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { CONSTANTS } from 'src/constants';
import axiosService from 'src/services/axios';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { useMerchant } from 'src/context/MerchantContext';
import { eWallet, eWalletMapping } from 'src/intefaces/eWallet.interface';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DangerousIcon from '@mui/icons-material/Dangerous';
import { keyframes } from '@mui/system';


import './ewallet-page.css';
import moment from 'moment';
import { RecordTypes } from 'src/types/enum';
import { useAuth } from 'src/context/AuthContext';
import { Member } from 'src/types/member-interface';
import { useAlert } from 'src/components/CommonAlert';
import CreditCard from './CreditCard';
import { TransfereWallet } from './TransferEwallet';
import MTransactionList from './mtransacations';
const bounceAnimation = keyframes`
  0% { transform: scale(0); }
  50% { transform: scale(1.1); }
  100% { transform: scale(1); }
`;



const MobileEWalletView: React.FC = () => {
    const [selectedWallet, setSelectedWallet] = React.useState<eWalletMapping | undefined>();
    const navigate = useNavigate();
    const [membereWalletMap, setMembereWalletMap] = useState<eWalletMapping[]>([]);
    const { merchantid } = useParams()
    const { merchant, setBackButton } = useMerchant();
    const [eWalletAction, seteWalletAction] = useState<"transfer" | "pay" | "transations" | undefined>(undefined);
    const [scannedMember, setScannedMember] = useState<Member | undefined>(undefined);
    const [payComplete, setPayComplete] = useState<"Success" | "Wrong Password" | "Failed to Pay" | "Insufficient Balance" | undefined>(undefined);
    const [loadingeWallets, setLoadingeWallets] = useState<boolean>(true);
    const [uTUsername, setUTUsername] = useState<string>("");
    const [uTPassword, setUTPassword] = useState<string>("");
    const [refresh, setRefresh] = useState<boolean>(false)
    const location = useLocation();
    const sliderRef = useRef<Slider | null>(null);
    const { isAuthenticated } = useAuth();

    const { showAlert } = useAlert();
    const geteWallets = async () => {
        try {
            setLoadingeWallets(true)
            const response = await axiosService.get<eWalletMapping[] | any>(`${CONSTANTS.ApiConstants.GENERIC.get[RecordTypes.eWalletMapping]}`)
            if (response.status != 200) {
                setLoadingeWallets(false)
                setMembereWalletMap([])
            } else {
                setLoadingeWallets(false)
                const json = response.data;
                setMembereWalletMap(json);
                const selectedIndex = json.findIndex((_: eWalletMapping) => _.id == selectedWallet?.id);
                setSelectedWallet(json[0]);
                setTimeout(() => goToSlide(selectedIndex > -1 ? selectedIndex : 0), 100)

            }
        } catch (ex: any) {
            setLoadingeWallets(false)
            setMembereWalletMap([])
            console.error("Exception Caught", ex)
        }
    }
    const goToSlide = (index: number) => {
        if (sliderRef.current && membereWalletMap) {
            const selectedIndex = membereWalletMap.findIndex((_: eWalletMapping) => _.id == selectedWallet?.id);
            sliderRef.current.slickGoTo(index);
            setRefresh(true);
        }
    }

    const postPay = async (e: any) => {
        e.preventDefault();
        try {
            const payload = {
                password: uTPassword,
                eWalletMappingId: selectedWallet?.id,
                merchantid: merchant?.id
            }
            const response: any = await axiosService.post<any>(`${CONSTANTS.ApiConstants.payEwalletPoints}`, payload)
            if (response.status != 200) {
                setPayComplete(response.message ?? "Failed to Pay");
            } else {
                setPayComplete("Success");
                //showAlert("Transfer Successful", "success");               

            }

        } catch (ex: any) {
            if (ex.response.status == 401) {
                setPayComplete(ex.response?.data?.message ?? "Wrong Password");
            }
            else {
                setPayComplete(ex.response?.data?.message ?? "Failed to Pay");
            }
        }
    }

    const sliderSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 3000,
        arrows: false,
        nextArrow: undefined
    };

    useEffect(() => {
        geteWallets()
        setBackButton && setBackButton(false);
    }, [])
    // useEffect(() => {
    //     if (selectedWallet) {
    //         eWalletTransferMode && startScan();
    //     } else {
    //         stopScan();
    //     }
    // }, [selectedWallet])
    const closeTransferWindow = () => {
        if (payComplete == "Success") {
            setScannedMember(undefined)
            setUTPassword("")
            setUTUsername("")
            seteWalletAction(undefined)
            geteWallets();
        } else if (payComplete == "Wrong Password") {
            setPayComplete(undefined);
        } else {
            setScannedMember(undefined);
            setPayComplete(undefined);

        }
    }

    const PayeWallet = () => {
        return <Dialog fullWidth sx={{ textAlign: "center" }} open={eWalletAction == "pay"} onClose={() => seteWalletAction(undefined)} disableScrollLock={true} >

            <DialogContent>
                {payComplete ?
                    <Box>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                mb: 3,
                            }}
                        >
                            {payComplete == "Success" ?
                                <CheckCircleIcon
                                    sx={{
                                        fontSize: '4rem',
                                        color: '#4caf50',
                                        animation: `${bounceAnimation} 0.5s ease-in-out forwards`,
                                    }}
                                /> : <DangerousIcon
                                    sx={{
                                        fontSize: '4rem',
                                        color: '#ff0000',
                                        animation: `${bounceAnimation} 0.5s ease-in-out forwards`,
                                    }}
                                />}

                        </Box>
                        <Typography variant="body1" gutterBottom>
                            {payComplete}
                        </Typography>
                        <DialogActions>
                            <Button size="large" fullWidth type="button" color="primary" variant="contained" onClick={() => closeTransferWindow()}>
                            <h3 style={{ fontWeight: "normal" }}>Ok</h3>
                            </Button>


                        </DialogActions>
                    </Box>
                    :
                    <form onSubmit={postPay}>
                        <TextField
                            onChange={(e) => { setUTPassword(e.target.value) }}
                            fullWidth
                            id="password"
                            label="Enter Your Password"
                        />
                        <DialogActions>
                            <Button size="large" fullWidth type="submit" color="primary" variant="contained">
                                <h3 style={{ fontWeight: "normal" }}>Ok</h3>
                            </Button>
                            <Button size="large" fullWidth onClick={() => seteWalletAction(undefined)} color="secondary">
                                <h3 style={{ fontWeight: "normal" }}>Cancel</h3>
                            </Button>
                        </DialogActions>

                    </form>
                }
            </DialogContent>
        </Dialog>
    }

    const onWalletChange = (index: number) => {
        setSelectedWallet(membereWalletMap[index]);
    }
    const getActions = () => {
        const action = []
        selectedWallet?.eWallet?.allowTransfer && action.push(<Button color="inherit" variant="text" onClick={() => seteWalletAction("transfer")}> <Stack sx={{ alignItems: "center", minWidth: "85px" }}>
            <Avatar variant='square' src={(selectedWallet && selectedWallet?.eWallet?.transferImg) ?? ""} sx={{ width: "60px", height: "60px" }}></Avatar>
            <h4 style={{ fontWeight: "normal", textTransform: "capitalize" }}>Transfer</h4>
        </Stack>
        </Button>
        );

        selectedWallet?.eWallet?.allowPay && action.push(< Button color="inherit" variant="text" onClick={() => seteWalletAction("pay")}>
            <Stack sx={{ alignItems: "center", minWidth: "85px" }} >
                <Avatar variant='square' src={(selectedWallet && selectedWallet?.eWallet?.payImg) ?? ""} sx={{ width: "60px", height: "60px" }}></Avatar>
                <h4 style={{ fontWeight: "normal", textTransform: "capitalize" }}>Pay</h4>
            </Stack>
        </Button >);

        action.push(<Button color="inherit" variant="text" onClick={() => seteWalletAction("transations")}>
            <Stack sx={{ alignItems: "center", minWidth: "85px" }}>
                <Avatar variant='square' src={(selectedWallet && selectedWallet?.eWallet?.transactionImg) ?? ""} sx={{ width: "60px", height: "60px" }}></Avatar>
                <h4 style={{ fontWeight: "normal", textTransform: "capitalize" }}>Transaction</h4>
            </Stack>
        </Button >)
        return action;
    }
    const getPanelbody = () => {
        return <Box >

            {/* eWallet Image Slider */}
            <Slider {...sliderSettings} afterChange={onWalletChange} ref={sliderRef}>
                {membereWalletMap.map((wallet) => (
                    <CreditCard ewallet={wallet?.eWallet} walletid={wallet?.eWallet?.id.toString()} />
                ))}
            </Slider>
            {/* eWallet Balance */}
            <Box sx={{ textAlign: 'center' }}>
                <h2 style={{
                    marginTop: "30px"
                }}>{(selectedWallet && selectedWallet?.eWallet?.name) ?? ""}</h2>
                <Grid container style={{ "alignItems": "center" }} >
                    <Grid item xs={4}></Grid>
                    <Grid item xs={4}>

                        <h1 style={{ marginTop: "10px" }}>{selectedWallet?.points} </h1>
                    </Grid>
                    <Grid item xs={4} style={{ textAlign: "left" }}>
                        <h4 style={{ alignContent: "center", "fontWeight": "normal" }} >{selectedWallet?.eWallet.unitName ?? ""}</h4>
                    </Grid>
                </Grid>

            </Box>

            {/* Action Buttons */}
            <Stack direction={"row"} sx={{ mb: 3, justifyContent: "center" }} >
                {getActions()}
            </Stack>

            {
                selectedWallet?.eWallet?.description && selectedWallet?.eWallet?.description != '<p><br></p>' ? <Paper square={true} elevation={0} sx={{ mt: 2, borderRadius: "10px", overflow: "hidden", padding: 3, backgroundColor: "#eee", width: "100%", textAlign: "left" }}>
                    {/* <Typography variant="h6">Description</Typography> */}
                    <Typography className='business-desc' variant="body2" dangerouslySetInnerHTML={{ __html: (selectedWallet?.eWallet?.description || "").replace(/<p><br><\/p>/g, '') }} sx={{ overflow: "hidden" }} />
                </Paper> : <></>
            }
            {
                selectedWallet?.eWallet?.terms && selectedWallet?.eWallet?.terms != '<p><br></p>' ? <Paper square={true} elevation={0} sx={{ mt: 2, borderRadius: "10px", overflow: "hidden", padding: 3, backgroundColor: "#eee", width: "100%", textAlign: "left" }}>
                    {/* <Typography variant="h6">Description</Typography> */}
                    <Typography className='business-desc' variant="body2" dangerouslySetInnerHTML={{ __html: (selectedWallet?.eWallet?.terms || "").replace(/<p><br><\/p>/g, '') }} sx={{ overflow: "hidden" }} />
                </Paper> : <></>
            }
        </Box >
    }
    if (loadingeWallets) {
        return (<Box sx={{ minHeight: "80vh", alignContent: "center", justifyItems: "center" }}><Typography sx={{ textAlign: "center" }}>Loading your eWallets...</Typography></Box>);
    }
    console.log("sliderRf.current", sliderRef.current)
    return (
        isAuthenticated ? membereWalletMap && membereWalletMap.length ? <Box sx={{ width: '100%', p: 3 }}>
            {getPanelbody()}
            {selectedWallet && eWalletAction && <Drawer
                disableScrollLock={true}
                variant={'temporary'}
                open={!!eWalletAction}
                onClose={() => seteWalletAction(undefined)}
                anchor='bottom'
                PaperProps={{
                    sx: {
                        borderRadius: '16px 16px 0 0', // Rounded corners at the top
                        height: '85vh', // Adjust height as needed
                    },
                }}
            > {eWalletAction == "transfer" || eWalletAction == "pay" ? <TransfereWallet eWalletAction={eWalletAction} selectedeWallet={selectedWallet.eWallet} onClose={(e: { reason: "transfer" | "cancel" }) => { seteWalletAction(undefined); geteWallets(); }} /> : eWalletAction == 'transations' ? <MTransactionList selectedeWallet={selectedWallet.eWallet} onClose={() => { seteWalletAction(undefined) }} /> : <></>}

            </Drawer>}

        </Box > : <Stack sx={{ width: "100%", height: "80vh", alignItems: "center", justifyContent: "center" }}>
            <Typography variant="body1">No eWallets for you</Typography>
        </Stack> :
            <Stack sx={{ width: "100%", height: "80vh", alignItems: "center", justifyContent: "center" }}>
                <Typography variant="body1">Login to check your eWallets</Typography>
                <br />
                <Button variant="text" onClick={() => navigate(`/${merchant?.id}/membersignin`, { state: { from: location.pathname } })}>Login</Button>
            </Stack>
    );
}

export default MobileEWalletView;
