import { RecordTypes } from "./types/enum";

export const CONSTANTS = {
  BOTTOMBUTTONFEATURE: {
    HOME: "Home",
    GALLERY: "Gallery",
    APPOINTMENT: "Appointment+Message",
    LUCKYDRAW: "Lucky Draw",
    BOOKING: "Booking",
    CHECKIN: "Check In",
    NOTICE: "Notice",
    CART: "Cart",
    PROFILE: "Profile",
    MARKETING: "Marketing/Reward",
    MESSAGE: "Message",
    COUPON: "Coupon",
    EWALLET: "Ewallet"

  } as any,
  ApiConstants: {
    GENERIC: {
      get: {
        [RecordTypes.merchant]: `${process.env.REACT_APP_API_BASE_URL}/api/merchant`,
        [RecordTypes.outlet]: `${process.env.REACT_APP_API_BASE_URL}/api/outlet`,
        [RecordTypes.partner]: `${process.env.REACT_APP_API_BASE_URL}/api/partner`,
        [RecordTypes.staff]: `${process.env.REACT_APP_API_BASE_URL}/api/staff`,
        [RecordTypes.noticeBoard]: `${process.env.REACT_APP_API_BASE_URL}/api/noticeboards`,
        [RecordTypes.noticeTabs]: `${process.env.REACT_APP_API_BASE_URL}/api/noticeTabs`,
        [RecordTypes.bottomButtons]: `${process.env.REACT_APP_API_BASE_URL}/api/bottomButtons`,
        [RecordTypes.messages]: `${process.env.REACT_APP_API_BASE_URL}/api/messages`,
        [RecordTypes.messageForm]: `${process.env.REACT_APP_API_BASE_URL}/api/messageForm`,
        [RecordTypes.messageCategory]: `${process.env.REACT_APP_API_BASE_URL}/api/messageCategory`,
        [RecordTypes.coupon]: `${process.env.REACT_APP_API_BASE_URL}/api/coupon`,
        [RecordTypes.couponMapping]: `${process.env.REACT_APP_API_BASE_URL}/api/couponmapping`,
        [RecordTypes.eWallet]: `${process.env.REACT_APP_API_BASE_URL}/api/eWallet`,
        [RecordTypes.eWalletMapping]: `${process.env.REACT_APP_API_BASE_URL}/api/eWalletMapping`,
        [RecordTypes.transations]: `${process.env.REACT_APP_API_BASE_URL}/api/transations`
      },
      find: {
        [RecordTypes.merchant]: `${process.env.REACT_APP_API_BASE_URL}/api/merchant/find`,
        [RecordTypes.outlet]: `${process.env.REACT_APP_API_BASE_URL}/api/outlet/find`,
        [RecordTypes.partner]: `${process.env.REACT_APP_API_BASE_URL}/api/partner/find`,
        [RecordTypes.staff]: `${process.env.REACT_APP_API_BASE_URL}/api/staff/find`,
        [RecordTypes.noticeBoard]: `${process.env.REACT_APP_API_BASE_URL}/api/noticeboards/find`,
        [RecordTypes.noticeTabs]: `${process.env.REACT_APP_API_BASE_URL}/api/noticeTabs/find`,
        [RecordTypes.bottomButtons]: `${process.env.REACT_APP_API_BASE_URL}/api/bottomButtons/find`,
        [RecordTypes.messages]: `${process.env.REACT_APP_API_BASE_URL}/api/messages/find`,
        [RecordTypes.messageForm]: `${process.env.REACT_APP_API_BASE_URL}/api/messageForm/find`,
        [RecordTypes.messageCategory]: `${process.env.REACT_APP_API_BASE_URL}/api/messageCategory/find`,
        [RecordTypes.coupon]: `${process.env.REACT_APP_API_BASE_URL}/api/coupon/find`,
        [RecordTypes.couponMapping]: `${process.env.REACT_APP_API_BASE_URL}/api/couponmapping/find`,
        [RecordTypes.eWallet]: `${process.env.REACT_APP_API_BASE_URL}/api/eWallet/find`,
        [RecordTypes.eWalletMapping]: `${process.env.REACT_APP_API_BASE_URL}/api/eWalletMapping/find`,
        [RecordTypes.transations]: `${process.env.REACT_APP_API_BASE_URL}/api/transations/find`
      },
      getById: {
        [RecordTypes.merchant]: `${process.env.REACT_APP_API_BASE_URL}/api/merchant`,
        [RecordTypes.outlet]: `${process.env.REACT_APP_API_BASE_URL}/api/outlet`,
        [RecordTypes.partner]: `${process.env.REACT_APP_API_BASE_URL}/api/partner`,
        [RecordTypes.staff]: `${process.env.REACT_APP_API_BASE_URL}/api/staff/find`,
        [RecordTypes.noticeBoard]: `${process.env.REACT_APP_API_BASE_URL}/api/noticeboards/find`,
        [RecordTypes.noticeTabs]: `${process.env.REACT_APP_API_BASE_URL}/api/noticeTabs/find`,
        [RecordTypes.bottomButtons]: `${process.env.REACT_APP_API_BASE_URL}/api/bottomButtons/find`,
        [RecordTypes.messages]: `${process.env.REACT_APP_API_BASE_URL}/api/messages/find`,
        [RecordTypes.messageForm]: `${process.env.REACT_APP_API_BASE_URL}/api/messageForm/find`,
        [RecordTypes.messageCategory]: `${process.env.REACT_APP_API_BASE_URL}/api/messageCategory/find`,
        [RecordTypes.coupon]: `${process.env.REACT_APP_API_BASE_URL}/api/coupon/find`,
        [RecordTypes.couponMapping]: `${process.env.REACT_APP_API_BASE_URL}/api/couponmapping/find`,
        [RecordTypes.eWallet]: `${process.env.REACT_APP_API_BASE_URL}/api/eWallet/find`,
        [RecordTypes.eWalletMapping]: `${process.env.REACT_APP_API_BASE_URL}/api/eWalletMapping/find`,
        [RecordTypes.transations]: `${process.env.REACT_APP_API_BASE_URL}/api/transations/find`
      },
      delete: {
        [RecordTypes.merchant]: `${process.env.REACT_APP_API_BASE_URL}/api/merchant`,
        [RecordTypes.outlet]: `${process.env.REACT_APP_API_BASE_URL}/api/outlet`,
        [RecordTypes.partner]: `${process.env.REACT_APP_API_BASE_URL}/api/partner`,
        [RecordTypes.staff]: `${process.env.REACT_APP_API_BASE_URL}/api/staff`,
        [RecordTypes.noticeBoard]: `${process.env.REACT_APP_API_BASE_URL}/api/noticeboards`,
        [RecordTypes.noticeTabs]: `${process.env.REACT_APP_API_BASE_URL}/api/noticeTabs`,
        [RecordTypes.bottomButtons]: `${process.env.REACT_APP_API_BASE_URL}/api/bottomButtons`,
        [RecordTypes.messages]: `${process.env.REACT_APP_API_BASE_URL}/api/messages`,
        [RecordTypes.messageForm]: `${process.env.REACT_APP_API_BASE_URL}/api/messageForm`,
        [RecordTypes.messageCategory]: `${process.env.REACT_APP_API_BASE_URL}/api/messageCategory`,
        [RecordTypes.coupon]: `${process.env.REACT_APP_API_BASE_URL}/api/coupon`,
        [RecordTypes.couponMapping]: `${process.env.REACT_APP_API_BASE_URL}/api/couponmapping`,
        [RecordTypes.eWallet]: `${process.env.REACT_APP_API_BASE_URL}/api/eWallet`,
        [RecordTypes.eWalletMapping]: `${process.env.REACT_APP_API_BASE_URL}/api/eWalletMapping`,
        [RecordTypes.transations]: `${process.env.REACT_APP_API_BASE_URL}/api/transations`
      },
      post: {
        [RecordTypes.merchant]: `${process.env.REACT_APP_API_BASE_URL}/api/merchant`,
        [RecordTypes.outlet]: `${process.env.REACT_APP_API_BASE_URL}/api/outlet`,
        [RecordTypes.partner]: `${process.env.REACT_APP_API_BASE_URL}/api/partner`,
        [RecordTypes.staff]: `${process.env.REACT_APP_API_BASE_URL}/api/staff`,
        [RecordTypes.noticeBoard]: `${process.env.REACT_APP_API_BASE_URL}/api/noticeboards`,
        [RecordTypes.noticeTabs]: `${process.env.REACT_APP_API_BASE_URL}/api/noticeTabs`,
        [RecordTypes.bottomButtons]: `${process.env.REACT_APP_API_BASE_URL}/api/bottomButtons`,
        [RecordTypes.messages]: `${process.env.REACT_APP_API_BASE_URL}/api/messages`,
        [RecordTypes.messageForm]: `${process.env.REACT_APP_API_BASE_URL}/api/messageForm`,
        [RecordTypes.messageCategory]: `${process.env.REACT_APP_API_BASE_URL}/api/messageCategory`,
        [RecordTypes.coupon]: `${process.env.REACT_APP_API_BASE_URL}/api/coupon`,
        [RecordTypes.couponMapping]: `${process.env.REACT_APP_API_BASE_URL}/api/couponmapping`,
        [RecordTypes.eWallet]: `${process.env.REACT_APP_API_BASE_URL}/api/eWallet`,
        [RecordTypes.eWalletMapping]: `${process.env.REACT_APP_API_BASE_URL}/api/eWalletMapping`,
        [RecordTypes.transations]: `${process.env.REACT_APP_API_BASE_URL}/api/transations`,
      },
      put: {
        [RecordTypes.merchant]: `${process.env.REACT_APP_API_BASE_URL}/api/merchant`,
        [RecordTypes.outlet]: `${process.env.REACT_APP_API_BASE_URL}/api/outlet`,
        [RecordTypes.partner]: `${process.env.REACT_APP_API_BASE_URL}/api/partner`,
        [RecordTypes.staff]: `${process.env.REACT_APP_API_BASE_URL}/api/staff`,
        [RecordTypes.noticeBoard]: `${process.env.REACT_APP_API_BASE_URL}/api/noticeboards`,
        [RecordTypes.noticeTabs]: `${process.env.REACT_APP_API_BASE_URL}/api/noticeTabs`,
        [RecordTypes.bottomButtons]: `${process.env.REACT_APP_API_BASE_URL}/api/bottomButtons`,
        [RecordTypes.messages]: `${process.env.REACT_APP_API_BASE_URL}/api/messages`,
        [RecordTypes.messageForm]: `${process.env.REACT_APP_API_BASE_URL}/api/messageForm`,
        [RecordTypes.messageCategory]: `${process.env.REACT_APP_API_BASE_URL}/api/messageCategory`,
        [RecordTypes.coupon]: `${process.env.REACT_APP_API_BASE_URL}/api/coupon`,
        [RecordTypes.couponMapping]: `${process.env.REACT_APP_API_BASE_URL}/api/couponmapping`,
        [RecordTypes.eWallet]: `${process.env.REACT_APP_API_BASE_URL}/api/eWallet`,
        [RecordTypes.eWalletMapping]: `${process.env.REACT_APP_API_BASE_URL}/api/eWalletMapping`,
        [RecordTypes.transations]: `${process.env.REACT_APP_API_BASE_URL}/api/transations`
      }
    },
    findAllGroupInfo: `${process.env.REACT_APP_API_BASE_URL}/api/outlet-groups/findAllGroupInfo`,
    outletGroupCreate: `${process.env.REACT_APP_API_BASE_URL}/api/outlet-groups`,
    outletGroupDelete: `${process.env.REACT_APP_API_BASE_URL}/api/outlet-groups`,

    getMembers: `${process.env.REACT_APP_API_BASE_URL}/api/members`,
    getMemberbyId: `${process.env.REACT_APP_API_BASE_URL}/api/members`,
    deleteMember: `${process.env.REACT_APP_API_BASE_URL}/api/members`,
    postMembers: `${process.env.REACT_APP_API_BASE_URL}/api/members`,
    putMember: `${process.env.REACT_APP_API_BASE_URL}/api/members`,
    inviteMemberToken: `${process.env.REACT_APP_API_BASE_URL}/api/members/inviteToken`,
    findMemberByMobile: `${process.env.REACT_APP_API_BASE_URL}/api/members/findByMobile`,
    advanceFilter: `${process.env.REACT_APP_API_BASE_URL}/api/members/advanceFilter`,
    
    login: `${process.env.REACT_APP_API_BASE_URL}/api/user/login`,
    signup: `${process.env.REACT_APP_API_BASE_URL}/api/user/signup`,
    user_memberLoginByOtp: `${process.env.REACT_APP_API_BASE_URL}/api/user/memberLoginByOtp`,
    user_verifyOtp: `${process.env.REACT_APP_API_BASE_URL}/api/user/verifyOtp`,
    
    
    getAccount: `${process.env.REACT_APP_API_BASE_URL}/api/account`,
    updateAccount: `${process.env.REACT_APP_API_BASE_URL}/api/account`,

    uploadImage: `${process.env.REACT_APP_API_BASE_URL}/api/file/uploadimage`,

    postMerchantSettings: `${process.env.REACT_APP_API_BASE_URL}/api/merchant-settings`,
    putMerchanSettings: `${process.env.REACT_APP_API_BASE_URL}/api/merchant-settings`,
    getMerchanSettings: `${process.env.REACT_APP_API_BASE_URL}/api/merchant-settings`,

    getMerchantProfile: `${process.env.REACT_APP_API_BASE_URL}/api/public/merchant`,
    getMerchantOutlets: `${process.env.REACT_APP_API_BASE_URL}/api/public/merchantoutlets`,
    getMerchantProfileTokenized: `${process.env.REACT_APP_API_BASE_URL}/api/public/profile`,
    postPublicMember: `${process.env.REACT_APP_API_BASE_URL}/api/public/member/register`,
    getBottomButtons: `${process.env.REACT_APP_API_BASE_URL}/api/public/bottombuttons`,
    getNoticeTabs: `${process.env.REACT_APP_API_BASE_URL}/api/public/getNoticeTabs`,
    getAllNotices: `${process.env.REACT_APP_API_BASE_URL}/api/public/getAllNotices`,
    findOneNotice: `${process.env.REACT_APP_API_BASE_URL}/api/public/findOneNotice`,
    public_getNoticeLayout: `${process.env.REACT_APP_API_BASE_URL}/api/public/getNoticeLayout`,
    public_findMessageForm: `${process.env.REACT_APP_API_BASE_URL}/api/public/findMessageForm`,
    public_findMessageCategories: `${process.env.REACT_APP_API_BASE_URL}/api/public/findMessageCategories`,
    public_createMessage: `${process.env.REACT_APP_API_BASE_URL}/api/public/createMessage`,
    public_findCoupons: `${process.env.REACT_APP_API_BASE_URL}/api/public/findCoupons`,
    public_member_signup: `${process.env.REACT_APP_API_BASE_URL}/api/user/membersignup`,
    public_memberLogin: `${process.env.REACT_APP_API_BASE_URL}/api/user/memberLogin`,
    getMemberByMob: `${process.env.REACT_APP_API_BASE_URL}/api/memberaccount/getDetailsByMobile`,
    getMemberById: `${process.env.REACT_APP_API_BASE_URL}/api/memberaccount/getDetailsById`,
    updateMemberAcc: `${process.env.REACT_APP_API_BASE_URL}/api/memberaccount`,
    resetPassByOtp: `${process.env.REACT_APP_API_BASE_URL}/api/user/resetPassByOtp`,
    updatePassword: `${process.env.REACT_APP_API_BASE_URL}/api/user/updatePassword`,
    memberAc_updateAvatar: `${process.env.REACT_APP_API_BASE_URL}/api/memberaccount/updateAvatar`,
    
    redeemCoupon: `${process.env.REACT_APP_API_BASE_URL}/api/couponmapping/redeem`,
    transferCoupon: `${process.env.REACT_APP_API_BASE_URL}/api/couponmapping/transfer`,
    assignCouponToMany :`${process.env.REACT_APP_API_BASE_URL}/api/couponmapping/createMany`,
    assignEWalletToMany :`${process.env.REACT_APP_API_BASE_URL}/api/eWalletMapping/createMany`,
    transferEwalletPoints :`${process.env.REACT_APP_API_BASE_URL}/api/eWalletMapping/transfer`,
    payEwalletPoints :`${process.env.REACT_APP_API_BASE_URL}/api/eWalletMapping/pay`,
    transactions :`${process.env.REACT_APP_API_BASE_URL}/api/eWalletMapping/transactions`,

    getNoticeLayout: `${process.env.REACT_APP_API_BASE_URL}/api/merchant-settings/getNoticeLayout`,
    updateNoticeLayout: `${process.env.REACT_APP_API_BASE_URL}/api/merchant-settings/updateNoticeLayout`,
    whatsppMessage: `${process.env.REACT_APP_API_BASE_URL}/api/message/whatsapp`
    
  }
};
