// src/context/MerchantContext.tsx

import React, { createContext, useState, useContext, ReactNode, useEffect } from 'react';
import axiosService from '../services/axios';
import { User } from '../types/user-interface';
import { CONSTANTS } from '../constants';
import { useAlert } from '../components/CommonAlert';
import { jwtDecode } from 'jwt-decode';
import { Token } from 'typescript';
import { TokenObject } from '../types/token-object';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Merchant } from 'src/types/merchant-interface';

// Define the type for the context
interface MerchantContextType {
  merchant?: Merchant;
  setBackButton?: (shouldShow: boolean) => any
  getBackButton?: boolean
}

// Create context
const MerchantContext = createContext<MerchantContextType | undefined>(undefined);

// Create a provider component
interface MerchantProviderProps {
  children: ReactNode;
}

export const MerchantProvider: React.FC<MerchantProviderProps> = ({ children }) => {
  const [merchant, setMerchant] = useState<Merchant>();
  const [user, setUser] = useState<TokenObject | null>(null);
  const [showBackButton, setShowBackButton] = useState<boolean>(false);
  const { showAlert } = useAlert();
  const navigate = useNavigate();
  const location = useLocation();
  const { merchantid } = useParams();

  const getMerchant = async () => {
    try {
      const response = await axiosService.get<Merchant | any>(`${CONSTANTS.ApiConstants.getMerchantProfile}/${merchantid}`)
      if (response.status != 200) {
        showAlert(response?.data?.message || "Failed to load", "error");
        console.error("Exception Caught", response);
        setMerchant(undefined)
      } else {
        setMerchant(response?.data)
      }
    } catch (ex: any) {
      setMerchant(undefined)
    }
  };
  useEffect(() => {
    console.log("merchantid", merchantid)
    merchantid && getMerchant();
  }, []);
  const getBackButton = showBackButton
  const setBackButton = (shouldShow: boolean) => {
    return setShowBackButton(shouldShow)
  }
  return (
    <MerchantContext.Provider value={{ merchant, getBackButton, setBackButton }}>
      {children}
    </MerchantContext.Provider>
  );
};

// Custom hook for using the context
export const useMerchant = (): MerchantContextType => {
  const context = useContext(MerchantContext);
  if (context === undefined) {
    throw new Error('useMerchant must be used within an MerchantProvider');
  }
  return context;
};
