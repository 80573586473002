
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import { Button, Card, CardContent, Checkbox, FormControl, FormControlLabel, FormLabel, Grid, IconButton, InputAdornment, InputLabel, MenuItem, Radio, RadioGroup, Select, Stack, TextField, Typography } from '@mui/material';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { CONSTANTS } from '../../constants';
import axiosService from '../../services/axios';

import * as Icons from '@mui/icons-material';
import { useAlert } from '../../components/CommonAlert';
import { useParams } from 'react-router-dom';
import { RecordActions, RecordTypes, Roles } from '../../types/enum';
import ImageUpload from '../../components/ImageUpload';
import { useState } from 'react';
import { useAuth } from '../../context/AuthContext';
import { HtmlEditor } from 'src/components/HtmlEditor';
import IconSelector from 'src/components/IconSelector';
import { MuiColorInput } from 'mui-color-input';
import { eWallet } from 'src/intefaces/eWallet.interface';

const genderOptions = [
    { value: 'Male', label: 'Male' },
    { value: 'Female', label: 'Female' },
    { value: 'Other', label: 'Other' }
]
export function CForm(): React.JSX.Element {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [value, setValue] = React.useState("General");
    const [outlets, setOutlets] = React.useState<eWallet[]>([])
    const { action, id, token } = useParams();
    const { user, getToken } = useAuth();
    const [iconSelectorOpen, setIconSelectorOpen] = useState<string>();
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };
    const { showAlert } = useAlert();
    const methods = useForm<eWallet>({
        defaultValues: {
            id: 0,
            name: "",
            unitName: "",
            description: "",
            terms: "",
            allowTransfer: false,
            allowPay: false,
            image: "",
            transferImg: "",
            payImg: "",
            transactionImg: "",
        }
    });

    const createNeweWallet = async (data: any) => {
        try {

            const response = await (await axiosService.post<eWallet | any>(`${CONSTANTS.ApiConstants.GENERIC.post[RecordTypes.eWallet]}`, data))
            if (response.status != 200) {
                setLoading(false);
                showAlert(response.data.message || 'Failed to Save eWallet', 'error');
            } else {
                const json = response.data;
                setLoading(false);
                showAlert('eWallet Created Successfully', 'success');
                navigate(`/eWallets/edit/${response.data.id}`)
            }
        } catch (ex: any) {
            setLoading(false);
            showAlert(ex.response.data.message || 'Failed to Save eWallet', 'error');
            console.error("Exception Caught", ex)
        }
    }
    const editeWallet = async (data: any) => {
        try {
            const response = await axiosService.put<eWallet>(`${CONSTANTS.ApiConstants.GENERIC.put[RecordTypes.eWallet]}/${id}`, data)
            if (response.status != 200) {
                setLoading(false);
                showAlert('Failed to Update eWallet', 'error');
            } else {
                const json = response.data;
                setLoading(false);
                showAlert('eWallet Updated Successfully', 'success');
            }
        } catch (ex) {
            showAlert('Failed to Update eWallet', 'error');
            setLoading(false);
            console.error("Exception Caught", ex)
        }
    }

    const geteWalletDetails = async () => {
        try {
            const response = await axiosService.get<eWallet>(`${CONSTANTS.ApiConstants.GENERIC.get[RecordTypes.eWallet]}/${id}`)
            if (response.status != 200) {
                showAlert('Failed to Retrive eWallet Details', 'error');
            } else {
                const json: eWallet = response.data;
                methods.reset(json);
            }
        } catch (ex) {
            showAlert('Failed to Retrive eWallet Details', 'error');
            console.error("Exception Caught", ex)
        }
    }
    const onSubmit = methods.handleSubmit(async (data: any) => {
        try {
            if (loading) {
                return
            }
            setLoading(true);
            if (action == RecordActions.add) {
                createNeweWallet(data);
            } else if (action == RecordActions.edit) {
                editeWallet(data);
            }
        }
        catch (ex) {
            setLoading(false);
        } finally {

        }
    });
    React.useEffect(() => {
        if (action == RecordActions.edit || action == RecordActions.view) {
            geteWalletDetails();
        }
    }, [])
    return (
        <FormProvider {...methods} >
            <Box component="form" onSubmit={onSubmit} sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                <Card>
                    <CardContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={4}>
                                <Controller
                                    name="name"
                                    rules={{ required: 'Name is required' }}
                                    control={methods.control}
                                    render={({ field, fieldState }) =>
                                        <TextField
                                            InputProps={{
                                                readOnly: action == RecordActions.view,
                                            }}
                                            helperText={typeof methods.formState.errors?.name?.message === 'string' ? methods.formState.errors?.name?.message : ""}
                                            FormHelperTextProps={{ style: { color: 'red' } }}
                                            {...field}
                                            fullWidth
                                            id="Name"
                                            label="Name"
                                        />} />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Controller
                                    name="unitName"

                                    control={methods.control}
                                    render={({ field, fieldState }) =>
                                        <TextField
                                            InputProps={{
                                                readOnly: action == RecordActions.view,
                                            }}
                                            {...field}
                                            fullWidth
                                            id="unitName"
                                            label="Unit Name"
                                        />} />
                            </Grid>

                            <Grid item xs={12}>
                                <Controller
                                    name="description"
                                    control={methods.control}
                                    render={({ field, fieldState }) => (
                                        <div>
                                            <InputLabel>Description</InputLabel>
                                            <HtmlEditor
                                                key={field.name}
                                                {...field}
                                                placeholder="Enter your description here"
                                            />
                                        </div>
                                    )} />
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    name="terms"
                                    control={methods.control}
                                    render={({ field, fieldState }) => (
                                        <div>
                                            <InputLabel>Terms & Conditions</InputLabel>
                                            <HtmlEditor
                                                key={field.name}
                                                {...field}
                                                placeholder="Enter your terms & conditions here"
                                            />
                                        </div>
                                    )} />
                            </Grid>
                        </Grid>
                    </CardContent >
                </Card >
                <Card>
                    <CardContent>
                        <Grid container spacing={2}>

                            <Grid item xs={12} sm={4}>
                                <Controller
                                    name="image"
                                    control={methods.control}
                                    render={({ field, fieldState }) =>
                                        <ImageUpload onImageChange={(url: string | null) => { field.onChange(url) }} imageUrl={field.value ?? ""} mode={action} name={"image"} label="eWallet Image" />
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} sm={8}></Grid>
                            <Grid item xs={12} sm={4}>
                                <Stack>
                                    <Stack>Allow</Stack>
                                    <Stack direction={"row"}>
                                        <Controller
                                            name="allowTransfer"
                                            control={methods.control}
                                            render={({ field }) =>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            {...field}
                                                            checked={field.value}
                                                            onChange={(e) => field.onChange(e.target.checked)}
                                                        />
                                                    }
                                                    label="Transfer"
                                                />
                                            } />
                                        <Controller
                                            name="allowPay"
                                            control={methods.control}
                                            render={({ field }) =>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            {...field}
                                                            checked={field.value}
                                                            onChange={(e) => field.onChange(e.target.checked)}
                                                        />
                                                    }
                                                    label="Pay"
                                                />
                                            } />
                                    </Stack>
                                </Stack>
                            </Grid>  
                            <Grid item xs={12} sm={8}></Grid>
                            <Grid item xs={12} sm={2}>
                                <Controller
                                    name="transferImg"
                                    control={methods.control}
                                    render={({ field, fieldState }) =>
                                        <ImageUpload onImageChange={(url: string | null) => { field.onChange(url) }} imageUrl={field.value ?? ""} mode={action} name={"transferImg"} label="Transfer" />
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                <Controller
                                    name="payImg"
                                    control={methods.control}
                                    render={({ field, fieldState }) =>
                                        <ImageUpload onImageChange={(url: string | null) => { field.onChange(url) }} imageUrl={field.value ?? ""} mode={action} name={"payImg"} label="Pay" />
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                <Controller
                                    name="transactionImg"
                                    control={methods.control}
                                    render={({ field, fieldState }) =>
                                        <ImageUpload onImageChange={(url: string | null) => { field.onChange(url) }} imageUrl={field.value ?? ""} mode={action} name={"transactionImg"} label="Transaction" />
                                    }
                                />
                            </Grid>
                        </Grid>
                    </CardContent >
                </Card >
                
                <br />
                <br />
                <br />
                <br />
                {action != RecordActions.view && <Box
                    sx={{
                        position: 'fixed',
                        bottom: 0,
                        left: 0,
                        zIndex: 9,
                        width: '100%',
                        backgroundColor: '#f5f5f5',
                        padding: 2,
                        display: 'flex',
                        justifyContent: 'flex-end', // Align the button to the right
                        boxShadow: '0px -1px 5px rgba(0,0,0,0.1)', // Optional shadow
                    }}
                >
                    <Button disabled={loading} variant="contained" color="primary" type="submit">
                        Save
                    </Button>
                </Box>
                }
            </Box>
        </FormProvider >
    );
}

