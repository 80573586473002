import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { TextField, Button, Grid, Typography, MenuItem, CircularProgress, Card, CardContent, Container, CardHeader, Box, InputLabel, keyframes, Stack } from '@mui/material';
import axios from 'axios';
import { MessageCategory, Messages } from 'src/intefaces/message.interface';
import ImageUpload from 'src/components/ImageUpload';
import { CONSTANTS } from 'src/constants';
import axiosService from 'src/services/axios';
import { RecordTypes, RecordActions } from 'src/types/enum';
import { useMerchant } from 'src/context/MerchantContext';
import { MessageForm } from 'src/intefaces/message-form.interface';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { useAlert } from 'src/components/CommonAlert';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
interface formTemplate { visible: boolean, label: string, sequence: number, key: string }
const bounceAnimation = keyframes`
  0% { transform: scale(0); }
  50% { transform: scale(1.1); }
  100% { transform: scale(1); }
`;

export const QueryForm: React.FC = () => {
    const { control, handleSubmit, reset } = useForm<Messages>();
    const [categories, setCategories] = useState<MessageCategory[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [messageFormConfig, setMessageFormConfig] = useState<MessageForm>();
    const [messageFormControls, setMessageFormControls] = useState<formTemplate[]>([]);
    const { merchant } = useMerchant();
    const { showAlert } = useAlert();
    const [isComplete, setIsComplete] = useState<boolean>(false);

    const sortDataBySeq = (data: any) => {
        // Extract the sequence keys and sort them
        const seqKeys = Object.keys(data)
            .filter(key => key.endsWith('Seq'))
            .sort((a, b) => data[a] - data[b]);

        const sortedKeys = seqKeys.map(seqKey => {
            const baseKey = seqKey.replace('Seq', '');
            return {
                'visible': data[baseKey + 'Visible'],
                'label': data[baseKey + 'Label'],
                'sequence': data[baseKey + 'Seq'],
                'key': baseKey
            } as formTemplate;
        });
        // Combine non-sequence related keys with sorted sequence keys
        // const nonSeqKeys = Object.keys(data).filter(key => !sortedKeys.includes(key) && !key.endsWith('Seq'));
        // const finalSortedKeys = [...nonSeqKeys, ...sortedKeys];

        // // Create a new sorted object
        // const sortedData: any = {};
        // finalSortedKeys.forEach(key => {
        //     if (data.hasOwnProperty(key)) {
        //         sortedData[key] = data[key];
        //     }
        // });

        return sortedKeys;
    };

    const getMessageFormDetails = async () => {
        try {
            const response = await axiosService.get<MessageForm>(`${CONSTANTS.ApiConstants.public_findMessageForm}/${merchant?.id}`)
            if (response.status != 200) {

            } else {
                const json: MessageForm = response.data;
                console.log("sortDataBySeqbfore", json)
                console.log("sortDataBySeq", sortDataBySeq(json));
                setMessageFormConfig(json)
                setMessageFormControls(sortDataBySeq(json))
            }
        } catch (ex) {
            console.error("Exception Caught", ex)
        }
    }
    const fetchCategories = async () => {
        try {
            const response = await axiosService.get<MessageCategory[]>(`${CONSTANTS.ApiConstants.public_findMessageCategories}/${merchant?.id}`)
            if (response.status != 200) {

            } else {
                const json = response.data;
                setCategories(json)
            }
        } catch (ex) {
            console.error("Exception Caught", ex)
        }
    };

    useEffect(() => {

        merchant && getMessageFormDetails();
        fetchCategories();
    }, [merchant]);

    const onSubmit = async (data: Messages) => {
        try {

            const response = await (await axiosService.post<Messages | any>(`${CONSTANTS.ApiConstants.public_createMessage}/${merchant?.id}`, data))
            if (response.status != 200) {
                setLoading(false);
                showAlert(response.data.message || `Failed to ${messageFormConfig?.title} Message`, 'error');
            } else {
                const json = response.data;
                setLoading(false);
                setIsComplete(true);
            }
        } catch (ex: any) {
            setLoading(false);
            showAlert(ex.response.data.message || `Failed to Save ${messageFormConfig?.title}`, 'error');
            console.error("Exception Caught", ex)
        }

    };

    return (
        !isComplete ?
            <Container maxWidth={"sm"} sx={{ p: 3 }}>
                <Box>
                    <Typography variant='h6'> {messageFormConfig?.title}</Typography><br />
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Grid container spacing={2}>
                            {messageFormControls.map((c) => {
                                return c?.visible ? <Grid item xs={12}>
                                    <Controller
                                        name={c.key == 'category' ? 'categoryid' : c.key as any}
                                        control={control}
                                        defaultValue=""
                                        render={({ field }) => {
                                            if (c.key == 'category') {
                                                return <>
                                                    <InputLabel>{messageFormConfig?.categoryLabel}</InputLabel>
                                                    <TextField
                                                        {...field}
                                                        select
                                                        fullWidth
                                                        variant="outlined"
                                                        required
                                                    >
                                                        {categories.sort((a, b) => {
                                                            if (a.sortId < b.sortId) {
                                                                return -1;
                                                            } else if (a.sortId > b.sortId) {
                                                                return 1;
                                                            }
                                                            return 0;
                                                        }).map((option) => (
                                                            <MenuItem key={option.categoryName} value={option.categoryName}>
                                                                {option.categoryName}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                </>
                                            } else if (c.key == 'message') {
                                                return <><InputLabel>{c.label}</InputLabel><TextField {...field} fullWidth variant="outlined" multiline rows={4} required /></>
                                            } else if (c.key == 'file') {
                                                return <><ImageUpload onImageChange={(url: string | null) => { field.onChange(url) }} imageUrl={field.value || ""} name={"File"} label={messageFormConfig?.fileLabel} allowPdf={true} /></>
                                            } else if (c.key == 'dateTime') {
                                                return <><InputLabel>{c.label}</InputLabel><DateTimePicker
                                                    sx={{ width: "100%" }}
                                                    onChange={field.onChange}
                                                    value={field.value || null}
                                                /></>
                                            }
                                            return <><InputLabel>{c.label}</InputLabel><TextField {...field} fullWidth variant="outlined" required /></>
                                        }}
                                    />

                                </Grid> : <></>
                            })}
                            <Grid item xs={12}>
                                <Button size='large' type="submit" variant="outlined" color="primary" fullWidth>
                                    <h3 style={{ fontWeight: "normal" }}>Submit</h3>
                                </Button>
                            </Grid>
                        </Grid>

                    </form>
                </Box>
            </Container> :
            <Stack style={{ padding: "24px", height: "80vh", justifyContent: "space-between", alignItems: "center" }}>
                <Stack style={{ alignItems: "center" }} spacing={2}>
                    <CheckCircleIcon
                        sx={{
                            fontSize: '6rem',
                            color: '#4caf50',
                            animation: `${bounceAnimation} 0.5s ease-in-out forwards`,
                        }}
                    />
                    <h2>Submitted Successfully</h2>
                </Stack>
                <Button size='large' fullWidth type="button" color="primary" variant="outlined" onClick={() => { reset(); setIsComplete(false) }}>
                    <h3 style={{ fontWeight: "normal" }}>Ok</h3>
                </Button>
            </Stack>
    );
};
