
import React, { useEffect, useState } from 'react';
import { Box, IconButton } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { CONSTANTS } from 'src/constants';
import { NoticeBoard, NoticeTab } from 'src/intefaces/notice-board.inteface';
import axiosService from 'src/services/axios';
import { FullCardViewNotice } from './fullcardview-notice';
import { useMerchant } from 'src/context/MerchantContext';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
const MobileNoticeDetails: React.FC = () => {
    const navigate = useNavigate();
    const [noticeBoard, setNoticeBoard] = useState<NoticeBoard>();
    const { noticeId } = useParams()
    const { setBackButton } = useMerchant();
    const getNoticeBoards = async () => {
        try {
            const response = await axiosService.get<NoticeBoard[] | any>(`${CONSTANTS.ApiConstants.findOneNotice}/${noticeId}`)
            if (response.status != 200) {
                setNoticeBoard(undefined)
            } else {
                const json = response.data;
                setNoticeBoard(json);
            }
        } catch (ex: any) {
            setNoticeBoard(undefined)
            console.error("Exception Caught", ex)
        }
    }
    useEffect(() => {
        getNoticeBoards();
        setBackButton && setBackButton(true);
    }, [])

    return (
        <Box sx={{ width: '100%',p:3 }} >          
            <FullCardViewNotice notice={noticeBoard} />
        </Box >
    );
};

export default MobileNoticeDetails;
