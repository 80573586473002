
import * as React from 'react';
import { useSelection } from '../../hooks/use-selection';
import DataTable from '../../components/DataTable';
import { CONSTANTS } from '../../constants';
import axiosService from '../../services/axios';
import { useAlert } from '../../components/CommonAlert';
import DeleteConfirmationDialog from '../../components/ConfirmationDialog';
import { useNavigate } from 'react-router-dom';
import { RecordTypes } from 'src/types/enum';
import { eWallet } from 'src/intefaces/eWallet.interface';


function noop(): void {
  // do nothing
}



interface EWalletsTableProps {
  count?: number;
  page?: number;
  rows?: eWallet[];
  rowsPerPage?: number;
  onDeleteCallback?: () => any
}

export function EWalletsTable({
  count = 0,
  rows = [],
  page = 0,
  rowsPerPage = 0,
  onDeleteCallback
}: EWalletsTableProps): React.JSX.Element {
  const rowIds = React.useMemo(() => {
    return rows.map((eWallet) => eWallet.id);
  }, [rows]);
  const navigate = useNavigate();
  const { selectAll, deselectAll, selectOne, deselectOne, selected } = useSelection(rowIds);
  const { showAlert } = useAlert();
  const selectedSome = (selected?.size ?? 0) > 0 && (selected?.size ?? 0) < rows.length;
  const [selectedId, setSelectedId] = React.useState<number | undefined>(undefined);
  const [openDeleteDlg, setOpenDeleteDlg] = React.useState<boolean>(false);
  const columns = [
    { id: 'id', label: 'Id' },
    { id: "image", label: "Image", type: "image" },
    { id: "name", label: "Name" },
    { id: "unitName", label: "Unit Name" },
    { id: "Assigned", label: "Assigned" },
    { id: "Paid", label: "Paid" },
    { id: "Balance", label: "Balance" }
  ];
  const handleEdit = (id: number) => {
    navigate(`/eWallets/edit/${id}`);
  };

  const handleDelete = async (id: number) => {
    setSelectedId(id);
    setOpenDeleteDlg(true);
  };

  const handleView = (id: number) => {
    navigate(`/eWallets/view/${id}`);
  };
  const onDeleteConfirm = async () => {
    try {
      setOpenDeleteDlg(false)
      const response = await axiosService.delete<eWallet[]>(`${CONSTANTS.ApiConstants.GENERIC.delete[RecordTypes.eWallet]}/${selectedId}`);
      if (response.status != 200) {
        showAlert('Failed to Delete Merchant', 'error');
      } else {
        showAlert('Merchant Deleted Successfully', 'success');
        onDeleteCallback && onDeleteCallback();
      }
    } catch (ex) {
      showAlert('Failed to Delete Merchant', 'error');
      console.error("Exception Caught", ex)
    } finally {
      setSelectedId(undefined);

    }
  }
  return (<div>
    <DataTable
      rows={rows}
      columns={columns}
      onEdit={handleEdit}
      onDelete={handleDelete}
      onView={handleView}
    />
    <DeleteConfirmationDialog
      open={openDeleteDlg}
      onClose={() => { setOpenDeleteDlg(false); setSelectedId(undefined) }}
      onConfirm={onDeleteConfirm}
      confirmLabel={"Delete"}
      cancelLabel={"Cancel"}
      message={"Are you sure you want to delete merchant"}
      title={"Confirm Delete"}
    />
  </div>)
}
