import React, { useEffect, useRef, useState } from 'react';
import { Box, Card, CardContent, Typography, Avatar, CardMedia, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import QRCode from 'qrcode.react';
import { eWallet } from 'src/intefaces/eWallet.interface';
import { FlipCameraIos } from '@mui/icons-material';

interface FlipCardProps {
    frontImage: string;
    backContent: React.ReactNode;
}

const FlipCardContainer = styled(Box)({
    perspective: '1000px',
    display: 'inline-block',
});

const FlipCardInner = styled(Box)<{ isFlipped: boolean }>(({ isFlipped }) => ({
    position: 'relative',
    width: '100%',
    transformStyle: 'preserve-3d',
    transition: 'transform 0.6s',
    transform: isFlipped ? 'rotateY(180deg)' : 'rotateY(0deg)',
}));

const FlipCardFront = styled(Box)({
    backfaceVisibility: 'hidden',
});

const FlipCardBack = styled(Box)<{ height: number }>(({ height }) => ({
    backfaceVisibility: 'hidden',
    transform: 'rotateY(180deg)',
    position: 'absolute',
    top: 0,
    height: height ? `${height}px` : 'auto',
    width: '100%',
}));


const CreditCard: React.FC<{ ewallet: eWallet, walletid: string }> = ({ ewallet, walletid }) => {
    const [isFlipped, setIsFlipped] = useState(false);
    const [height, setHeight] = useState(0);
    const frontImageRef = useRef<HTMLImageElement>(null);
    const backRef = useRef<HTMLImageElement>(null);
    const handleFlip = () => {
        setIsFlipped(!isFlipped);
    };
    const imageLoaded = () => {
        if (frontImageRef.current) {
            console.log("frontImageRef.current.clientHeight", frontImageRef.current.clientHeight)
            setHeight(frontImageRef.current.clientHeight);
        }
    }
    return (
        <FlipCardContainer>
            <FlipCardInner isFlipped={isFlipped}>
                {/* Front of the card */}
                <FlipCardFront onClick={() => setIsFlipped(!isFlipped)}>
                    <CardMedia
                        component="img"
                        onLoad={imageLoaded}
                        image={ewallet?.image ?? ""}
                        alt="Credit Card Front"
                        ref={frontImageRef}
                        sx={{ width: '100%' }}
                    />
                </FlipCardFront>

                {/* Back of the card */}
                <FlipCardBack height={height} onClick={() => setIsFlipped(!isFlipped)}>
                    <Box sx={{ display: 'flex', justifyContent: 'center', height: "100%",alignItems:"center" }}>
                        <QRCode value={walletid} />
                    </Box>
                </FlipCardBack>
            </FlipCardInner>
        </FlipCardContainer>
    );
};

export default CreditCard;
