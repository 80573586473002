// src/routes/Routes.tsx
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import NotFound from '../pages/not-found';
import Layout from '../components/Layout';
import Overview from '../pages/dashboard/overview';
import MerchantsPage from '../pages/merchants/merchants-page';
import MembersPage from '../pages/members/members-page';
import MerchantAddForm from '../pages/merchants/merchant-form';
import { MemberForm } from '../pages/members/member-form';
import SignInForm from '../pages/sign-in-form';
import ProtectedRoute from './ProtectedRoute';
import UnauthorizedPage from '../pages/unauthorized-page';
import { MerchantAccount } from '../pages/merchants/merchant-account';
import { RecordTypes, Roles } from '../types/enum';
import { Settings } from '../pages/settings/form';
import { StaffForm } from '../pages/staff/staffForm';
import StaffsPage from '../pages/staff/staff-page';
import { MemberInvite } from '../pages/members/member-invite';
import GlobalMemberSearch from '../pages/members/global-member-search';
import AboutUs from 'src/pages/mobile/about-us/about-us';
import Layout3 from 'src/pages/notice-board/layout-3';
import Layout2 from 'src/pages/notice-board/layout-2';
import NoticeBoardsPage from 'src/pages/notice-board/noticeboard-page';
import { NoticeBoardForm } from 'src/pages/notice-board/noticeboard-form';
import NoticeTabs from 'src/pages/notice-board/notice-tabs';
import BottomButtons from 'src/pages/bottom-button/button-page';
import MobileLayout from 'src/pages/notice-board/mobile-layout';
import MobileNoticesView from 'src/pages/mobile/notice/mobile-notices-view';
import MobileNoticeDetails from 'src/pages/mobile/notice/mobile-notices-details';
import { EditMessageForm, } from 'src/pages/messages/edit-message-form';
import MessagesPage from 'src/pages/messages/messages-page';
import { QueryForm } from 'src/pages/mobile/message-form';
import MessageCategories from 'src/pages/messages/message-categories';
import CouponsPage from 'src/pages/coupons/coupons-page';
import { CouponForm } from 'src/pages/coupons/coupon-form-wrapper';
import MobileCouponsView from 'src/pages/mobile/coupons/coupon-page';
import { MemberAccLanding } from 'src/pages/mobile/members/member-account-landing';
import MemberLogin from 'src/pages/mobile/members/member-login';
import MemberRegister from 'src/pages/mobile/members/member-register';
import MemberResetPassword from 'src/pages/mobile/members/member-reset-password';
import MemberVerify from 'src/pages/mobile/members/member-verify-otp';
import MemberSetUp from 'src/pages/mobile/members/member-set-up';
import MemberProfile from 'src/pages/mobile/members/member-profile';
import ProtectedMobileRoute from './ProtectedMobileRoute';
import EWalletsPage from 'src/pages/ewallet/eWallets-page';
import { EWalletForm } from 'src/pages/ewallet/ewallet-form-wrapper';
import MobileEWalletView from 'src/pages/mobile/ewallet/ewallet-page';
import TransactionsPage from 'src/pages/transaction-logs/transaction-page';
import ChangePasswordForm from 'src/pages/mobile/members/change-password';
import MerchantTerms from 'src/pages/mobile/members/merchant-terms';


const AppRoutes: React.FC = () => (
    <Routes>
        <Route path="login" element={<SignInForm />} />
        <Route path="admin" element={<SignInForm />} />
        <Route path="merchant" element={<SignInForm />} />
        <Route path="/unauthorized" element={<UnauthorizedPage />} />
        <Route path="/mregister/:token" element={<MemberInvite />} />
        <Route element={<MobileLayout />}>
            <Route path="/:merchantid" element={<AboutUs />} />
            <Route path="/:merchantid/home" element={<AboutUs />} />
            <Route path="/:merchantid/notice" element={<MobileNoticesView />} />
            <Route path="/:merchantid/notice/:noticeId" element={<MobileNoticeDetails />} />
            <Route path="/:merchantid/message" element={<QueryForm />} />
            <Route path="/:merchantid/membersignup" element={<MemberRegister src="register" />} />
            <Route path="/:merchantid/forgotpassword" element={<MemberRegister src="forgotpassword" />} />
            <Route path="/:merchantid/membersignin" element={<MemberLogin />} />
            <Route path="/:merchantid/memberresetpass/:countrycode/:mobile/:mode" element={<MemberResetPassword />} />
            <Route path="/:merchantid/memberverify/:countrycode/:mobile/:mode" element={<MemberVerify />} />
            <Route path="/:merchantid/membersetup/:mode" element={<MemberSetUp />} />
            <Route path="/:merchantid/memberacclanding" element={<MemberAccLanding />} />
            <Route path="/:merchantid/changepassword" element={<ChangePasswordForm />} />
            <Route path="/:merchantid/merchantterms" element={<MerchantTerms />} />
        </Route>
        <Route element={<ProtectedMobileRoute roles={[Roles.member]} element={<MobileLayout />} />}>
            <Route path="/:merchantid/coupon" element={<MobileCouponsView />} />
            <Route path="/:merchantid/ewallet" element={<MobileEWalletView />} />
            <Route path="/:merchantid/profile" element={<MemberProfile />} />
        </Route>

        <Route path="/notice/layout2" element={<Layout2 />} />
        <Route path="/notice/layout3" element={<Layout3 />} />
        <Route element={<ProtectedRoute roles={[Roles.merchant, Roles.superadmin, Roles.outlet, Roles.partner, Roles.staff]} element={<Layout />} />}>
            <Route path="/" element={<Overview />} />
            <Route path="/home" element={<Overview />} />
        </Route>

        <Route element={<ProtectedRoute roles={[Roles.outlet, Roles.merchant, Roles.staff]} element={<Layout />} />}>
            <Route path="members" element={<MembersPage />} />
            <Route path="members/globalsearch" element={<GlobalMemberSearch />} />
            <Route path="members/:action" element={<MemberForm />} />
            <Route path="members/:action/:id" element={<MemberForm />} />
        </Route>

        <Route element={<ProtectedRoute roles={[Roles.outlet]} element={<Layout />} />}>
            <Route path="outletaccount" element={<MerchantAccount recordType={RecordTypes.outlet} />} />
        </Route>
        <Route element={<ProtectedRoute roles={[Roles.partner]} element={<Layout />} />}>
            <Route path="partneraccount" element={<MerchantAccount recordType={RecordTypes.partner} />} />
        </Route>
        <Route element={<ProtectedRoute roles={[Roles.merchant]} element={<Layout />} />}>
            <Route path="merchantaccount" element={<MerchantAccount recordType={RecordTypes.merchant} />} />

            <Route path="settings" element={<Settings recordType={RecordTypes.merchant} />} />

            <Route path="outlet" element={<MerchantsPage recordType={RecordTypes.outlet} key={Math.random()} />} />
            <Route path="outlet/:action" element={<MerchantAddForm recordType={RecordTypes.outlet} key={Math.random()} />} />
            <Route path="outlet/:action/:id" element={<MerchantAddForm recordType={RecordTypes.outlet} key={Math.random()} />} />

            <Route path="partner" element={<MerchantsPage recordType={RecordTypes.partner} key={Math.random()} />} />
            <Route path="partner/:action" element={<MerchantAddForm recordType={RecordTypes.partner} key={Math.random()} />} />
            <Route path="partner/:action/:id" element={<MerchantAddForm recordType={RecordTypes.partner} key={Math.random()} />} />

            <Route path="staff" element={<StaffsPage key={Math.random()} />} />
            <Route path="staff/:action" element={<StaffForm key={Math.random()} />} />
            <Route path="staff/:action/:id" element={<StaffForm key={Math.random()} />} />

            <Route path="noticeboards" element={<NoticeBoardsPage />} />
            <Route path="noticeboards/:action" element={<NoticeBoardForm />} />
            <Route path="noticeboards/:action/:id" element={<NoticeBoardForm />} />
            <Route path="noticeTabs" element={<NoticeTabs />} />
            <Route path="bottomButtons" element={<BottomButtons />} />

            <Route path="messages" element={<MessagesPage />} />
            <Route path="messagesForm" element={<EditMessageForm />} />
            <Route path="messages-categories" element={<MessageCategories />} />

            <Route path="coupons" element={<CouponsPage />} />
            <Route path="coupons/:action" element={<CouponForm />} />
            <Route path="coupons/:action/:id" element={<CouponForm />} />

            <Route path="eWallets" element={<EWalletsPage />} />
            <Route path="eWallets/:action" element={<EWalletForm />} />
            <Route path="eWallets/:action/:id" element={<EWalletForm />} />

            <Route path="txnlog" element={<TransactionsPage />} />
            
        </Route>
        <Route element={<ProtectedRoute roles={[Roles.superadmin]} element={<Layout />} />}>

            <Route path="merchants" element={<MerchantsPage recordType={RecordTypes.merchant} key={Math.random()} />} />
            <Route path="merchants/:action" element={<MerchantAddForm recordType={RecordTypes.merchant} key={Math.random()} />} />
            <Route path="merchants/:action/:id" element={<MerchantAddForm recordType={RecordTypes.merchant} key={Math.random()} />} />

        </Route>
        <Route path="*" element={<NotFound />} />
    </Routes>
);

export default AppRoutes;
