import { useParams } from "react-router-dom";
import React from 'react';
import { Button, Card, CardActions, CardContent, Grid, TextField } from "@mui/material"
import { Image as ImageIcon } from '@phosphor-icons/react/dist/ssr/Image';
import { Controller, useFormContext } from 'react-hook-form';
import { RecordActions } from "../../../types/enum";
import { FormProp } from "../../../types/formprop-interface";

export const LoginDetails = ({ action, id }: FormProp) => {
    const methods = useFormContext();
    return (
        <Grid container spacing={2} >
            <Grid item xs={12} sm={4}>
                <Controller
                    name="username"
                    control={methods.control}
                    render={({ field, fieldState }) =>
                        <TextField
                            InputProps={{
                                readOnly: action == RecordActions.view,
                            }}
                            {...field}
                            fullWidth
                            id="username"
                            label="Username"

                        />} />
            </Grid>

            <Grid item xs={12} sm={4}>
                <Controller
                    name="password"
                    control={methods.control}
                    render={({ field, fieldState }) =>
                        <TextField
                            InputProps={{
                                readOnly: action == RecordActions.view,
                            }}
                            {...field}
                            fullWidth
                            name="LoginPassword"
                            label="Set New Password"
                            id="LoginPassword"
                        />} />
            </Grid>



        </Grid>)
}