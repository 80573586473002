import { Box, Stack, Typography, CircularProgress, Avatar, TextField, Button, IconButton, InputAdornment, FormControl, MenuItem, Select, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Container } from "@mui/material"
import moment from "moment";
import { useState } from "react";
import { useAlert } from "src/components/CommonAlert";
import { CONSTANTS } from "src/constants";
import axiosService from 'src/services/axios';
import { Member } from "src/types/member-interface";
// import { Scanner } from '@yudiel/react-qr-scanner';
import { CouponMapping } from "src/intefaces/coupon.interface";
import { useMerchant } from "src/context/MerchantContext";
import CancelIcon from '@mui/icons-material/Cancel';
import { FlagIcon, FlagIconCode } from 'react-flag-kit';
import { useAuth } from "src/context/AuthContext";
import { Roles } from "src/types/enum";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DangerousIcon from '@mui/icons-material/Dangerous';
import { keyframes } from '@mui/system';

const bounceAnimation = keyframes`
  0% { transform: scale(0); }
  50% { transform: scale(1.1); }
  100% { transform: scale(1); }
`;

export const TransferCoupon = ({ selectedCoupon, setSelectedCoupon, onClose }: { selectedCoupon: CouponMapping, setSelectedCoupon: any, onClose: any }) => {
    const { showAlert } = useAlert();
    const [searchingMember, setSearchingMember] = useState<boolean>(false);
    const [scannedMember, setScannedMember] = useState<Member | undefined>(undefined);
    const [transferComplete, setTransferComplete] = useState<"Success" | "Invalid Phone Number" | "Wrong Password" | "Failed to Transfer" | "Cannot Transfer to Self" | undefined>(undefined);
    const [uTUsername, setUTUsername] = useState<string>("");
    const [uTPassword, setUTPassword] = useState<string>("");
    const { merchant } = useMerchant();
    const { user } = useAuth();
    const countries = [
        { label: 'Malaysia', value: '+60', flag: "MY" },
        { label: 'United States', value: '+1', flag: "US" },
        { label: 'India', value: '+91', flag: "IN" },
    ];
    const [selectedCountry, setSelectedCountry] = useState<any>({ label: 'Malaysia', value: '+60', flag: "MY" });
    const [mobile, setMobile] = useState<string>();
    const onQrScanned = async (mobileNumber: string) => {

        try {
            setSearchingMember(true);
            const response = await axiosService.get<Member>(`${CONSTANTS.ApiConstants.getMemberByMob}/${mobileNumber}`)

            if (response.status != 200) {
                setScannedMember(undefined)
                setSearchingMember(false);
                showAlert('Failed to Get Member Details', 'error');
            } else {
                const json: Member = response.data;
                if (json.DOB) {
                    json.DOB = moment(json.DOB ?? null).utc(true).local();;
                    json.RegistredTime = moment(json.RegistredTime ?? null).utc(true).local();
                }
                setSearchingMember(false);
                if (json.id == user?.account?.id) {
                    setScannedMember({ Mobile: mobile } as Member);
                    setTransferComplete("Cannot Transfer to Self");
                } else if (json?.Mobile) {
                    setScannedMember(json)
                }
                else {
                    setScannedMember({ Mobile: mobile } as Member);
                    setTransferComplete("Invalid Phone Number");
                }
            }
        } catch (ex) {
            setScannedMember(undefined)
            setSearchingMember(false);
            showAlert('Failed to Get Member Details', 'error');
            console.error("Exception Caught", ex)
        }
    }
    const postTransfer = async (e: any) => {
        e.preventDefault();
        try {
            let username = user?.username;
            if (user?.role == Roles.member) {
                username = user?.username.split("@")[0]
            }
            const payload = {
                password: uTPassword,
                couponMappingId: selectedCoupon?.id,
                username: username,
                transferTo: scannedMember?.id,
                merchantid: merchant?.id,
                transferToPhone: scannedMember?.Mobile
            }
            const response: any = await axiosService.post<any>(`${CONSTANTS.ApiConstants.transferCoupon}`, payload)
            if (response.status != 200) {
                setTransferComplete(response?.message ?? "Failed to Transfer");
            } else {
                setTransferComplete("Success");
                //showAlert("Transfer Successful", "success");               

            }
        } catch (ex: any) {
            if (ex.response.status == 401) {
                setTransferComplete("Wrong Password");
            } else {
                setTransferComplete(ex?.response?.message ?? "Failed to Transfer");
            }
        }
    }
    const closeTransferWindow = () => {
        if (transferComplete == "Success") {
            setSelectedCoupon(undefined);
            setScannedMember(undefined)
            setUTPassword("")
            setUTUsername("")
            onClose && onClose({ reason: "transfer" })
        } else if (transferComplete == "Wrong Password") {
            setTransferComplete(undefined);
        } else {
            setScannedMember(undefined);
            setTransferComplete(undefined);

        }
    }
    return <Box sx={{ width: '100%', p: 3 }}>
        <Stack style={{ alignItems: "center", height: "80vh", justifyContent: "space-between" }}>
            <h1 style={{ textAlign: "center" }}>
                {"Transfer"}
            </h1>
            {/* <Stack style={{ width: "80vw", height: "80vw", justifyContent: "center", alignItems: "center" }}>
                {searchingMember ? <CircularProgress size={50} /> :
                    (!scannedMember ? <Scanner onError={(e: any) => showAlert(e.toString(), "error")} onScan={(result) => onQrScanned(result[0].rawValue)} /> :
                        <Box >
                            <Avatar src={scannedMember?.Avatar} ></Avatar>
                            <Typography variant="subtitle1">{scannedMember.Mobile} </Typography>
                            <Typography variant='body1'>{scannedMember.MemberName} </Typography>
                        </Box>)
                }
            </Stack> */}
            <br />
            <Stack direction="row" sx={{ width: "100%" }}>

                <TextField
                    variant="outlined"
                    fullWidth
                    sx={{
                        '& .MuiInputBase-input': {
                            fontSize: '1.5em', // Set the font size here
                        },
                        '& .MuiInputLabel-root': {
                            fontSize: '1.5em', // Set the label font size if needed
                        },
                    }}
                    disabled={!!scannedMember
                    }
                    onChange={(e) => { setMobile(e.target.value) }}
                    placeholder="Phone Number"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <FormControl variant="standard">
                                    <Select
                                        value={0}
                                        onChange={(e) => { setSelectedCountry(countries.find(c => c.value == e.target.value)); }}
                                        displayEmpty
                                        sx={{
                                            "minWidth": "80px",
                                            "minHeight": "0px",
                                            "& > .MuiSelect-select": {
                                                "minHeight": "0px !important"
                                            }
                                        }}
                                        renderValue={(value) => (
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                {selectedCountry ? <><FlagIcon code={selectedCountry?.flag as FlagIconCode} />
                                                    <h2 style={{ fontWeight: "normal", fontSize: "24px" }}>  {selectedCountry?.value}</h2></> : <></>}
                                            </div>
                                        )}
                                    >
                                        {countries.map((country) => (
                                            <MenuItem key={country.value} value={country.value}>
                                                <FlagIcon code={country.flag as FlagIconCode} />
                                                <h2 style={{ fontWeight: "normal", fontSize: "24px" }}> ({country.value})</h2>
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </InputAdornment>
                        ),
                    }}
                />

            </Stack>
            <br />
            <Stack sx={{ width: "100%" }}>
                {!scannedMember ?
                    <Button size="large" fullWidth variant="outlined" color="primary" onClick={() => {
                        const countrycode = selectedCountry.value;
                        const intPhone = (countrycode + mobile).replace(/[+-]/g, '');
                        onQrScanned(intPhone);
                    }}><h3>Transfer</h3></Button> :

                    <></>
                }
                <br />
                <IconButton color='primary' size="large" onClick={() => { onClose && onClose({ reason: "cancel" }) }} aria-label="cancel">
                    <CancelIcon sx={{ fontSize: '1.5em' }} />
                </IconButton>
            </Stack>
        </Stack>
        {
            scannedMember && <Dialog fullWidth sx={{ textAlign: "center" }} open={!!scannedMember} onClose={() => setScannedMember(undefined)}>

                <DialogContent>
                    {transferComplete ?
                        <Stack spacing={2}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                {transferComplete == "Success" ?
                                    <CheckCircleIcon
                                        sx={{
                                            fontSize: '6em',
                                            color: '#4caf50',
                                            animation: `${bounceAnimation} 0.5s ease-in-out forwards`,
                                        }}
                                    /> : <CancelIcon
                                        sx={{
                                            fontSize: '6em',
                                            color: '#ff0000',
                                            animation: `${bounceAnimation} 0.5s ease-in-out forwards`,
                                        }}
                                    />}

                            </Box>
                            <h2>
                                {transferComplete}
                            </h2>
                            <Box>
                                <Button fullWidth type="button" color="primary" variant="outlined" size="large" onClick={() => closeTransferWindow()}>
                                    <h3 style={{ fontWeight: "normal" }}> Ok</h3>
                                </Button>
                            </Box>
                        </Stack>
                        :
                        <form onSubmit={postTransfer}>
                            <TextField
                                onChange={(e) => { setUTPassword(e.target.value) }}
                                fullWidth
                                id="password"
                                sx={{ '& .MuiInputBase-input': { fontSize: "1.5em" } }}
                                placeholder="Password"
                            />
                            <DialogActions sx={{ pl: 0, pr: 0 }}>
                                <Button size="large" fullWidth type="submit" color="primary" variant="contained" disableElevation={true}>
                                    <h3 style={{ fontWeight: "normal" }}> Ok</h3>
                                </Button>
                                <Button size="large" fullWidth onClick={() => setScannedMember(undefined)} color="primary" variant="outlined">
                                    <h3 style={{ fontWeight: "normal" }}>Cancel</h3>
                                </Button>
                            </DialogActions>

                        </form>
                    }
                </DialogContent>
            </Dialog>
        }
    </Box >
}