// src/context/AuthContext.tsx

import React, { createContext, useState, useContext, ReactNode, useEffect } from 'react';
import axiosService from '../services/axios';
import { User } from '../types/user-interface';
import { CONSTANTS } from '../constants';
import { useAlert } from '../components/CommonAlert';
import { jwtDecode } from 'jwt-decode';
import { Token } from 'typescript';
import { TokenObject } from '../types/token-object';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Cookies from 'js-cookie';
// Define the type for the context
interface AuthContextType {
  isAuthenticated: boolean;
  user: TokenObject | null;
  login: (username: string, password: string, rememberme: boolean) => Promise<string>;
  memberLogin: (username: string, password: string, merchantid: number, rememberMe: boolean) => Promise<string>;
  memberLoginByOTP: (username: string, OTP: string, countrycode: string, merchantid: number, rememberMe?: boolean) => Promise<string>;
  logout: () => void;
  getToken: () => string | null;
  isTokenValid: (token: string) => boolean;
}

// Create context
const AuthContext = createContext<AuthContextType | undefined>(undefined);

// Create a provider component
interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [user, setUser] = useState<TokenObject | null>(null);
  const { showAlert } = useAlert();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const isTokenValid = (token: string) => {
    try {
      if (token) {
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000;

        // Check if token is expired
        if ((decodedToken.exp ?? 0) < currentTime) {
          localStorage.removeItem('token');
          sessionStorage.removeItem('token');
          return false; // Token expired
        }
        return true; // Token valid
      }

      return false; // No token found
    } catch (error) {
      return false;
    }
  };
  const getToken = (): string | null => {
    return (queryParams.get('ref') ? queryParams.get('ref') : Cookies.get('token')) ?? null;
  }
  const syncTokenAcrossTabs = (e: { key: string | null; newValue: string | null }) => {
    if (e.key === 'tempToken' && e.newValue) {
      // Store token in sessionStorage for the new tab
      sessionStorage.setItem('token', e.newValue);
      console.log('Token synced across tabs.');
    }
  };
  const login = async (username: string, password: string, rememberMe: boolean) => {
    try {
      const response = await axiosService.post<User | any>(`${CONSTANTS.ApiConstants.login}`, { username: username, password: password })
      if (response.status != 200) {
        showAlert(response?.data?.message || "Failed to login", "error");
        console.error("Exception Caught", response);
        return response?.data?.message || "Failed to login";
      } else {
        const token = response.data.token;
        if (rememberMe) {
          Cookies.set('token', token, { expires: 7 }); // Expires in 7 days
          localStorage.setItem('token', token);
        } else {
          Cookies.set('token', token);
        }
        const decodedToken: TokenObject = jwtDecode(token);
        setIsAuthenticated(true);
        setUser(decodedToken);
        return "true";
      }
    } catch (ex: any) {
      console.error("Exception Caught", ex)
      if (ex?.response?.status == 500) {
        return ex?.response?.data?.message
      } else if (ex.code == "ERR_NETWORK") {
        return "Failed to connect to server"
      }
      return ex?.response?.data?.message ?? ex.message ?? "Failed to login"

    }
  };
  const memberLogin = async (username: string, password: string, merchantid: number, rememberMe: boolean) => {
    try {
      const response = await axiosService.post<User | any>(`${CONSTANTS.ApiConstants.public_memberLogin}/${merchantid}`, { mobile: username, password: password })
      if (response.status != 200) {
        showAlert(response?.data?.message || "Failed to login", "error");
        console.error("Exception Caught", response);
        return response?.data?.message || "Failed to login";
      } else {
        const token = response.data.token;
        if (rememberMe) {
          Cookies.set('token', token, { expires: 7 }); // Expires in 7 days
          localStorage.setItem('token', token);
        } else {
          Cookies.set('token', token);
        }
        const decodedToken: TokenObject = jwtDecode(token);
        setIsAuthenticated(true);
        setUser(decodedToken);
        return "true";
      }
    } catch (ex: any) {
      console.error("Exception Caught", ex)
      if (ex?.response?.status == 500) {
        return ex?.response?.data?.message
      } else if (ex.code == "ERR_NETWORK") {
        return "Failed to connect to server"
      }
      return ex?.response?.data?.message ?? ex.message ?? "Failed to login";
    }
  };
  const memberLoginByOTP = async (username: string, OTP: string, countrycode: string, merchantid: number, rememberMe?: boolean) => {
    try {
      const response = await axiosService.post<User | any>(`${CONSTANTS.ApiConstants.user_verifyOtp}/${merchantid}`, { mobile: username, OTP: OTP, countrycode: countrycode })
      if (response.status != 200) {
        showAlert(response?.data?.message || "Failed to login", "error");
        console.error("Exception Caught", response);
        return response?.data?.message || "Failed to login";
      } else {
        const token = response.data.token;
        if (rememberMe) {
          Cookies.set('token', token, { expires: 7 }); // Expires in 7 days
          localStorage.setItem('token', token);
        } else {
          Cookies.set('token', token);
        }
        const decodedToken: TokenObject = jwtDecode(token);
        setIsAuthenticated(true);
        setUser(decodedToken);
        return "true";
      }
    } catch (ex: any) {
      if (ex?.response?.status == 500) {
        return ex?.response?.data?.message
      } else if (ex.code == "ERR_NETWORK") {
        return "Failed to connect to server"
      }
      return ex?.response?.data?.message ?? ex.message ?? "Failed to login";
    }
  };
  useEffect(() => {
    const token = Cookies.get('token') || localStorage.getItem('token');;
    if (token) {
      const decoded: TokenObject = jwtDecode(token);
      setIsAuthenticated(true);
      setUser(decoded);
    }

  }, []);
  const logout = () => {
    Cookies.remove('token');
    localStorage.removeItem('token')
    window.location.reload(); // Reload the page or redirect to the login page
  };
  const publicRoutes = ['/'];
  // useEffect(() => {
  //   console.log("pathname",location.pathname)
  //   if (publicRoutes.includes(location.pathname)) {
  //     return; // Skip authentication check for public routes
  //   }
  //   const token = getToken();

  //   if (token && isTokenValid(token)) {
  //     const decoded: TokenObject = jwtDecode(token);
  //     setIsAuthenticated(true);
  //     setUser(decoded);
  //     //console.log("location.pathname",location.pathname)
  //     navigate(`${location.pathname}${location.search}`);
  //   } else {
  //     // Token is not valid or not present, navigate to login or another appropriate page
  //     navigate('/login');
  //   }
  // }, [navigate, location.pathname]);

  return (
    <AuthContext.Provider value={{ isAuthenticated, user, login, logout, getToken, isTokenValid, memberLogin, memberLoginByOTP }}>
      {children}
    </AuthContext.Provider>
  );
};

// Custom hook for using the context
export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
