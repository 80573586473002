
import * as React from 'react';
import { useSelection } from '../../hooks/use-selection';
import DataTable from '../../components/DataTable';
import { CONSTANTS } from '../../constants';
import axiosService from '../../services/axios';
import { useAlert } from '../../components/CommonAlert';
import DeleteConfirmationDialog from '../../components/ConfirmationDialog';
import { useNavigate } from 'react-router-dom';
import { NoticeBoard } from 'src/intefaces/notice-board.inteface';
import { DataType, RecordTypes } from 'src/types/enum';
import { Paper } from '@mui/material';


interface NoticeBoardsTableProps {
  count?: number;
  page?: number;
  rows?: NoticeBoard[];
  rowsPerPage?: number;
  onDeleteCallback?: () => any
}

export function NoticeBoardsTable({
  count = 0,
  rows = [],
  page = 0,
  rowsPerPage = 0,
  onDeleteCallback
}: NoticeBoardsTableProps): React.JSX.Element {
  const rowIds = React.useMemo(() => {
    return rows && rows.map((noticeBoard) => noticeBoard.id);
  }, [rows]);
  const navigate = useNavigate();
  const { selectAll, deselectAll, selectOne, deselectOne, selected } = useSelection(rowIds);
  const { showAlert } = useAlert();
  const selectedSome = (selected?.size ?? 0) > 0 && (selected?.size ?? 0) < rows.length;
  const [selectedId, setSelectedId] = React.useState<number | undefined>(undefined);
  const [openDeleteDlg, setOpenDeleteDlg] = React.useState<boolean>(false);
  const columns = [
    { id: "id", label: "UID" },
    { id: "zoomMode", label: "Zoom Mode" },
    { id: "title", label: "Title" },
    { id: "dateFrom", label: "Date From", type: DataType.datetime },
    { id: "expiry", label: "Expiry", type: DataType.datetime  },
    { id: "imageLink", label: "Image Link" },
    { id: "status", label: "Status" },
    { id: "pinTop", label: "Pintop" }
  ];
  const handleEdit = (id: number) => {
    navigate(`/noticeBoards/edit/${id}`);
  };

  const handleDelete = async (id: number) => {
    setSelectedId(id);
    setOpenDeleteDlg(true);
  };

  const handleView = (id: number) => {
    navigate(`/noticeBoards/view/${id}`);
  };
  const onDeleteConfirm = async () => {
    try {
      setOpenDeleteDlg(false)
      const response = await axiosService.delete<NoticeBoard[]>(`${CONSTANTS.ApiConstants.GENERIC.delete[RecordTypes.noticeBoard]}/${selectedId}`);
      if (response.status != 200) {
        showAlert('Failed to Delete Merchant', 'error');
      } else {
        showAlert('Merchant Deleted Successfully', 'success');
        onDeleteCallback && onDeleteCallback();
      }
    } catch (ex) {
      showAlert('Failed to Delete Merchant', 'error');
      console.error("Exception Caught", ex)
    } finally {
      setSelectedId(undefined);

    }
  }
  return (<div>
  <Paper square={true}>   <DataTable
      rows={rows}
      columns={columns}
      onEdit={handleEdit}
      onDelete={handleDelete}
      onView={handleView}
    /></Paper>
    <DeleteConfirmationDialog
      open={openDeleteDlg}
      onClose={() => { setOpenDeleteDlg(false); setSelectedId(undefined) }}
      onConfirm={onDeleteConfirm}
      confirmLabel={"Delete"}
      cancelLabel={"Cancel"}
      message={"Are you sure you want to delete?"}
      title={"Confirm Delete"}
    />
  </div>)
}
