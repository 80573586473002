import React from 'react';
import { Paper, Box, Typography, Avatar } from '@mui/material';
import { LocationOn, Phone, Email, Padding, ArrowForward } from '@mui/icons-material';
import { Merchant } from 'src/types/merchant-interface';
import { red, blue, green } from '@mui/material/colors';

const ContactInfo: React.FC<any> = ({ merchant }: { merchant: Merchant }) => {
    const openGoogleMaps = () => {
        const zoom = 14;
        const googleMapsUrl = `https://www.google.com/maps/@${merchant.MerchantLatitude},${merchant.MerchantLongitude},${zoom}z`;
        window.open(googleMapsUrl, '_blank');
    };
    const openPhoneDialer = (phone: string) => {
        window.location.href = `tel:${phone}`;
    };
    const openEmail = () => {
        window.location.href = `mailto:${merchant?.MerchantEmail}`;
    };
    const isLastBlock = (blockName: "address" | "phone1" | "phone2" | "email") => {
        switch (blockName) {
            case "address":
                return merchant.MerchantPhone1 || merchant.MerchantPhone2 || merchant.MerchantEmail ? false : true
                break;
            case "phone1":
                return merchant.MerchantPhone2 || merchant.MerchantEmail ? false : true
                break;
            case "phone2":
                return merchant.MerchantEmail ? false : true
                break;
            case "email":
                return true
                break;
        }

    }
    return (<Paper square={true} elevation={0} sx={{ pl: 3, pr: 3 }}>
        {merchant.MerchantAddress && <Paper square={true} elevation={1} sx={{ p: 2, mb: isLastBlock("address") ? "unset" : 2 }}>
            <Box display="flex" alignItems="center" justifyContent={'space-between'} onClick={openGoogleMaps}>
                <Box display="flex" alignItems="center"  >
                    <Avatar sx={{ bgcolor: merchant.ColorCodeButton, mr: 2, color: "#fff" }}>
                        <LocationOn />
                    </Avatar>
                    <h3 style={{ fontWeight: "normal" }}>{merchant.MerchantAddress}</h3>
                </Box>

            </Box>
        </Paper>}
        {merchant.MerchantPhone1 && <Paper square={true} elevation={1} sx={{ p: 2, mb: isLastBlock("phone1") ? "unset" : 2 }}>
            <Box display="flex" alignItems="center" justifyContent={'space-between'} onClick={() => openPhoneDialer(merchant.MerchantPhone1)}>
                <Box display="flex" alignItems="center"  >

                    <Avatar sx={{ bgcolor: merchant.ColorCodeButton, mr: 2, color: "#fff" }}>
                        <Phone />
                    </Avatar>

                    {merchant.MerchantPhone1 && <h3 style={{ fontWeight: "normal" }}>{merchant.MerchantPhone1}</h3>}

                </Box>

            </Box>

        </Paper>
        }
        {merchant.MerchantPhone2 && <Paper square={true} elevation={1} sx={{ p: 2, mb: isLastBlock("phone2") ? "unset" : 2 }}>
            <Box display="flex" alignItems="center" justifyContent={'space-between'} onClick={() => openPhoneDialer(merchant.MerchantPhone2)}>
                <Box display="flex" alignItems="center"  >

                    <Avatar sx={{ bgcolor: merchant.ColorCodeButton, mr: 2, color: "#fff" }}>
                        <Phone />
                    </Avatar>

                    <h3 style={{ fontWeight: "normal" }}>{merchant.MerchantPhone2}</h3>

                </Box>
            </Box>
        </Paper >
        }

        {merchant?.MerchantEmail && <Paper square={true} elevation={1} sx={{ p: 2}}>
            <Box display="flex" alignItems="center" justifyContent={'space-between'} onClick={openEmail}>
                <Box display="flex" alignItems="center"  >

                    <Avatar sx={{ bgcolor: merchant.ColorCodeButton, mr: 2, color: "#fff" }}>
                        <Email />
                    </Avatar>
                    <h3 style={{ fontWeight: "normal" }}>{merchant?.MerchantEmail}</h3>
                </Box>
            </Box>
        </Paper>
        }
    </Paper >
    )
};

export default ContactInfo;
