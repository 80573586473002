import React, { useRef, useState } from 'react';
import { Box, TextField, Button, Typography, Avatar, Select, MenuItem, InputLabel, FormControl, Stack, InputAdornment, Divider, Link } from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import SmsIcon from '@mui/icons-material/Sms';
import { useMerchant } from 'src/context/MerchantContext';
import { FlagIcon, FlagIconCode } from 'react-flag-kit';
import { Divide } from '@phosphor-icons/react';
import { useAuth } from 'src/context/AuthContext';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import axiosService from 'src/services/axios';
import { CONSTANTS } from 'src/constants';
import { Member } from 'src/types/member-interface';
import { useAlert } from 'src/components/CommonAlert';
import { OTPMode } from 'src/types/enum';
const MemberVerify: React.FC = () => {
    const { merchant } = useMerchant();
    const countries = [
        { label: 'Malaysia', value: '+60', flag: "MY" },
        { label: 'United States', value: '+1', flag: "US" },
        { label: 'India', value: '+91', flag: "IN" },
    ]
    const [password, setPassword] = useState('');
    let { mode, mobile, countrycode } = useParams();
    const { memberLoginByOTP } = useAuth();
    const [otp, setOtp] = useState<string[]>(["0", "0", "0", "0"]);
    const [message, setMessage] = useState('');
    const navigate = useNavigate();
    const location = useLocation();
    const { showAlert } = useAlert();
    const [loading, setLoading] = useState<boolean>(true);
    const inputRefs = useRef<Array<HTMLInputElement | null>>([]);
    const sendOtp = async () => {
        if (!mode) {
            mode = OTPMode.SMS;
        }
        try {
            const response = await (await axiosService.post<Member | any>(`${CONSTANTS.ApiConstants.user_memberLoginByOtp}/${merchant?.id}`, { countrycode: countrycode, mobile: mobile, otpMode: mode }))
            if (response.status != 200) {
                setLoading(false);
                showAlert(response?.data?.message && typeof response.data.message == "string" ? response.data.message : 'Oops! Something is not working', 'error');
            } else {

                // setCodesent(true)
            }
        } catch (ex: any) {
            setLoading(false);
            // setCodesent(false)
            showAlert(ex?.response?.data?.message && typeof ex.response.data.message == "string" ? ex.response.data.message : 'Oops! Something is not working. Signup Failed', 'error');
            console.error("Exception Caught", ex)
        }
    };
    const handleSubmit = (event: any) => {
        const eOtp = otp.join("");
        memberLoginByOTP(mobile || "", eOtp || "", countrycode || "", merchant?.id || 0).then((isLoggedIn: string) => {
            const from = location.state?.from || '/';
            if (isLoggedIn == "true") {
                setMessage('');
                navigate(`/${merchant?.id}/membersetup/add`);
            }
            else {
                showAlert(isLoggedIn ?? "", 'error')
            }

        }).catch((ex: any) => {
            showAlert(ex?.response?.data?.message && typeof ex.response.data.message == "string" ? ex.response.data.message : 'Oops! Login Failed', 'error');
        });
        event.preventDefault();
    };
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
        const { value } = e.target;

        if (value.length === 1 && inputRefs.current[index + 1]) {
            // Move to next input
            inputRefs.current[index + 1]?.focus();
        }

        if (value.length === 0 && inputRefs.current[index - 1]) {
            // Move to previous input on backspace
            inputRefs.current[index - 1]?.focus();
        }
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'space-evenly',
                minHeight: '80vh',
                padding: 2,
            }}
        >
            <Avatar
                sx={{
                    width: 120,
                    height: 120,
                    marginBottom: 3,
                }}
                src={merchant?.MerchantLogo}
                alt="Logo"
                variant='rounded'
            />
            <Stack sx={{ width: "100%", alignItems: "center" }}>
                {
                    <><h3 style={{ fontWeight: "normal" }}>
                        Enter 4-digit code
                    </h3>
                        <Stack direction="row" sx={{ width: "100%" }} spacing={2}>

                            {[...Array(4)].map((_, index) => (
                                <TextField
                                    key={"otp" + index}
                                    focused={index == 0}
                                    inputRef={(ref) => (inputRefs.current[index] = ref)}
                                    variant="outlined"
                                    inputProps={{ maxLength: 1, style: { textAlign: "center" } }}
                                    onChange={(e: any) => {
                                        const currentOtp = otp;
                                        currentOtp[index] = e.target.value;
                                        setOtp(currentOtp);
                                        handleChange(e, index);
                                    }}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Backspace' && !inputRefs.current[index]?.value) {
                                            inputRefs.current[index - 1]?.focus();
                                        }
                                    }}
                                />
                            ))}
                        </Stack>
                    </>}
            </Stack>
            <Stack sx={{ width: "100%", alignItems: "center" }}>

                {<>

                    <Button
                        type="submit"
                        fullWidth
                        variant="outlined"
                        color="primary"
                        onClick={handleSubmit}
                        size='large'
                    >
                        <h3 style={{ fontWeight: "normal" }}>Submit</h3>
                    </Button>
                    <Stack direction="row" sx={{ justifyContent: "space-around", mt: 2, width: "100%" }}>
                        <Link onClick={() => { sendOtp() }} color="primary" style={{ textDecoration: "none" }}><h3 style={{ fontWeight: "normal" }}>Resend OTP</h3></Link>

                        <Link color={"primary"} onClick={() => { navigate(`/${merchant?.id}/membersignup`) }} style={{ textDecoration: "none" }}><h3 style={{ fontWeight: "normal" }}>Change Number</h3></Link>
                    </Stack>
                </>}
            </Stack>

        </Box >


    );
};

export default MemberVerify;
